import React from 'react';
import _ from 'lodash';
import { Table, Icon } from 'semantic-ui-react';
import styles from './GoalsSummary.module.css';
import { defineMessages, useIntl } from 'react-intl';
import { GoalsSummaryRow } from './GoalsSummaryRow';
import { LoadingGoalsSummaryRow } from './LoadingGoalsSummaryRow';
import dayjs from 'dayjs';
import { ChartTheme } from './GoalChart';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { Order, OrderStatus, getExchangeRates } from '../../../orders';
import { useSelector } from 'react-redux';
import { calculateOrderPrice } from '../../selectors';

interface OwnProps {
    currentOrders: Order[];
    ytdPreviousOrders: Order[];
    allPreviousOrders: Order[];
    goal: number;

    convertCurrenciesToCad?: boolean;
    currency?: string;
    loading?: boolean;
}

export type GoalsSummaryProps =
    & OwnProps;

const m = defineMessages({
    orderedMetric: { id: 'Goals.orderedMetric', defaultMessage: 'Ordered:' },
    shippedMetric: { id: 'Goals.shippedMetric', defaultMessage: 'Shipped:' },
    backlogMetric: { id: 'Goals.backlogMetric', defaultMessage: 'Backlog:' },
    goalMetric: { id: 'Goals.goalMetric', defaultMessage: 'Goal:' },
    previousYearMetric: { id: 'Goals.previousYearMetric', defaultMessage: '{previousYear}:' },
});

export const GoalsSummary: React.FC<GoalsSummaryProps> = (props) => {
    const { formatMessage } = useIntl();

    const convertCurrenciesToCad = props.currency == null;
    const defaultExchangeRates = useSelector(getExchangeRates);

    const shippedOrders = _(props.currentOrders).filter(x => x.status >= OrderStatus.shipped).value();
    const shippedOrdersTotal = _(shippedOrders).sumBy(x => calculateOrderPrice(x, convertCurrenciesToCad, defaultExchangeRates));
    const ytdPreviousShippedOrders = _(props.ytdPreviousOrders).filter(x => x.status >= OrderStatus.shipped).value();
    const ytdPreviousShippedOrdersTotal = _(ytdPreviousShippedOrders).sumBy(x => calculateOrderPrice(x, convertCurrenciesToCad, defaultExchangeRates));

    const currentOrdersTotal = _(props.currentOrders).sumBy(x => calculateOrderPrice(x, convertCurrenciesToCad, defaultExchangeRates));
    const ytdPreviousOrdersTotal = _(props.ytdPreviousOrders).sumBy(x => calculateOrderPrice(x, convertCurrenciesToCad, defaultExchangeRates));
    const allPreviousOrdersTotal = _(props.allPreviousOrders).sumBy(x => calculateOrderPrice(x, convertCurrenciesToCad, defaultExchangeRates));
    const currentBacklogTotal = currentOrdersTotal - shippedOrdersTotal;

    return (
        <Table className={styles.summaryTable} basic="very" compact="very" unstackable style={{ marginTop: 0 }}>
            <Table.Body>
                {!props.loading &&
                    <>
                        <GoalsSummaryRow
                            label={formatMessage(m.orderedMetric)}
                            currentAmount={currentOrdersTotal}
                            previousAmount={ytdPreviousOrdersTotal}
                            currency={props.currency || 'CAD'}
                            icon={<Icon name={ChartTheme.currentSales.symbol as SemanticICONS} style={{ color: ChartTheme.currentSales.color }} />}
                        />
                        <GoalsSummaryRow
                            label={formatMessage(m.shippedMetric)}
                            currentAmount={shippedOrdersTotal}
                            previousAmount={ytdPreviousShippedOrdersTotal}
                            currency={props.currency || 'CAD'}
                            icon={<Icon name={ChartTheme.shipped.symbol as SemanticICONS} style={{ color: ChartTheme.shipped.color }} />}
                        />
                        <GoalsSummaryRow
                            label={formatMessage(m.backlogMetric)}
                            currentAmount={currentBacklogTotal}
                            currency={props.currency || 'CAD'}
                        />
                        <GoalsSummaryRow
                            label={formatMessage(m.goalMetric)}
                            currentAmount={props.goal}
                            currency={props.currency || 'CAD'}
                            icon={<Icon name={ChartTheme.goal.symbol as SemanticICONS} style={{ color: ChartTheme.goal.color }} />}
                        />
                        <GoalsSummaryRow
                            label={formatMessage(m.previousYearMetric, { previousYear: dayjs().local().year() - 1 })}
                            currentAmount={allPreviousOrdersTotal}
                            currency={props.currency || 'CAD'}
                            icon={<Icon name={ChartTheme.previousSales.symbol as SemanticICONS} style={{ color: ChartTheme.previousSales.color }} />}
                        />
                    </>
                }

                {props.loading &&
                    <>
                        <LoadingGoalsSummaryRow label={formatMessage(m.orderedMetric)} />
                        <LoadingGoalsSummaryRow label={formatMessage(m.shippedMetric)} />
                        <LoadingGoalsSummaryRow label={formatMessage(m.backlogMetric)} />
                        <LoadingGoalsSummaryRow label={formatMessage(m.goalMetric)} />
                        <LoadingGoalsSummaryRow label={formatMessage(m.previousYearMetric, { previousYear: dayjs().local().year() - 1 })} />
                    </>
                }
            </Table.Body>
        </Table>
    );
};