import React from 'react';
import { NavLinkProps, NavLink } from 'react-router-dom';
import { SemanticICONS } from 'semantic-ui-react';
import { FloatingActionButton } from './FloatingActionButton';
import styles from './FloatingActionMenuItem.module.css';

interface OwnProps {
    icon: SemanticICONS;
    title: string;
}

export type FloatingActionMenuItemProps =
    & OwnProps
    & NavLinkProps;

export const FloatingActionMenuItem: React.FC<FloatingActionMenuItemProps> = (props) => {
    const { icon, title, ...navProps } = props;
    return (
        <NavLink className={styles.container} {...navProps}>
            <span className={styles.linkTitle}>
                {title}
            </span>
            <FloatingActionButton
                className={styles.button}
                icon={icon}
                size="huge"
            />
        </NavLink>
    );
};