import React from 'react';
import SinglePageLayout from '../../../../components/layouts/SinglePageLayout';

interface ClientLayoutProps {
    children?: React.ReactNode;
}

export const ClientLayout: React.FC<ClientLayoutProps> = (props) => {

    return (
        <SinglePageLayout>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {props.children}
            </div>
        </SinglePageLayout>
    );
};

