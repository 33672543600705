export interface IResizeImageOptions {
    file: File;
    maxSize: number;
}

export const resizeImage = (settings: IResizeImageOptions): Promise<Blob> => {
    const file = settings.file;
    const maxSize = settings.maxSize;
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');

    const resize = () => {
        let width = image.width;
        let height = image.height;

        if (width > height) {
            if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
            }
        } else {
            if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
            }
        }

        canvas.width = width;
        canvas.height = height;

        return new Promise<Blob>((success, failure) => {
            const context2d = canvas.getContext('2d');

            if (context2d == null) {
                failure(new Error("Could not get 2D context."));
                return;
            }


            context2d.drawImage(image, 0, 0, width, height);
            canvas.toBlob(blob => {
                if (blob) {
                    success(blob);
                }
                else {
                    failure(new Error("Resizing failed"));
                }
            });
        });
    };

    return new Promise<Blob>((success, failure) => {
        if (!file.type.match(/image.*/)) {
            failure(new Error("Not an image"));
            return;
        }

        reader.onload = (readerEvent: any) => {
            image.onload = () => {
                success(resize());
            };
            image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
};