import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Icon } from "semantic-ui-react";

const m = defineMessages({
    defaultExpandedLabel: { id: "ExpandButton.ExpandedLabel", defaultMessage: "Collapse" },
    defaultCollapedLabel: { id: "ExpandButton.CollapedLabel", defaultMessage: "Expand" },
});

interface ExpandButtonProps {
    isExpanded: boolean;
    onChange: (isExpanded: boolean) => void;
    expandedLabel?: string;
    collapsedLabel?: string;
}

export const ExpandButton: React.FC<ExpandButtonProps> = ({
    isExpanded,
    onChange,
    expandedLabel,
    collapsedLabel,
}) => {
    const { formatMessage } = useIntl();

    return (
        <span onClick={() => onChange(!isExpanded)} style={{ cursor: "pointer", color: "#686869" }}>
            {isExpanded ? (
                <>
                    <Icon size="large" name="minus square" />
                    {expandedLabel ?? formatMessage(m.defaultExpandedLabel)}
                </>
            ) : (
                <>
                    <Icon size="large" name="plus square" />
                    {collapsedLabel ?? formatMessage(m.defaultCollapedLabel)}
                </>
            )}
        </span>
    );
};
