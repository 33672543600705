import React, { useState, useEffect } from 'react';
import FluidPageLayout from '../../../components/layouts/FluidPageLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { FloatingActionButton, FloatingActionButtons } from '../../../components/floatingActionButton';
import { ActivityForm } from './components/ActivityForm';
import { ActivityEdit } from '../models';
import * as activityApi from '../api';
import * as LayoutActions from '../../layout/actions';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ValidationResult } from '../../../utils/ValidationResult';
import { nameof } from '../../../utils/nameof';
import { MediaFile } from './components/MediaGallery';
import { getAllClients, areClientsLoading } from '../../clients/selectors';

const m = defineMessages({
    pageTitle: { id: 'CreateActivityPage.pageTitle', defaultMessage: 'Add a new activity' }
});

export const CreateActivityPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { clientId, branchId } = useParams();

    const allClients = useSelector(getAllClients);
    const isLoading = useSelector(areClientsLoading);

    const [isSaving, setSaving] = useState(false);
    const [activity, setActivity] = useState<ActivityEdit>(new ActivityEdit());
    const [validationResult, setValidationResult] = useState<ValidationResult>(ValidationResult.empty());

    useEffect(() => {
        dispatch(LayoutActions.setPageTitle(formatMessage(m.pageTitle)));
    }, [dispatch, formatMessage]);

    useEffect(() => {
        setActivity((previousActivity) => new ActivityEdit({
            ...previousActivity,
            clientId: clientId ? Number(clientId) : undefined,
            branchId: branchId ? Number(branchId) : undefined,
        }));
    }, [clientId, branchId]);

    useEffect(() => {
        // When changing the client, we want to reset the selected branch, since it depends on the client.
        // For a better UX experience, we want to automatically select the new client's branch if there's only one.
        const client = allClients.find(x => x.id === activity.clientId);
        if (client != null) {
            const selectedBranchId = client.branches.length === 1 ? client.branches[0].id : undefined;

            setActivity((previousActivity) => new ActivityEdit({
                ...previousActivity,
                branchId: selectedBranchId
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity.clientId]);

    const updateActivity = (fieldId: string, fieldValue: any) => {
        setActivity(new ActivityEdit({
            ...activity,
            [fieldId]: fieldValue
        }));
    };

    const addAttachments = (files: File[]) => updateActivity(nameof<ActivityEdit>('attachments'), [...activity.attachments, ...files]);
    const deleteAttachment = (file: MediaFile) => updateActivity(nameof<ActivityEdit>('attachments'), activity.attachments.filter(x => x !== file.id));

    const navigateBack = () => navigate(-1);
    const saveChanges = () => {
        const result = activity.validate();
        setValidationResult(result);

        if (result.isValid) {
            setSaving(true);

            activityApi.createActivity(activity)
                .then(navigateBack)
                .finally(() => setSaving(false));
        }
    };

    return (
        <FluidPageLayout>
            <ActivityForm
                activity={activity}
                attachments={activity.attachments.map(x => ({ id: x, src: URL.createObjectURL(x) }))}
                validationResult={validationResult}
                loading={isLoading}
                disabled={isSaving}
                allClients={allClients}
                style={{ marginTop: '.25rem' }}
                onChange={updateActivity}
                onAddAttachments={addAttachments}
                onDeleteAttachment={deleteAttachment}
            />

            <FloatingActionButtons>
                <FloatingActionButton
                    icon="cancel"
                    onClick={navigateBack}
                />
                <FloatingActionButton
                    disabled={isSaving || isLoading}
                    loading={isSaving}
                    primary
                    icon="save"
                    onClick={saveChanges}
                />
            </FloatingActionButtons>
        </FluidPageLayout>
    );
};