import React from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';

import styles from './FloatingActionButton.module.css';

export type FloatingActionButtonProps =
    & ButtonProps;

export const FloatingActionButton: React.FC<FloatingActionButtonProps> = (props) => {
    const { className, ...buttonProps } = props;
    return (
        <Button
            className={`${styles.fab} ${className || ''}`}
            circular
            size="huge"
            {...buttonProps}
        />
    );
};