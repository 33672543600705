import React, { useState } from "react";
import { Dimmer } from "semantic-ui-react";
import { TopBar } from "../TopBar";
import { SidebarMenu } from "../SidebarMenu";

interface BasicPageLayoutProps {
    children?: React.ReactNode;
}

export const BasicPageLayout: React.FC<BasicPageLayoutProps> = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <>
            <SidebarMenu visible={isMenuOpen} onHide={() => setIsMenuOpen(false)} />
            <Dimmer.Dimmable blurring={isMenuOpen} dimmed={isMenuOpen}>
                <Dimmer
                    inverted
                    onClickOutside={() => setIsMenuOpen(false)}
                    style={{ zIndex: 700 }}
                />
                <TopBar onToggleMenu={() => setIsMenuOpen(!isMenuOpen)} />
                <div style={{ height: "100vh", paddingTop: "3.5rem" }}>
                    {props.children}
                </div>
            </Dimmer.Dimmable>
        </>
    );
};

export default BasicPageLayout;
