import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { FluidPageLayout } from '../../../components/layouts';
import { ActivityForm } from './components/ActivityForm';
import { FloatingActionButton, FloatingActionButtons } from '../../../components/floatingActionButton';
import { useNavigate, useParams  } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as LayoutActions from '../../layout/actions';
import { useIntl, defineMessages } from 'react-intl';
import { UpdateActivityEdit } from '../models';
import { ValidationResult } from '../../../utils/ValidationResult';

import * as activityApi from '../api';
import { nameof } from '../../../utils/nameof';
import { MediaFile } from './components/MediaGallery';
import { getAllClients, areClientsLoading } from '../../clients/selectors';

const m = defineMessages({
    pageTitle: { id: 'UpdateActivityPage.pageTitle', defaultMessage: 'Modify an activity' }
});

export const UpdateActivityPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isActivityLoading, setActivityLoading] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [activity, setActivity] = useState<UpdateActivityEdit>(new UpdateActivityEdit());
    const [attachments, setAttachments] = useState<MediaFile[]>([]);
    const [validationResult, setValidationResult] = useState<ValidationResult>(ValidationResult.empty());
    const allClients = useSelector(getAllClients);
    const isLoadingClients = useSelector(areClientsLoading);

    useEffect(() => {
        dispatch(LayoutActions.setPageTitle(formatMessage(m.pageTitle)));
    }, [dispatch, formatMessage]);

    useEffect(() => {
        setActivityLoading(true);

        activityApi.getActivityById(Number(id))
            .then(result => {
                const attachmentUrls = result.attachmentIds.map(x => `/api/attachments/${x}`) || [];
                setAttachments(attachmentUrls.map(x => ({ id: x, src: x })));
                setActivity(new UpdateActivityEdit({
                    ...result,
                    clientId: result.branch?.clientId,
                }));
            })
            .finally(() => setActivityLoading(false));
    }, [id]);

    const navigateBack = () => navigate(-1);
    const updateActivity = (fieldId: string, fieldValue: any) => {
        setActivity(new UpdateActivityEdit({
            ...activity,
            [fieldId]: fieldValue
        }));
    };

    const addAttachments = (files: File[]) => {
        updateActivity(nameof<UpdateActivityEdit>('attachments'), [...activity.attachments, ...files]);
        setAttachments([...attachments, ...files.map((x): MediaFile => ({ id: x, src: URL.createObjectURL(x) }))]);
    };

    const deleteAttachment = (file: MediaFile) => {
        if (file.id instanceof File) {
            // We're deleting a not yet uploaded file, so remove it from the list of attachments to upload.
            updateActivity(nameof<UpdateActivityEdit>('attachments'), activity.attachments.filter(x => x !== file.id));
        } else {
            const attachmentId = Number((file.id as string).replace('/api/attachments/', ''));
            updateActivity(nameof<UpdateActivityEdit>('attachmentsToDelete'), _.union(activity.attachmentsToDelete, [attachmentId]));
        }

        setAttachments(attachments.filter(x => x !== file));
    };

    const saveChanges = () => {
        const result = activity.validate();
        setValidationResult(result);

        if (result.isValid) {
            setSaving(true);

            activityApi.updateActivity(Number(id), activity)
                .then(navigateBack)
                .finally(() => setSaving(false));
        }
    };

    return (
        <FluidPageLayout>
            <ActivityForm
                activity={activity}
                attachments={attachments}
                validationResult={validationResult}
                loading={isActivityLoading || isLoadingClients}
                disabled={isSaving}
                allClients={allClients}
                style={{ marginTop: '.25rem' }}
                onChange={updateActivity}
                onAddAttachments={addAttachments}
                onDeleteAttachment={deleteAttachment}
            />

            <FloatingActionButtons>
                <FloatingActionButton
                    icon="cancel"
                    onClick={navigateBack}
                />

                <FloatingActionButton
                    disabled={isSaving || isActivityLoading}
                    loading={isSaving}
                    primary
                    icon="save"
                    onClick={saveChanges}
                />
            </FloatingActionButtons>
        </FluidPageLayout>
    );
};