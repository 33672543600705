import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "../store/store";
import { isAuthenticatedSelector } from "../modules/account/selectors";

export const RequireAuthentification: React.FC = () => {
  const isAuthenticated = useSelector<ApplicationState, boolean>((state) =>
    isAuthenticatedSelector(state)
  );

  return isAuthenticated === true ? <Outlet /> : <Navigate to='/account/login' />;
};
