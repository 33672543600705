import React from "react";
import { FormattedNumber } from "react-intl";
interface FormatedAmountProps {
    amount?: number;
}
export const FormattedAmount: React.FC<FormatedAmountProps> = ({ amount }) => {

    if (!amount) return null;

    return (
        <FormattedNumber
            value={amount}
            style="decimal"
            useGrouping={true}
            maximumFractionDigits={0}
        />
    );
};
