import { LOAD_REPRESENTATIVES_REQUEST } from "./types";
import * as api from "./api";
import * as actions from "./actions";
import { call, put, all, fork, takeLatest } from "redux-saga/effects";

function* watchLoadRepresentatives() {
    yield takeLatest(LOAD_REPRESENTATIVES_REQUEST, handleLoadRepresentatives);
}

function* handleLoadRepresentatives(): any {
    try {
        const results = yield call(api.getAllRepresentatives);
        yield put(actions.loadRepresentativesSuccess(results));
    } catch (e) {
        yield put(actions.loadRepresentativesFailure(e as Error));
    }
}

export default function* representativesSaga() {
    yield all([fork(watchLoadRepresentatives)]);
}
