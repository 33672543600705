import React from "react";
import _ from 'lodash';
import { useSelector } from "react-redux";
import { Dropdown, DropdownItemProps, DropdownProps } from "semantic-ui-react";
import { getAllCountryCodes } from "../../../orders";
import { defineMessages, useIntl } from "react-intl";

export type CountryFilterDropdownProps =
    & DropdownProps;

const m = defineMessages({
    allCountries: { id: 'CountryFilterDropdown.allCountries', defaultMessage: 'All countries' }
});

export const allCountriesValue = -1;
const canadaCountryCode = 'CA';
const unitedStatesCountryCode = 'US';

export const CountryFilterDropdown: React.FC<CountryFilterDropdownProps> = (props) => {
    const { formatMessage } = useIntl();
    const { ...dropdownProps } = props;
    const countryCodes = useSelector(getAllCountryCodes);

    const getSortedCountries = (): DropdownItemProps[] => {
        const countries = _
            .sortBy(countryCodes, x => x)
            .map((x): DropdownItemProps => ({
                text: x,
                value: x
            }));

        const customSortedCountries = sortCanadaAndUsaFirst(countries);
        const customSortedCountriesWithDefaultValue = addDefaultOption(customSortedCountries);
        return customSortedCountriesWithDefaultValue;
    };

    const sortCanadaAndUsaFirst = (dropdownItems: DropdownItemProps[]) => {
        const canadaDropdownItem = dropdownItems.find(x => x.value === canadaCountryCode);
        const usaDropdownItem = dropdownItems.find(x => x.value === unitedStatesCountryCode);
        const countriesExcludingCanadaAndUSA = dropdownItems.filter(x => x.value !== canadaCountryCode && x.value !== unitedStatesCountryCode);
        return [canadaDropdownItem || [], usaDropdownItem || [], ...countriesExcludingCanadaAndUSA];
    };

    const addDefaultOption = (dropdownItems: DropdownItemProps[]) => {
        const defaultCountriesDropdownItem: DropdownItemProps = ({
            text: formatMessage(m.allCountries),
            value: allCountriesValue
        });

        return [defaultCountriesDropdownItem, ...dropdownItems];
    };

    return (
        <Dropdown
            options={getSortedCountries()}
            {...dropdownProps}
        />
    );
};