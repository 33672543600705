import React from 'react';
import { Table, Placeholder } from 'semantic-ui-react';

export interface LoadingGoalsSummaryRowProps {
    label: string;
}

export const LoadingGoalsSummaryRow: React.FC<LoadingGoalsSummaryRowProps> = (props) => {
    return (
        <Table.Row verticalAlign="middle">
            <Table.Cell collapsing textAlign="right" style={{ fontSize: '1.25rem' }}>{props.label}</Table.Cell>
            <Table.Cell>
                <Placeholder fluid className="grey" style={{ marginTop: '-10px' }}>
                    <Placeholder.Line length="full" />
                </Placeholder>
            </Table.Cell>
            <Table.Cell collapsing style={{ minWidth: '50px' }}>
                <Placeholder fluid className="grey" style={{ marginTop: '-10px' }}>
                    <Placeholder.Line length="full" />
                </Placeholder>
            </Table.Cell>
            <Table.Cell collapsing>
                <Placeholder className="grey" style={{ width: '1rem', height: '1rem' }}>
                    <Placeholder.Image square />
                </Placeholder>
            </Table.Cell>
        </Table.Row>
    );
};