import uniq from "lodash/uniq";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getExchangeRates } from "../../orders";
import { GroupedMonthlyTotalAmount, MonthlyStats } from "../types";
import { calculateRatio, convertAmountToCurrency } from "../utils";
import { useReportDates } from "./useReportDates";

export const useGroupMonthlyStats = (
    groupedAmounts: GroupedMonthlyTotalAmount[],
    outputCurrency: string
) => {
    const defaultExchangeRates = useSelector(getExchangeRates);
    const { isCurrentPeriod, isPreviousPeriod } = useReportDates();
    const [monthlyStatsByGroupId, setMonthlyStatsByGroupId] = useState<
        Record<string | number, MonthlyStats>
    >({});
    const [isProcessing, setIsProcessing] = useState(true);

    useEffect(() => {
        const stats: Record<string | number, MonthlyStats> = {};

        const addGroupedAmount = (groupedAmount: GroupedMonthlyTotalAmount) => {
            const groupId = groupedAmount.groupId;

            const totalPrice = convertAmountToCurrency(
                groupedAmount,
                outputCurrency,
                defaultExchangeRates
            );

            const month = groupedAmount.month;
            const year = groupedAmount.year;

            if (!stats[groupId]) {
                stats[groupId] = {
                    statsByMonth: {},
                    currentPeriodTotal: 0,
                    previousPeriodTotal: 0,
                    ratio: 0,
                };
            }

            const monthlyStats = stats[groupId];

            if (!monthlyStats.statsByMonth[month]) {
                monthlyStats.statsByMonth[month] = {
                    month: month,
                    currentPeriodTotal: 0,
                    previousPeriodTotal: 0,
                    ratio: 0,
                };
            }

            if (isCurrentPeriod(month, year)) {
                monthlyStats.statsByMonth[month].currentPeriodTotal += totalPrice;
                monthlyStats.currentPeriodTotal += totalPrice;
            } else if (isPreviousPeriod(month, year)) {
                monthlyStats.statsByMonth[month].previousPeriodTotal += totalPrice;
                monthlyStats.previousPeriodTotal += totalPrice;
            }

            monthlyStats.statsByMonth[month].ratio = calculateRatio(
                monthlyStats.statsByMonth[month].previousPeriodTotal,
                monthlyStats.statsByMonth[month].currentPeriodTotal
            );

            monthlyStats.ratio = calculateRatio(
                monthlyStats.previousPeriodTotal,
                monthlyStats.currentPeriodTotal
            );
        };

        groupedAmounts.forEach((groupedAmount) => addGroupedAmount(groupedAmount));

        setMonthlyStatsByGroupId(stats);
        setIsProcessing(false);
    }, [defaultExchangeRates, groupedAmounts, isCurrentPeriod, isPreviousPeriod, outputCurrency]);

    const groupsId = uniq(
        groupedAmounts
            .filter((groupedAmount) => groupedAmount.totalAmount)
            .map((groupedAmount) => groupedAmount.groupId)
    );

    return { monthlyStatsByGroupId, groupsId, isProcessing };
};
