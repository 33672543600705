import { get } from '../../utils/api';
import { Order } from '.';
import { ExchangeRate, BocExchangeRatesResponse } from './types';
import dayjs from 'dayjs';

const endpoint = '/api/orders';
const bankOfCanadaEndpoint = 'https://www.bankofcanada.ca/valet';

export async function loadOrdersBetween(startDate: Date, endDate: Date) {
    return get<Order[]>(`${endpoint}/${startDate.toISOString()}/${endDate.toISOString()}`);
}

export async function loadExchangeRates(): Promise<ExchangeRate[]> {
    return get<ExchangeRate[]>(`${endpoint}/exchange-rates`);
}

export async function loadBankOfCanadaExchangeRate(): Promise<ExchangeRate[]> {
    // Bank of Canada API documentation:
    // https://www.bankofcanada.ca/valet/docs#valet_api
    const currencies: { [currencyCode: string]: string } = {
        'FXUSDCAD': 'USD',
        'FXEURCAD': 'EUR',
    };

    // Here we are not using our internal 'get' utility method, because we don't want to pass our default
    // request parameters to the Bank of Canada API.
    return fetch(`${bankOfCanadaEndpoint}/observations/${Object.keys(currencies).join(',')}/json?recent=1`)
        .then(response => response.json())
        .then((result: BocExchangeRatesResponse) => {
            const observation = result.observations[0];
            const fetchedOn = dayjs(observation.d).toDate();

            const exchangeRates = Object.keys(currencies).map((currencyCode): ExchangeRate => ({
                currency: currencies[currencyCode],
                rate: observation[currencyCode] != null ? Number(observation[currencyCode].v) : 1.0,
                lastUpdatedOn: fetchedOn,
            }));

            return exchangeRates;
        })
        .catch(e => {
            console.error('Failed to fetch the latest exchange rates from the Bank of Canada.');
            console.error(e);
            return [];
        });
}