import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Table } from "semantic-ui-react";

const m = defineMessages({
    billedOn: { id: "InvoicesTable.Headers.InvoiceDate", defaultMessage: "Date" },
    rebateAmount: { id: "InvoicesTable.Headers.RebateAmount", defaultMessage: "Rebate" },
    secondRebateAmount: { id: "InvoicesTable.Headers.SecondRebateAmount", defaultMessage: "Second Rebate" },
    salesAmount: { id: "InvoicesTable.Headers.SalesAmount", defaultMessage: "Sales Amount" },
    total: { id: "InvoicesTable.Headers.Total", defaultMessage: "Total" },
    commision: {
        id: "InvoicesTable.Headers.Commission",
        defaultMessage: "Commission",
    },
    exchangeRate: { id: "InvoicesTable.Headers.EXchangeRate", defaultMessage: "Exchange Rate" },
    glAmount: { id: "InvoicesTable.Headers.GlAmount", defaultMessage: "GL Amount" },
});

export const TableHeaders = () => {
    const { formatMessage } = useIntl();
    return (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>{formatMessage(m.billedOn)}</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                    {formatMessage(m.salesAmount)}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                    {formatMessage(m.glAmount)}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                    {formatMessage(m.rebateAmount)}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                    {formatMessage(m.secondRebateAmount)}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">{formatMessage(m.total)}</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                    {formatMessage(m.exchangeRate)}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                    {formatMessage(m.commision)}
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    );
};
