import React from "react";
import _ from 'lodash';
import { useSelector } from "react-redux";
import { Dropdown, DropdownItemProps, DropdownProps } from "semantic-ui-react";
import { ApplicationState } from '../../../../store/store';
import { getProvinceCodes } from "../../../orders";
import { defineMessages, useIntl } from "react-intl";

interface OwnProps {
    countryCode: string | undefined;
}

export type ProvinceFilterDropdownProps =
    & DropdownProps
    & OwnProps;

const m = defineMessages({
    allProvinces: { id: 'ProvinceFilterDropdown.allProvinces', defaultMessage: 'All provinces' }
});

export const allProvincesValue = -1;

export const ProvinceFilterDropdown: React.FC<ProvinceFilterDropdownProps> = (props) => {
    const { formatMessage } = useIntl();
    const { countryCode, ...dropdownProps } = props;
    const provinceCodes = useSelector((state: ApplicationState) => getProvinceCodes(state, countryCode || ''));
    const provinceDropdownProps = _.sortBy(provinceCodes, x => x).map((x): DropdownItemProps => ({
        text: x,
        value: x
    }));

    const allProvincesDropdownItem: DropdownItemProps = ({
        text: formatMessage(m.allProvinces),
        value: allProvincesValue
    });

    return (
        <Dropdown
            disabled={countryCode == null}
            options={[allProvincesDropdownItem, ...provinceDropdownProps]}
            {...dropdownProps}
        />
    );
};