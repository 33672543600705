import { User } from './types';
import { get, post } from '../../utils/api';

const endpoint = '/api/account/';

export async function logout(): Promise<void> {
    return get<void>(endpoint + 'logout');
}

export async function retrieve(): Promise<User>{
    return get<User>(endpoint + 'identity');
} 
