import {
    User,
    RETRIEVE_USER_REQUEST,
    RetrieveUserSuccessAction,
    RetrieveUserRequestAction,
    RetrieveUserFailureAction,
    RETRIEVE_USER_FAILURE,
    RETRIEVE_USER_SUCCESS,
    LOGOUT_REQUEST,
    LogoutRequestAction,
} from "./types";

export function logout(): LogoutRequestAction {
    return {
        type: LOGOUT_REQUEST,
        payload: {},
    };
}

export function retrieveUser(): RetrieveUserRequestAction {
    return {
        type: RETRIEVE_USER_REQUEST,
        payload: {},
    };
}

export const retrieveUserSuccess = (user: User): RetrieveUserSuccessAction => {
    return {
        type: RETRIEVE_USER_SUCCESS,
        payload: user,
    };
};

export const retrieveUserFailure = (error: Error): RetrieveUserFailureAction => {
    return {
        type: RETRIEVE_USER_FAILURE,
        payload: { error },
    };
};
