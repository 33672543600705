import { useSelector } from "react-redux";
import { getExchangeRates, OrdersFilters } from "../../orders";
import { Currency, GroupedTotalAmount, MonthlyStats, SalesStat } from "../types";
import { convertAmountToCurrency, getFilterOutputCurrency, sumMonthlyStats } from "../utils";
import { useGroupMonthlyStats } from "./useGroupMonthlyStats";
import uniq from "lodash/uniq";
import sum from "lodash/sum";

export interface SalesReportData {
    filters: OrdersFilters;
    currencyFilter: string;
    outputCurrency: string;
    monthsInOrder: number[];
    clientNameByClientCode: Record<string, string>;
    clientCodes: string[];
    monthlySalesByClientCode: Record<string, MonthlyStats>;
    incomingBillingAmountByClientCode: Record<string, number>;
    totalIncomingBillingAmount: number;
    billedAmountAfterEndDateByClientCode: Record<string, number>;
    totalBilledAmountAfterEndDate: number;
    totalMonthlyOrders: MonthlyStats;
}

export const useSalesReportData = (
    salesStat: SalesStat | undefined,
    filters: OrdersFilters,
    monthsInOrder: number[]
) => {
    const defaultExchangeRates = useSelector(getExchangeRates);
    const outputCurrency = getFilterOutputCurrency(filters.currency);

    const {
        monthlyStatsByGroupId: monthlyStatsByClientCode,
        groupsId: monthlyStatsGroupsId,
        isProcessing: isProcessingMontlyStats,
    } = useGroupMonthlyStats(salesStat?.salesByClientCode || [], outputCurrency);

    const allMonthlyStats = Object.values(monthlyStatsByClientCode);
    const totalMonthlyOrders: MonthlyStats = sumMonthlyStats(allMonthlyStats);

    const sumAmountByGroupId = (amounts: GroupedTotalAmount[]) =>
        amounts.reduce((result, amount) => {
            if (!result[amount.groupId]) {
                result[amount.groupId] = 0;
            }
            const amountConvertedInOutputCurrency = convertAmountToCurrency(
                amount,
                outputCurrency,
                defaultExchangeRates
            );
            result[amount.groupId] += amountConvertedInOutputCurrency;
            return result;
        }, {} as Record<string, number>);

    const incomingBillingAmountByClientCode = sumAmountByGroupId(
        salesStat?.upcomingBillingAmountByClientCode || []
    );

    const billedAmountAfterEndDateByClientCode = sumAmountByGroupId(
        salesStat?.billedAmountAfterEndDateByClientCode || []
    );

    const clientCodes = uniq([
        ...monthlyStatsGroupsId,
        ...Object.keys(incomingBillingAmountByClientCode),
        ...Object.keys(billedAmountAfterEndDateByClientCode),
    ]);

    const totalIncomingBillingAmount = sum(Object.values(incomingBillingAmountByClientCode));
    const totalBilledAmountAfterEndDate = sum(Object.values(billedAmountAfterEndDateByClientCode));

    const salesReportData: SalesReportData = {
        filters: filters,
        currencyFilter: filters.currency || Currency.CanadianDollar,
        outputCurrency: getFilterOutputCurrency(filters.currency),
        clientNameByClientCode: salesStat?.clientNamesByClientCode || {},
        clientCodes: clientCodes as string[],
        monthlySalesByClientCode: monthlyStatsByClientCode,
        incomingBillingAmountByClientCode: incomingBillingAmountByClientCode,
        billedAmountAfterEndDateByClientCode: billedAmountAfterEndDateByClientCode,
        monthsInOrder: monthsInOrder,
        totalMonthlyOrders,
        totalIncomingBillingAmount,
        totalBilledAmountAfterEndDate,
    };

    const isProcessing = isProcessingMontlyStats;

    return { salesReportData, isProcessing };
};
