import React from 'react';
import { Set } from '../../types';
import { Segment, Label, Placeholder, Popup, Header } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import styles from './ClientSets.module.css';

export interface ClientSetsProps {
    clientId: number;
    branchId: number;
    sets: Set[];
    loading?: boolean;
}

const m = defineMessages({
    manageAllSets: { id: 'ClientSets.manageAllSets', defaultMessage: 'Manage all sets' },
    noContentTitle: { id: 'ClientSets.noContentTitle', defaultMessage: 'There are no sets linked with this client.' },
    noContentAction: { id: 'ClientSets.noContentAction', defaultMessage: 'Link a set' }
});

export const ClientSets: React.FC<ClientSetsProps> = (props) => {
    const { formatMessage } = useIntl();
    const renderSet = (set: Set) => {
        return (
            <Popup key={set.id} hideOnScroll trigger={<Label style={{ cursor: 'pointer' }}>{set.name}</Label>}>
                {set.setCode}
            </Popup>
        );
    };

    return (
        <Segment className={styles.setsList}>
            {!props.loading && props.sets.length > 0 &&
                <>
                    {props.sets.map(renderSet)}
                    <div style={{ fontSize: '1rem', marginTop: '.5rem' }}>
                        <NavLink to={`/clients/${props.clientId}/branches/${props.branchId}/sets`}>
                            {formatMessage(m.manageAllSets)}
                        </NavLink>
                    </div>
                </>
            }

            {!props.loading && props.sets.length <= 0 &&
                <>
                    <Header icon size="tiny" disabled textAlign="center">
                        {formatMessage(m.noContentTitle)}
                    </Header>
                    <div style={{ fontSize: '1rem', marginTop: '.5rem' }}>
                        <NavLink to={`/clients/${props.clientId}/branches/${props.branchId}/sets`}>
                            {formatMessage(m.noContentAction)}
                        </NavLink>
                    </div>
                </>
            }


            {props.loading &&
                <Placeholder style={{ marginTop: '-14px' }}>
                    <Placeholder.Line length="full" />
                </Placeholder>
            }
        </Segment>
    );
};