import React from "react";
import { useIntl } from "react-intl";
import { TableCell, TableCellProps } from "semantic-ui-react";

interface RatioCellProps extends TableCellProps {
    ratio: number;
    previousAmount: number;
    currentAmount: number;
}

export const RatioCell: React.FC<RatioCellProps> = ({
    ratio,
    previousAmount,
    currentAmount,
    ...tableCellProps
}) => {
    const { formatNumber } = useIntl();

    const hasAnyAmount = previousAmount || currentAmount;
    const formattedRatio = hasAnyAmount ? formatNumber(ratio, { style: "percent" }) : "";

    return (
        <TableCell
            textAlign="right"
            {...tableCellProps}
            style={{ fontWeight: "bold", ...tableCellProps.style }}
        >
            {formattedRatio}
        </TableCell>
    );
};
