import React from 'react';
import _ from 'lodash';
import { Dropdown, DropdownProps, DropdownItemProps } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { getAllClients, areClientsLoading } from '../../selectors';

interface OwnProps {
    defaultOption?: DropdownItemProps;
}

export type ClientsDropdownProps =
    & OwnProps
    & DropdownProps;

export const ClientsDropdown: React.FC<ClientsDropdownProps> = (props) => {
    const { defaultOption, ...dropdownProps } = props;

    const clients = useSelector(getAllClients);
    const loading = useSelector(areClientsLoading);
    const clientsOptions: DropdownItemProps[] = _(clients)
        .sortBy(x => x.displayName)
        .map(x => ({
            text: x.displayName,
            value: x.id,
        }))
        .value();

    return (
        <Dropdown
            loading={loading}
            options={defaultOption != null ? [defaultOption, ...clientsOptions] : clientsOptions}
            {...dropdownProps}
        />
    );
};