import { useCallback } from "react";
import { useIntl } from "react-intl";

const replaceNarrowNonBreakingSpaces = (text: string): string => text.replaceAll("\u202f", "\x20");

export const useReportPdf = () => {
    const { formatDate, formatNumber } = useIntl();

    const getFormattedDate = useCallback(
        (date: Date): string =>
            formatDate(date, { day: "numeric", month: "numeric", year: "2-digit" }),
        [formatDate]
    );

    const getFormattedAmount = useCallback(
        (amount: number): string =>
            amount
                ? replaceNarrowNonBreakingSpaces(
                      formatNumber(amount, {
                          style: "decimal",
                          useGrouping: true,
                          maximumFractionDigits: 0,
                      })
                  )
                : "",
        [formatNumber]
    );

    const getFormattedRatio = useCallback(
        (ratio: number, previousAmount: number, currentAmount: number): string => {
            const hasAnyAmount = previousAmount || currentAmount;
            return hasAnyAmount
                ? replaceNarrowNonBreakingSpaces(formatNumber(ratio, { style: "percent" }))
                : "";
        },
        [formatNumber]
    );

    return { getFormattedDate, getFormattedAmount, getFormattedRatio };
};
