import React from 'react';
import { IntlProvider } from 'react-intl';
import { defaultLocale } from '../../configureI18n';
import { I18nMessages } from '../../configureI18n';
import { ApplicationState } from '../../store/store';
import { getCurrentLocale } from '../../modules/layout';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/en-ca';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore  from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter  from 'dayjs/plugin/isSameOrAfter';
import isBetween  from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(localizedFormat);

export interface I18nProviderProps {
    children?: React.ReactNode;
    messages: I18nMessages;
}

const I18nProvider : React.FC<I18nProviderProps> = (props) => {
    const locale = useSelector((state: ApplicationState) => getCurrentLocale(state));

    dayjs.locale(`${locale}-ca`);

    // English is our default locale throughout the application, so it is assumed that the "defaultMessage" properties
    // on our components contain the actual English message to display. This is the reason why we don't pass the messages
    // property for this locale.
    const messages = locale === defaultLocale ? undefined : props.messages[locale];

    return (
        <IntlProvider locale={locale} messages={messages} key={locale}>
            {React.Children.only(props.children)}
        </IntlProvider>
    );
};

export { I18nProvider };