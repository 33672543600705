import parse from "autosuggest-highlight/parse";
import React from "react";

interface GoogleAutocompleteResultProps {
  prediction: google.maps.places.AutocompletePrediction;
}

export const GoogleAutocompleteResult: React.FC<GoogleAutocompleteResultProps> = (props) => {
  const matches = props.prediction.structured_formatting.main_text_matched_substrings;
  const parts = parse(props.prediction.structured_formatting.main_text, matches.map((match) => [match.offset, match.offset + match.length]));

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          {parts.map((part, index) => (
            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>{part.text}</span>
          ))}
        </div>
        <div style={{ fontSize: '.85rem' }}>
          {props.prediction.structured_formatting.secondary_text}
        </div>
      </div>
    );
};