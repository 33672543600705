import React from 'react';
import _ from 'lodash';
import { Activity } from '../../../activities/types';
import { ActivityFeedEvent } from '../../../activities/views/components/ActivityFeedEvent';
import { Feed, Segment, Header } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import { LoadingActivityFeedEvent } from '../../../activities/views/components/LoadingActivityFeedEvent';

export interface BranchActivitiesProps {
    branchId: number;
    clientId: number;
    activities: Activity[];
    loading?: boolean;
    maxResults?: number;

    onNavigate: (activity: Activity) => void;
}

const m = defineMessages({
    seeAllLink: { id: 'BranchActivities.seeAllLink', defaultMessage: 'See all activities' },
    noContentTitle: { id: 'BranchActivities.noContentTitle', defaultMessage: 'There are no activities for this branch.' },
    noContentAction: { id: 'BranchActivities.noContentAction', defaultMessage: 'Add an activity' }
});

export const BranchActivities: React.FC<BranchActivitiesProps> = (props) => {
    const { formatMessage } = useIntl();

    return (
        <Segment>
            {!props.loading && props.activities.length > 0 &&
                <Feed size="small">
                    {_(props.activities)
                        .orderBy(x => x.createdOn)
                        .reverse()
                        .map(x => <ActivityFeedEvent key={x.id} activity={x} onClick={() => props.onNavigate(x)} />)
                        .take(props.maxResults)
                        .value()
                    }

                    {props.maxResults && props.maxResults < props.activities.length &&
                        <div style={{ fontSize: '1rem', marginTop: '.5rem' }}>
                            <NavLink to={`/clients/${props.clientId}/branches/${props.branchId}/activities`}>
                                {formatMessage(m.seeAllLink)}
                            </NavLink>
                        </div>
                    }
                </Feed>
            }

            {!props.loading && props.activities.length <= 0 &&
                <>
                    <Header icon size="tiny" disabled textAlign="center">
                        {formatMessage(m.noContentTitle)}
                    </Header>
                    <div>
                        <NavLink to={`/activities/new/${props.clientId}/${props.branchId}`}>
                            {formatMessage(m.noContentAction)}
                        </NavLink>
                    </div>
                </>
            }

            {props.loading &&
                <>
                    <LoadingActivityFeedEvent />
                    <LoadingActivityFeedEvent />
                    <LoadingActivityFeedEvent />
                </>
            }
        </Segment>
    );
};