import dayjs from "dayjs";
import deburr from "lodash/deburr";
import orderBy from "lodash/orderBy";
import {
    Content,
    PageOrientation,
    PredefinedPageSize,
    TableCell,
    TDocumentDefinitions,
} from "pdfmake/interfaces";
import { defineMessages, useIntl } from "react-intl";
import { OrdersReportData } from "./useOrdersReportData";
import { useReportFiltersPdf } from "./useReportFiltersPdf";
import { useReportPdf } from "./useReportPdf";
import { mainTableLayout, styles } from "./pdfStyles";

const defaultPageSettings = {
    pageSize: "LETTER" as PredefinedPageSize,
    pageOrientation: "landscape" as PageOrientation,
    pageMargins: [30, 60, 30, 40] as [number, number, number, number],
};


const defaultStyle = {
    fontSize: 8,
};

const m = defineMessages({
    title: { id: "OrdersReportPdf.Title", defaultMessage: "Orders by client report" },
    client: { id: "OrdersReportPdf.Client", defaultMessage: "Client" },
    period: { id: "OrdersReportPdf.Period", defaultMessage: "Period" },
    totals: { id: "OrdersReportPdf.Totals", defaultMessage: "Totals" },
    currentPeriod: { id: "OrdersReportPdf.CurrentPeriod", defaultMessage: "Current" },
    previousPeriod: { id: "OrdersReportPdf.PreviousPeriod", defaultMessage: "Previous" },
});

export const useOrdersReportPdf = (reportData: OrdersReportData) => {
    const { formatMessage } = useIntl();

    const { getFormattedAmount, getFormattedDate, getFormattedRatio } = useReportPdf();
    const { getActiveFilterTable } = useReportFiltersPdf(reportData.filters);

    const clientReportHeader = (currentPage: number): Content => {
        return {
            margin: [30, 20, 30, 0],
            columns: [
                { text: `Date ${getFormattedDate(new Date())}` },
                {
                    width: "*",
                    style: "documentTitle",
                    text: formatMessage(m.title).toUpperCase(),
                },
                {
                    alignment: "right",
                    text: `Page ${currentPage}`,
                },
            ],
        };
    };

    const getTableHeaders = () : TableCell[] => {
        return [
            { text: formatMessage(m.client).toUpperCase() },
            { text: formatMessage(m.period).toUpperCase() },
            ...reportData.monthsInOrder.map((month) => ({
                text: dayjs().month(month).format("MMM").toUpperCase(),
                style: "monthHeader",
            })),
            { text: formatMessage(m.totals).toUpperCase(), style: "totalHeader" },
        ];
    };

    const getCurrentPeriodRow = (clientCode: string) : TableCell[] => {
        const monthlyOrders = reportData.monthlyOrdersByClientCode[clientCode];

        return [
            { text: clientCode },
            { text: formatMessage(m.currentPeriod) },
            ...reportData.monthsInOrder.map((month) => ({
                text: getFormattedAmount(monthlyOrders?.statsByMonth[month]?.currentPeriodTotal),
                style: "periodAmount",
            })),
            { text: getFormattedAmount(monthlyOrders?.currentPeriodTotal), style: "totalAmount" },
        ];
    };

    const getPreviousPeriodRow = (clientCode: string) : TableCell[] => {
        const monthlyOrders = reportData.monthlyOrdersByClientCode[clientCode];

        return [
            { text: "" },
            { text: formatMessage(m.previousPeriod) },
            ...reportData.monthsInOrder.map((month) => ({
                text: getFormattedAmount(monthlyOrders?.statsByMonth[month]?.previousPeriodTotal),
                style: "periodAmount",
            })),
            { text: getFormattedAmount(monthlyOrders?.previousPeriodTotal), style: "totalAmount" },
        ];
    };

    const getRatiosRow = (clientCode: string) : TableCell[] => {
        const clientName = reportData.clientNameByClientCode[clientCode] || "";
        const monthlyOrders = reportData.monthlyOrdersByClientCode[clientCode];

        return [
            { text: clientName },
            { text: "" },
            ...reportData.monthsInOrder.map((month) => ({
                text: getFormattedRatio(
                    monthlyOrders?.statsByMonth[month]?.ratio,
                    monthlyOrders?.statsByMonth[month]?.previousPeriodTotal,
                    monthlyOrders?.statsByMonth[month]?.currentPeriodTotal
                ),
                style: "periodRatio",
            })),
            {
                text: getFormattedRatio(
                    monthlyOrders?.ratio,
                    monthlyOrders?.previousPeriodTotal,
                    monthlyOrders?.currentPeriodTotal
                ),
                style: "totalRatio",
            },
        ];
    };

    const getClientRows = (clientCode: string) : TableCell[][] => {
        return [
            getCurrentPeriodRow(clientCode),
            getPreviousPeriodRow(clientCode),
            getRatiosRow(clientCode),
        ];
    };

    const getTableRows = () : TableCell[][] => {
        const orderedClientCodes = orderBy(reportData.clientCodes, (clientCode) =>
            deburr((reportData.clientNameByClientCode[clientCode] || "").toLowerCase())
        );

        return orderedClientCodes.flatMap((clientCode) => getClientRows(clientCode));
    };

    const getMainTable = (): Content => {
        return {
            table: {
                headerRows: 1,
                widths: [
                    "*",
                    "auto",
                    "auto",
                    "auto",
                    "auto",
                    "auto",
                    "auto",
                    "auto",
                    "auto",
                    "auto",
                    "auto",
                    "auto",
                    "auto",
                    "auto",
                    "auto",
                ],
                body: [getTableHeaders(), ...getTableRows()],
            },
            layout: mainTableLayout,
        };
    };

    const getDocumentDefinition = (): TDocumentDefinitions => {
        return {
            ...defaultPageSettings,
            header: clientReportHeader,
            styles: styles,
            defaultStyle: defaultStyle,
            content: [getActiveFilterTable(), getMainTable()],
        };
    };

    return {
        getDocumentDefinition,
    };
};
