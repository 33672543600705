import _ from 'lodash';
import { ApplicationState } from '../../store/store';
import { User } from '../account/types';

export const getRepresentativeById = (state: ApplicationState, id: string) => state.representatives.byId[id];
export const getAllRepresentatives = (state: ApplicationState) => state.representatives.allIds.map(id => getRepresentativeById(state, id)).filter(x => x != null);
export const areRepresentativesLoading = (state: ApplicationState) => state.representatives.loading;

export const getRepresentativeDisplayName = (representative: User | undefined): string => {
    return representative != null
        ? _.trim(`${representative.firstName || ''} ${representative.lastName || ''}`)
        : '';
};