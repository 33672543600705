import React, { useState } from 'react';
import { Client } from '../../types';
import { Accordion, Icon, AccordionTitleProps, Placeholder } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import styles from './ClientList.module.css';

export interface ClientListProps {
    clients: Client[];
    loading?: boolean;
}

export const ClientList: React.FC<ClientListProps> = (props) => {
    const [selectedClient, setSelectedClient] = useState<number | undefined>(undefined);
    const selectClient = (_: React.MouseEvent<HTMLDivElement>, data: AccordionTitleProps) => {
        const index = data.index != null ? Number(data.index) : undefined;
        setSelectedClient(index !== selectedClient ? index : undefined);
    };

    const renderClient = (client: Client, index: number) => (
        <div key={client.id}>
            <Accordion.Title
                className={styles.title}
                active={selectedClient === index}
                index={index}
                onClick={selectClient}
                style={{ textTransform: 'uppercase' }}
            >
                <Icon name="dropdown" /> {client.displayName}
            </Accordion.Title>
            <Accordion.Content
                className={styles.content}
                active={selectedClient === index}
            >
                {client.branches
                    .sort((a, b) => (a.city || '').localeCompare(b.city || ''))
                    .map(branch => (
                        <NavLink key={branch.id} to={`/clients/${branch.clientId}/branches/${branch.id}`}>
                            {branch.city}
                        </NavLink>
                    ))
                }
            </Accordion.Content>
        </div>
    );

    if (props.loading) {
        return (
            <Placeholder>
                <Placeholder.Line length="full" />
                <Placeholder.Line length="full" />
                <Placeholder.Line length="full" />
                <Placeholder.Line length="full" />
                <Placeholder.Line length="full" />
                <Placeholder.Line length="full" />
                <Placeholder.Line length="full" />
                <Placeholder.Line length="full" />
            </Placeholder>
        );
    }

    return (
        <Accordion>
            {props.clients.map(renderClient)}
        </Accordion>
    );
};