import React from "react";
import styles from "./FloatingActionButtons.module.css";

interface FloatingActionButtonsProps {
    children?: React.ReactNode;
}

export const FloatingActionButtons: React.FC<FloatingActionButtonsProps> = (props) => {
    return <div className={styles.container}>{props.children}</div>;
};
