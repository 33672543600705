import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import {
    FloatingActionButton,
    FloatingActionButtons,
} from "../../../components/floatingActionButton";
import { OrdersReportLayout } from "./OrdersReportLayout";
import { OrdersReportTable } from "../components/OrdersReportTable";
import { useReportDates } from "../hooks/useReportDates";
import { useReportOrders } from "../hooks/useReportOrders";
import { useOrdersReportData } from "../hooks/useOrdersReportData";
import { ReportActiveFilters } from "../components/ReportActiveFilters";
import { useDispatch } from "react-redux";
import * as LayoutActions from "../../layout/actions";
import { defineMessages, useIntl } from "react-intl";
import { useReportFilters } from "../hooks/useReportFilters";
import { useOrdersReportPdf } from "../hooks/useOrdersReportPdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const m = defineMessages({
    title: { id: "OrdersReportPage.title", defaultMessage: "Orders by client report" },
});

export const OrdersReportPage = () => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(LayoutActions.setPageTitle(formatMessage(m.title)));
    }, [dispatch, formatMessage]);

    const { filters, clearFilter } = useReportFilters();

    const { reportMonthsInOrder, currentPeriodEndDate, previousPeriodStartDate } = useReportDates();

    const { ordersStat, isLoading: isLoadingOrders } = useReportOrders(
        previousPeriodStartDate,
        currentPeriodEndDate,
        filters
    );

    const { ordersReportData, isProcessing } = useOrdersReportData(
        ordersStat,
        filters,
        reportMonthsInOrder
    );

    const isLoading = isLoadingOrders || isProcessing;

    const { getDocumentDefinition } = useOrdersReportPdf(ordersReportData);

    const generatePdf = () => {
        const documentDefinition = getDocumentDefinition();
        pdfMake.createPdf(documentDefinition).download(`${formatMessage(m.title)}.pdf`);
    };

    return (
        <OrdersReportLayout>
            <ReportActiveFilters onClear={clearFilter} />
            <OrdersReportTable ordersReportData={ordersReportData} isLoading={isLoading} />
            <FloatingActionButtons>
                <FloatingActionButton icon="file pdf" onClick={generatePdf} />
                <FloatingActionButton
                    icon="filter"
                    onClick={() => navigate(`/reports/orders/filters${location.search}`)}
                />
            </FloatingActionButtons>
        </OrdersReportLayout>
    );
};
