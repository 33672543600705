import React from "react";
import { useIntl } from "react-intl";
import { Table, TableCell } from "semantic-ui-react";
import { Invoice } from "../../../orders";
import { calculateInvoiceOrderReportPrice } from "../../utils";

interface TableRowProps {
    invoice: Invoice;
}

const nonBreakingSpace = "\u00A0";

export const TableRow: React.FC<TableRowProps> = ({ invoice }) => {
    const { formatDate, formatNumber } = useIntl();

    const commission = 1 - invoice.adjustmentForSalesCommission;

    const getFormattedAmount = (amount: number, currency = "") =>
        [
            formatNumber(amount, {
                style: "decimal",
                useGrouping: true,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            currency,
        ]
            .filter((x) => x)
            .join(nonBreakingSpace);

    const invoiceTotal = calculateInvoiceOrderReportPrice(invoice, invoice.currency, []);

    return (
        <Table.Row key={invoice.id}>
            <TableCell>
                {invoice.id}
            </TableCell>
            <TableCell>
                {formatDate(invoice.billedOn, {
                    day: "numeric",
                    month: "numeric",
                    year: "2-digit",
                })}
            </TableCell>
            <TableCell textAlign="right">{getFormattedAmount(invoice.salesAmount)}</TableCell>
            <TableCell textAlign="right">{getFormattedAmount(invoice.ledgerAmount)}</TableCell>
            <TableCell textAlign="right">{getFormattedAmount(invoice.rebateAmount)}</TableCell>
            <TableCell textAlign="right">{getFormattedAmount(invoice.secondRebateAmount)}</TableCell>
            <TableCell textAlign="right">
                {getFormattedAmount(invoiceTotal, invoice.currency)}
            </TableCell>
            <TableCell textAlign="right">{invoice.exchangeRate}</TableCell>
            <TableCell textAlign="right">{formatNumber(commission, { style: "percent" })}</TableCell>
        </Table.Row>
    );
};
