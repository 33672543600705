import React from 'react';
import { Form, Segment, Icon, Header, Divider, Message, TextAreaProps } from 'semantic-ui-react';
import { ActivityEdit } from '../../models';
import { ClientBranchPicker } from './ClientBranchPicker';
import { ActivityTypeControl } from './ActivityTypeControl';
import { MediaGallery, MediaFile } from './MediaGallery';
import { Client } from '../../../clients/types';
import { nameof } from '../../../../utils/nameof';
import { defineMessages, useIntl } from 'react-intl';
import { ValidationResult } from '../../../../utils/ValidationResult';

export interface ActivityFormProps {
    activity: ActivityEdit;
    attachments: MediaFile[];
    validationResult: ValidationResult;
    allClients: Client[];
    loading?: boolean;
    disabled?: boolean;
    className?: string;
    style?: React.CSSProperties;

    onChange: (fieldId: string, fieldValue: string | number | undefined) => void;
    onAddAttachments: (attachments: File[]) => void;
    onDeleteAttachment: (attachment: MediaFile) => void;
}

const m = defineMessages({
    activityTypeLabel: { id: 'ActivityForm.activityTypeLabel', defaultMessage: 'Activity type' },
    notesLabel: { id: 'ActivityForm.notesLabel', defaultMessage: 'Notes' },
    attachmentsLabel: { id: 'ActivityForm.attachmentsLabel', defaultMessage: 'Attachments (optional)' },
    addAttachmentButton: { id: 'ActivityForm.addAttachmentLabel', defaultMessage: 'Upload attachment' },
    addAttachmentInstructions: { id: 'ActivityForm.addAttachmentInstructions', defaultMessage: 'Choose one or more pictures from your mobile device.' }
});

export const ActivityForm: React.FC<ActivityFormProps> = (props) => {
    const { formatMessage } = useIntl();
    const selectedClient = props.allClients.find(x => x.id === props.activity.clientId);
    const selectedBranch = selectedClient?.branches.find(x => x.id === props.activity.branchId);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => props.onAddAttachments(Array.from(event.target.files || []));

    const handleTextAreaChange = (event: React.FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
        const fieldId = event.currentTarget.id;
        props.onChange(fieldId, data.value);
    };

    return (
        <Form error={!props.validationResult.isValid} className={props.className} style={props.style}>
            <ClientBranchPicker
                loading={props.loading}
                disabled={props.disabled}
                isClientInError={props.validationResult.isFieldInvalid(nameof<ActivityEdit>('clientId'))}
                isBranchInError={props.validationResult.isFieldInvalid(nameof<ActivityEdit>('branchId'))}
                clients={props.allClients}
                selectedClient={selectedClient}
                selectedBranch={selectedBranch}
                onClientChange={client => props.onChange(nameof<ActivityEdit>('clientId'), client?.id)}
                onBranchChange={branch => props.onChange(nameof<ActivityEdit>('branchId'), branch?.id)}
            />

            <Form.Field disabled={props.disabled} style={{ marginTop: '1.25rem' }}>
                <label>{formatMessage(m.activityTypeLabel)}</label>
                <ActivityTypeControl
                    selectedType={props.activity.type}
                    onChange={type => props.onChange(nameof<ActivityEdit>('type'), type)}
                />
            </Form.Field>

            <Form.TextArea
                id={nameof<ActivityEdit>('note')}
                error={props.validationResult.isFieldInvalid(nameof<ActivityEdit>('note'))}
                disabled={props.disabled}
                label={formatMessage(m.notesLabel)}
                onChange={handleTextAreaChange}
                value={props.activity.note || ''}
            />

            <Form.Field disabled={props.disabled}>
                <label>{formatMessage(m.attachmentsLabel)}</label>
                <Segment textAlign="center">
                    <Header subheader={formatMessage(m.addAttachmentInstructions)} />
                    <label className="ui button">
                        <Icon name="camera" />
                        {formatMessage(m.addAttachmentButton)}
                        <input
                            type="file"
                            accept="image/png,image/gif,image/jpeg,image/bmp"
                            style={{ display: 'none' }}
                            multiple={true}
                            onChange={handleFileUpload}
                        />
                    </label>

                    {props.attachments.length > 0 &&
                        <div style={{ textAlign: 'left' }}>
                            <Divider />
                            <MediaGallery
                                size="tiny"
                                files={props.attachments}
                                onDelete={props.onDeleteAttachment}
                            />
                        </div>
                    }
                </Segment>
            </Form.Field>

            <Message error list={props.validationResult.localizeErrorMessages(formatMessage)} />
        </Form>
    );
};