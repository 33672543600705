import { LoadRepresentativesRequestAction, LOAD_REPRESENTATIVES_REQUEST, LoadRepresentativesSuccessAction, LOAD_REPRESENTATIVES_SUCCESS, LoadRepresentativesFailureAction, LOAD_REPRESENTATIVES_FAILURE } from "./types";
import { User } from "../account/types";

export function loadRepresentatives(): LoadRepresentativesRequestAction {
    return { type: LOAD_REPRESENTATIVES_REQUEST, payload: {} };
}

export function loadRepresentativesSuccess(representatives: User[]): LoadRepresentativesSuccessAction {
    return { type: LOAD_REPRESENTATIVES_SUCCESS, payload: representatives };
}

export function loadRepresentativesFailure(error: Error): LoadRepresentativesFailureAction {
    return { type: LOAD_REPRESENTATIVES_FAILURE, payload: { error } };
}