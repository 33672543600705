import React from "react";
import SinglePageLayout from "../../../components/layouts/SinglePageLayout";

interface GoalsLayoutProps {
    children?: React.ReactNode;
}

const GoalsLayout: React.FC<GoalsLayoutProps> = (props) => {
    return (
        <SinglePageLayout>
            <div style={{ paddingTop: ".25rem", height: "100%" }}>{props.children}</div>
        </SinglePageLayout>
    );
};

export default GoalsLayout ;
