import React from "react";
import { Table, Popup, Icon, TableRowProps } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { defineMessages, useIntl } from "react-intl";
import { formatMoney } from "../../../../utils/formatMoney";
import styles from "./GoalsSummaryRow.module.css";

interface OwnProps {
    label: string;
    currentAmount: number;
    currency: string;
    previousAmount?: number;
    icon?: React.ReactNode;
}

export type GoalsSummaryRowProps = OwnProps & TableRowProps;

const m = defineMessages({
    variation: { id: "GoalsSummaryRow.variation", defaultMessage: "({value}%)" },
    positiveVariationTooltip: {
        id: "GoalsSummaryRow.positiveVariationTooltip",
        defaultMessage:
            "A <b>{amount} {currency}</b> increase compared to the same date last year.",
    },
    negativeVariationTooltip: {
        id: "GoalsSummaryRow.negativeVariationTooltip",
        defaultMessage:
            "A <b>{amount} {currency}</b> decrease compared to the same date last year.",
    },
});

export const GoalsSummaryRow: React.FC<GoalsSummaryRowProps> = (props) => {
    const { formatMessage } = useIntl();
    const { label, currentAmount, currency, previousAmount, icon, ...rowProps } = props;
    const hasDecreased = previousAmount && currentAmount < previousAmount;
    const variationInPercentage = previousAmount
        ? Math.round(Math.abs(currentAmount / previousAmount - 1) * 100)
        : undefined;

    const renderVariation = () => {
        const variationStyle = hasDecreased ? styles.negative : styles.positive;
        const variationIcon: SemanticICONS = hasDecreased ? "caret down" : "caret up";

        return (
            <span className={`${styles.variation} ${variationStyle}`}>
                {formatMessage(m.variation, { value: variationInPercentage })}{" "}
                <Icon name={variationIcon} fitted />
            </span>
        );
    };

    return (
        <Table.Row verticalAlign="middle" {...rowProps}>
            <Table.Cell collapsing className={styles.label} textAlign="right">
                {label}
            </Table.Cell>
            <Table.Cell textAlign="right">
                <span className={styles.amount}>{formatMoney(currentAmount, true)}</span>
                <span className={styles.currency}>{currency}</span>
            </Table.Cell>
            <Table.Cell textAlign="right" collapsing>
                {previousAmount && (
                    <Popup position="bottom right" trigger={renderVariation()}>
                        {formatMessage(
                            hasDecreased ? m.negativeVariationTooltip : m.positiveVariationTooltip,
                            {
                                amount: formatMoney(Math.abs(currentAmount - previousAmount), true),
                                currency: currency,
                                b: (chunks: React.ReactNode) => <strong key={1}>{chunks}</strong>,
                            }
                        )}
                    </Popup>
                )}
            </Table.Cell>
            <Table.Cell collapsing>{icon}</Table.Cell>
        </Table.Row>
    );
};
