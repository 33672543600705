import React, { useEffect, useRef } from 'react';
import GoalsLayout from './GoalsLayout';
import dayjs from 'dayjs';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as GoalsActions from '../../goals/actions';
import * as LayoutActions from '../../layout/actions';
import { GoalsSummary } from './components/GoalsSummary';
import { GoalsActiveFilters } from './components/GoalsActiveFilters';
import { GoalChart } from './components/GoalChart';
import { FloatingActionButtons, FloatingActionButton } from '../../../components/floatingActionButton';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ApplicationState } from '../../../store/store';
import { getOrders, OrdersFilters, areOrdersLoading, OrderStatus } from '../../orders';
import { getGoalsTotalForYear } from '../selectors';
import { GoalsChartType } from '../types';

const m = defineMessages({
    title: { id: 'GoalsPage.title', defaultMessage: 'Goals' }
});

export const GoalsPage = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const loading = useSelector(areOrdersLoading);
    const filters = queryString.parse(location.search) as OrdersFilters;

    const divRef = useRef<HTMLDivElement>(null);

    const clearFilter = (filterId: string) => {
        const activeFilters: OrdersFilters = { ...queryString.parse(location.search), [filterId]: undefined };
        navigate(`${location.pathname}?${queryString.stringify(activeFilters)}`);
    };

    useEffect(() => {
        dispatch(LayoutActions.setPageTitle(formatMessage(m.title)));
        dispatch(GoalsActions.loadGoals());

    }, [dispatch, formatMessage]);

    const startDate = dayjs().local().startOf('year').toDate();
    const endDate = dayjs().local().toDate();
    const previousStartDate = dayjs().local().add(-1, 'year').startOf('year').toDate();
    const previousYtdEndDate = dayjs().local().add(-1, 'year').toDate();

    const currentOrders = useSelector((state: ApplicationState) => getOrders(state, startDate, endDate, filters));
    const shippedOrders = currentOrders.filter(x => x.status >= OrderStatus.shipped);
    const ytdPreviousOrders = useSelector((state: ApplicationState) => getOrders(state, previousStartDate, previousYtdEndDate, filters));
    const allPreviousOrders = useSelector((state: ApplicationState) => getOrders(state, previousStartDate, dayjs(previousStartDate).endOf('year').toDate(), filters));

    const chartType = filters.month != null ? GoalsChartType.specificMonth : GoalsChartType.wholeYear;
    const yearlyGoal = useSelector((state: ApplicationState) => getGoalsTotalForYear(state, dayjs().local().year(), filters.clientId));
    const currentGoal = chartType === GoalsChartType.wholeYear ? (yearlyGoal / 12) * (dayjs().local().month() + 1) : (yearlyGoal / 12);

    const chartWidth = divRef.current?.offsetWidth || undefined;
    const chartHeight = divRef.current?.offsetHeight || undefined;

    return (
        <GoalsLayout>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div>
                    <GoalsActiveFilters onClear={clearFilter} />
                    <GoalsSummary
                        loading={loading}
                        currentOrders={currentOrders}
                        ytdPreviousOrders={ytdPreviousOrders}
                        allPreviousOrders={allPreviousOrders}
                        goal={currentGoal}
                        currency={filters.currency}
                    />
                </div>
                <div ref={divRef} style={{ flexGrow: 1, minHeight: 0 }} >
                    <GoalChart
                        width={chartWidth || undefined}
                        height={chartHeight ? chartHeight - 5 : undefined}
                        type={filters.month != null ? GoalsChartType.specificMonth : GoalsChartType.wholeYear}
                        filters={filters}
                        currentOrders={currentOrders}
                        currentShippedOrders={shippedOrders}
                        previousOrders={allPreviousOrders}
                        yearlyGoal={yearlyGoal}
                        loading={loading}
                    />
                </div>
            </div>

            <FloatingActionButtons>
                <FloatingActionButton
                    icon="filter"
                    onClick={() => navigate(`/goals/filters${location.search}`)}
                />
                <FloatingActionButton
                    primary
                    icon="add"
                    onClick={() => navigate(`/goals/new`)}
                />
            </FloatingActionButtons>
        </GoalsLayout>
    );
};