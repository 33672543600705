import React from "react";
import { TableCellProps } from "semantic-ui-react";
import { getUrlForMonthClientSales } from "../../utils";
import { AmountCell } from "../AmountCell";

interface MonthTotalCellProps extends TableCellProps {
    clientCode: string;
    total: number;
    month: number;
    year: number;
}

export const MonthTotalCell: React.FC<MonthTotalCellProps> = ({
    clientCode,
    total,
    month,
    year,
    ...tableCellProps
}) => {
    const url = getUrlForMonthClientSales(clientCode, month, year);

    return (
        <AmountCell
            {...tableCellProps}
            amount={total}
            linkTo={url}
        />
    );
};
