import { LoadOrdersRequestAction, LOAD_ORDERS_REQUEST, Order, LoadOrdersSuccessAction, LOAD_ORDERS_SUCCESS, LoadOrdersFailureAction, LOAD_ORDERS_FAILURE, LoadExchangeRatesRequestAction, LOAD_EXCHANGE_RATES_REQUEST, ExchangeRate, LoadExchangeRatesSuccessAction, LOAD_EXCHANGE_RATES_SUCCESS, LoadExchangeRatesFailureAction, LOAD_EXCHANGE_RATES_FAILURE } from "./types";

export function loadOrders(startDate: Date, endDate: Date): LoadOrdersRequestAction {
    return {
        type: LOAD_ORDERS_REQUEST,
        payload: {
            startDate,
            endDate
        }
    };
}

export function loadOrdersSuccess(orders: Order[]): LoadOrdersSuccessAction {
    return {
        type: LOAD_ORDERS_SUCCESS,
        payload: { orders }
    };
}

export function loadOrdersFailure(error: Error): LoadOrdersFailureAction {
    return {
        type: LOAD_ORDERS_FAILURE,
        payload: { error }
    };
}

export function loadExchangeRates(): LoadExchangeRatesRequestAction {
    return { type: LOAD_EXCHANGE_RATES_REQUEST };
}

export function loadExchangeRatesSuccess(rates: ExchangeRate[]): LoadExchangeRatesSuccessAction {
    return {
        type: LOAD_EXCHANGE_RATES_SUCCESS,
        payload: { rates: rates }
    };
}

export function loadExchangeRatesFailure(error: Error): LoadExchangeRatesFailureAction {
    return {
        type: LOAD_EXCHANGE_RATES_FAILURE,
        payload: { error }
    };
}