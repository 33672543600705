import queryString from "query-string";
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { OrdersFilters } from "../../orders";

export const useReportFilters = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const filters = useMemo(
        () => queryString.parse(location.search) as OrdersFilters,
        [location.search]
    );

    const clearFilter = useCallback(
        (filterId: string) => {
            const activeFilters: OrdersFilters = {
                ...queryString.parse(location.search),
                [filterId]: undefined,
            };
            navigate(`${location.pathname}?${queryString.stringify(activeFilters)}`);
        },
        [location.pathname, location.search, navigate]
    );

    return { filters, clearFilter };
};
