import React from "react";
import { Grid, Header, Segment, Button } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useIntl, defineMessages } from "react-intl";

const m = defineMessages({
    title: { id: "ServerErrorPage.title", defaultMessage: "500" },
    subtitle: { id: "ServerErrorPage.subtitle", defaultMessage: "Oops... There was an error in the server" },
    homeButton: { id: 'ServerErrorPage.homeButton', defaultMessage: 'Go home' },
    backButton: { id: 'ServerErrorPage.backButton', defaultMessage: 'Go back' },
});

export const ServerErrorPage: React.FC = () => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();

    function handleGoHomeClicked() {
        navigate("/");
    }

    function handleGoBackClicked() {
        navigate(-1);
    }

    return (
        <Grid
            textAlign="center"
            style={{ height: "100vh" }}
            verticalAlign="middle"
            className="not-found"
        >
            <Grid.Column style={{ maxWidth: 450 }}>
                <Segment basic>
                    <Header as="h2" textAlign="center">{formatMessage(m.title)}</Header>
                    <p>{formatMessage(m.subtitle)}</p>
                    <div>
                        <Button primary onClick={handleGoHomeClicked}>{formatMessage(m.homeButton)}</Button>
                        <Button secondary onClick={handleGoBackClicked}>{formatMessage(m.backButton)}</Button>
                    </div>
                </Segment>
            </Grid.Column>
        </Grid>
    );
};
