import React, { useState, useEffect } from 'react';
import { FluidPageLayout } from '../../../components/layouts';
import { PageHeader } from '../../../components/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { Branch } from '../../clients/types';
import { Activity } from '../types';

import * as clientApi from '../../clients/api';
import * as activityApi from '../api';
import * as LayoutActions from '../../layout/actions';
import { defineMessages, useIntl } from 'react-intl';
import { BranchActivities } from '../../clients/views/components/BranchActivities';
import { NavLink } from 'react-router-dom';
import { FloatingActionButton } from '../../../components/floatingActionButton';

import styles from './ActivitiesPage.module.css';
import { useDispatch } from 'react-redux';

const m = defineMessages({
    pageTitle: { id: 'ActivitiesPage.pageTitle', defaultMessage: 'Branch activities' },
    backLinkTitle: { id: 'ActivitiesPage.backLinkTitle', defaultMessage: 'Return to the profile' },
});

export const ActivitiesPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { branchId, clientId } = useParams<any>();
    const [branch, setBranch] = useState<Branch | undefined>();
    const [isLoadingActivities, setLoadingActivities] = useState(false);
    const [activities, setActivities] = useState<Activity[]>([]);

    useEffect(() => {
        LayoutActions.setPageTitle(formatMessage(m.pageTitle));
    }, [dispatch, formatMessage]);

    useEffect(() => {
        const id = branchId ? Number(branchId) : undefined;

        if (id != null && !isNaN(id)) {
            clientApi.getBranchById(id).then(result => setBranch(result));

            setLoadingActivities(true);
            activityApi.getActivitiesByBranch(id)
                .then(result => setActivities(result))
                .finally(() => setLoadingActivities(false));
        }
    }, [branchId]);

    const viewActivityDetails = (activity: Activity) => navigate(`/clients/${clientId}/branches/${branchId}/activities/${activity.id}`);
    const addActivity = () => navigate(`/activities/new/${clientId}/${branchId}`);

    return (
        <FluidPageLayout>
            <PageHeader
                title={branch?.client?.displayName || ''}
                subtitle={branch?.city || ''}
                backLink={<NavLink to={`/clients/${clientId}/branches/${branchId}`} style={{ fontSize: '1rem' }}>{formatMessage(m.backLinkTitle)}</NavLink>}
            />

            <BranchActivities
                clientId={Number(clientId)}
                branchId={Number(branchId)}
                activities={activities}
                loading={isLoadingActivities}
                onNavigate={viewActivityDetails}
            />

            <FloatingActionButton
                disabled={isLoadingActivities}
                className={styles.addButton}
                primary
                icon="add"
                onClick={addActivity}
            />
        </FluidPageLayout>
    );
};