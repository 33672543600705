import _ from 'lodash';
import { ValidationResult } from '../../../utils/ValidationResult';
import { defineMessages } from 'react-intl';
import { nameof } from '../../../utils/nameof';
import { Address, Coordinates } from '../types';

const m = defineMessages({
    emailRequired: { id: 'BranchEdit.emailRequired', defaultMessage: 'You must specify the branch\'s email address.' },
    phoneRequired: { id: 'BranchEdit.phoneRequired', defaultMessage: 'You must specify the branch\'s phone number.' },
    addressRequired: { id: 'BranchEdit.addressRequired', defaultMessage: 'You must specify the branch\'s address.' }
});

export class BranchEdit {
    public email: string | undefined;
    public phone: string | undefined;
    public address: Address | undefined;
    public coordinates: Coordinates | undefined;

    public constructor(init?: Partial<BranchEdit>) {
        Object.assign(this, init);
    }

    public validate(): ValidationResult {
        const result = new ValidationResult();

        if (_.isEmpty(this.email?.trim())) {
            result.isValid = false;
            result.errorFields.push(nameof<BranchEdit>('email'));
            result.errorMessages.push(m.emailRequired);
        }

        if (_.isEmpty(this.phone?.trim())) {
            result.isValid = false;
            result.errorFields.push(nameof<BranchEdit>('phone'));
            result.errorMessages.push(m.phoneRequired);
        }

        if (this.address == null) {
            result.isValid = false;
            result.errorFields.push(nameof<BranchEdit>('address'));
            result.errorMessages.push(m.addressRequired);
        }

        return result;
    }
}