import  React, { useState } from 'react';
import { Dimmer, Container } from 'semantic-ui-react';
import { TopBar } from '../TopBar';
import { SidebarMenu } from '../SidebarMenu';
import styles from './SinglePageLayout.module.css';

interface SinglePageLayoutProps {
    children?: React.ReactNode;
  }

// This provides a 'single page layout', i.e. a fixed non scrollable page. To scroll the content of the page,
// you can use the `overflow-y: auto` attribute.
export const SinglePageLayout: React.FC<SinglePageLayoutProps> = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <>
            <SidebarMenu visible={isMenuOpen} onHide={() => setIsMenuOpen(false)} />
            <Dimmer.Dimmable blurring={isMenuOpen} dimmed={isMenuOpen}>
                <Dimmer inverted onClickOutside={() => setIsMenuOpen(false)} style={{ zIndex: 700 }} />
                <TopBar onToggleMenu={() => setIsMenuOpen(!isMenuOpen)} />

                <Container className={styles.fixedContainer}>
                    {props.children}
                </Container>
            </Dimmer.Dimmable>
        </>
    );
};

export default SinglePageLayout;