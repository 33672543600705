import React from 'react';
import { List } from 'semantic-ui-react';
import { useIntl, defineMessages } from 'react-intl';
import { Set } from '../../types';

export interface SetListItemProps {
    set: Set;
    selected?: boolean;
    onClick?: (set: Set) => void;
}

const m = defineMessages({
    productCodeFormat: { id: 'SetListItem.productCodeFormat', defaultMessage: '#{code}' }
});

export const SetListItem: React.FC<SetListItemProps> = (props) => {
    const { formatMessage } = useIntl();
    const handleClick = () => {
        if (props.onClick) {
            props.onClick(props.set);
        }
    };

    return (
        <List.Item onClick={handleClick}>
            {props.selected && <List.Icon name="check" color="green" />}
            {!props.selected && <List.Icon name="box" />}
            <List.Content>
                <List.Header>{props.set.name}</List.Header>
                <List.Description>{formatMessage(m.productCodeFormat, { code: props.set.setCode })}</List.Description>
            </List.Content>
        </List.Item>
    );
};