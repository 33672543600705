import React, { useEffect, useState } from 'react';
import { FluidPageLayout } from '../../../components/layouts';
import { useNavigate, useParams } from 'react-router-dom';
import * as api from '../api';
import { Activity } from '../types';
import { PageHeader } from '../../../components/PageHeader';
import { Segment, Feed, Divider } from 'semantic-ui-react';
import { ActivityFeedEvent } from './components/ActivityFeedEvent';
import * as LayoutActions from '../../layout/actions';
import { useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { LoadingActivityFeedEvent } from './components/LoadingActivityFeedEvent';
import { NavLink } from 'react-router-dom';
import { FloatingActionButton } from '../../../components/floatingActionButton';
import styles from './ActivityDetailsPage.module.css';
import { MediaGallery, MediaFile } from './components/MediaGallery';

const m = defineMessages({
    pageTitle: { id: 'ActivityDetailsPage.pageTitle', defaultMessage: 'Activity details' },
    backLinkTitle: { id: 'ActivityDetailsPage.backLinkTitle', defaultMessage: 'Return to the profile' }
});

export const ActivityDetailsPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { clientId, branchId, id } = useParams();
    const [loading, setLoading] = useState(false);
    const [activity, setActivity] = useState<Activity | undefined>(undefined);

    useEffect(() => {
        dispatch(LayoutActions.setPageTitle(formatMessage(m.pageTitle)));
    }, [dispatch, formatMessage]);

    useEffect(() => {
        const activityId = id != null ? Number(id) : undefined;
        if (id != null) {
            setLoading(true);

            api.getActivityById(Number(activityId))
                .then(result => setActivity(result))
                .finally(() => setLoading(false));
        }
    }, [id]);

    const redirectToEdit = () => {
        if (activity != null) {
            navigate(`/clients/${activity.branch?.clientId}/branches/${activity.branchId}/activities/${activity.id}/edit`);
        }
    };

    const attachments = activity?.attachmentIds.map((x): MediaFile => ({
        id: x,
        src: `/api/attachments/${x}`
    })) || [];

    return (
        <FluidPageLayout>
            <PageHeader
                title={activity?.branch?.client?.displayName || ''}
                subtitle={activity?.branch?.city || ''}
                backLink={<NavLink to={`/clients/${clientId}/branches/${branchId}`} style={{ fontSize: '1rem' }}>{formatMessage(m.backLinkTitle)}</NavLink>}
            />
            <Segment style={{ marginBottom: '2.5rem' }}>
                <Feed size="small">
                    {loading && <LoadingActivityFeedEvent />}
                    {!loading && activity && <ActivityFeedEvent activity={activity} multiline />}
                </Feed>

                {attachments.length > 0 &&
                    <>
                        <Divider />
                        <MediaGallery size="tiny" files={attachments} />
                    </>
                }
            </Segment>

            <FloatingActionButton
                className={styles.editButton}
                primary
                disabled={loading}
                icon="pencil"
                onClick={redirectToEdit}
            />
        </FluidPageLayout>
    );
};