import React, { useEffect, useState } from "react";
import { OrdersFilters } from "../../../orders";
import { defineMessages, useIntl } from "react-intl";
import { DropdownItemProps, DropdownProps } from "semantic-ui-react";
import { FilterSection } from "../../../../components/FilterSection";
import { nameof } from "../../../../utils/nameof";
import { ClientsDropdown } from "../../../clients/views/components/ClientsDropdown";
import { CountryFilterDropdown } from "../../../goals/views/components/CountryFilterDropdown";
import { ProvinceFilterDropdown } from "../../../goals/views/components/ProvinceFilterDropdown";
import { CurrencyFilterDropdown } from "../CurrencyFilterDropdown";
import upperCase from "lodash/upperCase";
import { useReportFilters } from "../../hooks/useReportFilters";

const m = defineMessages({
    accountsSectionTitle: {
        id: "ReportFiltersForm.accountsSectionTitle",
        defaultMessage: "Filter by account",
    },
    regionsSectionTitle: {
        id: "ReportFiltersForm.regionSectionTitle",
        defaultMessage: "Filter by region",
    },
    currenciesSectionTitle: {
        id: "ReportFiltersForm.currenciesSectionTitle",
        defaultMessage: "Filter by currency",
    },
    allClients: { id: "ReportFiltersForm.allClients", defaultMessage: "All clients" },
    allRepresentatives: {
        id: "ReportFiltersForm.allRepresentatives",
        defaultMessage: "All representatives",
    },
    allCurrencies: {
        id: "ReportFiltersForm.allCurrencies",
        defaultMessage: "All currencies (converted in CAD)",
    },
});

const ALL_VALUE = -1;

interface FiltersFormProps {
    onChange: (newFilters: OrdersFilters) => void;
}

export const FiltersForm: React.FC<FiltersFormProps> = ({ onChange }) => {
    const { formatMessage } = useIntl();

    const { filters } = useReportFilters();

    const [formFilters, setFormFilters] = useState<OrdersFilters>({});

    useEffect(() => {
        setFormFilters(filters);
    }, [filters]);

    useEffect(() => onChange(formFilters), [formFilters, onChange]);

    const allClientsOption: DropdownItemProps = {
        text: formatMessage(m.allClients),
        value: ALL_VALUE,
    };

    const allCurrenciesInCADOption: DropdownItemProps = {
        text: formatMessage(m.allCurrencies),
        value: ALL_VALUE,
    };

    const resetAccounts = () =>
        setFormFilters({ ...formFilters, clientId: undefined, representativeId: undefined });

    const resetCountries = () =>
        setFormFilters({ ...formFilters, countryCode: undefined, provinceCode: undefined });

    const resetCurrencies = () => setFormFilters({ ...formFilters, currency: undefined });

    const updateFilter = (fieldId: string, value: string | number | boolean | (string | number | boolean)[] | undefined) => {
        setFormFilters({
            ...formFilters,
            [fieldId]: value != ALL_VALUE ? value : undefined,
        });
    };

    const updateCountryFilter = (
        _event: React.SyntheticEvent<HTMLElement>,
        data: DropdownProps
    ) => {
        const countryCode = data.value != ALL_VALUE ? data.value : undefined;
        setFormFilters({
            ...formFilters,
            countryCode: countryCode?.toString(),
            provinceCode: undefined,
        });
    };

    return (
        <>
            <FilterSection
                title={formatMessage(m.accountsSectionTitle)}
                onClear={resetAccounts}
                style={{ marginBottom: "1rem" }}
            >
                <ClientsDropdown
                    id={nameof<OrdersFilters>("clientId")}
                    fluid
                    selection
                    search
                    defaultOption={allClientsOption}
                    style={{ marginBottom: ".5rem" }}
                    value={formFilters.clientId != null ? Number(formFilters.clientId) : ALL_VALUE}
                    onChange={(_, { value }) => updateFilter(nameof<OrdersFilters>("clientId"), value)}
                />
            </FilterSection>

            <FilterSection
                title={formatMessage(m.regionsSectionTitle)}
                onClear={resetCountries}
                style={{ marginBottom: "1rem" }}
            >
                <CountryFilterDropdown
                    id={nameof<OrdersFilters>("countryCode")}
                    fluid
                    selection
                    value={formFilters.countryCode || ALL_VALUE}
                    onChange={updateCountryFilter}
                />

                <ProvinceFilterDropdown
                    id={nameof<OrdersFilters>("provinceCode")}
                    countryCode={formFilters.countryCode}
                    fluid
                    selection
                    value={formFilters.provinceCode || ALL_VALUE}
                    onChange={(_, { value }) => updateFilter(nameof<OrdersFilters>("provinceCode"), value)}
                />
            </FilterSection>

            <FilterSection
                title={formatMessage(m.currenciesSectionTitle)}
                onClear={resetCurrencies}
                style={{ marginBottom: "1rem" }}
            >
                <CurrencyFilterDropdown
                    id={nameof<OrdersFilters>("currency")}
                    fluid
                    selection
                    defaultOption={allCurrenciesInCADOption}
                    value={
                        formFilters.currency != null ? upperCase(formFilters.currency) : ALL_VALUE
                    }
                    onChange={(_, { value }) => updateFilter(nameof<OrdersFilters>("currency"), value)}
                />
            </FilterSection>
        </>
    );
};
