import React from 'react';
import _ from 'lodash';
import { Dropdown, DropdownProps, DropdownItemProps } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { getAllRepresentatives, areRepresentativesLoading, getRepresentativeDisplayName } from '../../selectors';

interface OwnProps {
    defaultOption?: DropdownItemProps;
}

export type RepresentativesDropdownProps =
    & OwnProps
    & DropdownProps;

export const RepresentativesDropdown: React.FC<RepresentativesDropdownProps> = (props) => {
    const { defaultOption, ...dropdownProps } = props;

    const representatives = useSelector(getAllRepresentatives);
    const loading = useSelector(areRepresentativesLoading);
    const representativesOptions: DropdownItemProps[] = _(representatives)
        .map(x => ({
            text: getRepresentativeDisplayName(x),
            value: x.id,
        }))
        .sortBy(x => x.text)
        .value();

    return (
        <Dropdown
            loading={loading}
            options={defaultOption != null ? [defaultOption, ...representativesOptions] : representativesOptions}
            {...dropdownProps}
        />
    );
};