import { call, put, all, takeLatest, fork, takeEvery } from 'redux-saga/effects';
import { LOAD_ORDERS_REQUEST, LoadOrdersRequestAction, LOAD_EXCHANGE_RATES_REQUEST, ExchangeRate, Order } from "./types";
import * as actions from './actions';
import * as api from './api';

function* watchLoadOrders() { yield takeEvery(LOAD_ORDERS_REQUEST, handleLoadOrders); }
function* handleLoadOrders(action: LoadOrdersRequestAction) {
    try {
        const orders: Order[] = yield call(api.loadOrdersBetween, action.payload.startDate, action.payload.endDate);
        yield put(actions.loadOrdersSuccess(orders));

    } catch (e) {
        yield put(actions.loadOrdersFailure(e as Error));
    }
}

function* watchLoadExchangeRates() { yield takeLatest(LOAD_EXCHANGE_RATES_REQUEST, handleLoadExchangeRates); }
function* handleLoadExchangeRates() {
    try {
        const rates: ExchangeRate[] = yield call(api.loadBankOfCanadaExchangeRate);
        yield put(actions.loadExchangeRatesSuccess(rates));
    } catch (e) {
        yield put(actions.loadExchangeRatesFailure(e as Error));
    }
}

function* ordersSagas() {
    yield all([
        fork(watchLoadOrders),
        fork(watchLoadExchangeRates),
    ]);
}

export default ordersSagas;