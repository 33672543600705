import { Reducer } from 'redux';
import { LayoutActionTypes, LayoutState, SET_LOCALE, SET_PAGE_TITLE } from './types';

const initialState: LayoutState = {
    currentLocale: 'fr',
    currentPageTitle: '',
};

export const layoutReducer: Reducer<LayoutState, LayoutActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOCALE: {
            return {
                ...state,
                currentLocale: action.payload.locale
            };
        }

        case SET_PAGE_TITLE: {
            return {
                ...state,
                currentPageTitle: action.payload.title,
            };
        }

        default:
            return state;
    }
};