import React from "react";
import { ReactComponent as MSLogo } from "./MicrosoftLogo.svg";
import { defineMessages, useIntl } from "react-intl";

import "./MicrosoftLoginButton.css";

const m = defineMessages({
    text: {
        id: "MicrosoftLoginButton.title",
        defaultMessage: "Sign in with Microsoft",
    },
});

interface MicrosoftLoginButtonProps {
    isLoggingIn: boolean;
}

export const MicrosoftLoginButton: React.FC<MicrosoftLoginButtonProps> = ({ isLoggingIn }) => {
    const { formatMessage } = useIntl();

    return (
        <button type="submit" className="ms-button" disabled={isLoggingIn}>
            <MSLogo /> {formatMessage(m.text)}
        </button>
    );
};
