import { useQuery } from "@tanstack/react-query";
import { OrdersFilters } from "../../orders";
import { getSalesStat, GetSalesStatFilters } from "../api";
import { getFilterPickCurrency } from "../utils";

export const useReportSales = (startDate: Date, endDate: Date, filters: OrdersFilters) => {
    const queryFilters: GetSalesStatFilters = {
        clientId: filters.clientId,
        countryCode: filters.countryCode,
        provinceCode: filters.provinceCode,
        currency: getFilterPickCurrency(filters.currency),
    };

    const { data: salesStat, isFetching: isLoading } = useQuery(
        [
            "salesstat",
            queryFilters.clientId,
            queryFilters.countryCode,
            queryFilters.provinceCode,
            queryFilters.currency,
        ],
        async () => getSalesStat(startDate, endDate, queryFilters),
        {
            staleTime: 15 * 60 * 1000,
        }
    );

    return { salesStat, isLoading };
};
