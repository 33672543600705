import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { FluidPageLayout } from '../../../components/layouts';
import { FloatingActionButtons, FloatingActionButton } from '../../../components/floatingActionButton';
import { useNavigate, useLocation } from 'react-router-dom';
import { FilterSection } from '../../../components/FilterSection/FilterSection';
import { DropdownItemProps, Dropdown, DropdownProps } from 'semantic-ui-react';
import dayjs from 'dayjs';
import { defineMessages, useIntl } from 'react-intl';
import { ClientsDropdown } from '../../clients/views/components/ClientsDropdown';
import { GoalsFilters } from '../types';
import queryString from 'query-string';
import { nameof } from '../../../utils/nameof';
import { RepresentativesDropdown } from '../../representatives/views/components/RepresentativesDropdown';
import { CountryFilterDropdown } from './components/CountryFilterDropdown';
import { ProvinceFilterDropdown } from './components/ProvinceFilterDropdown';

const m = defineMessages({
    datesSectionTitle: { id: 'GoalsFiltersPage.datesSectionTitle', defaultMessage: 'Filter by date' },
    accountsSectionTitle: { id: 'GoalsFiltersPage.accountsSectionTitle', defaultMessage: 'Filter by account' },
    regionsSectionTitle: { id: 'GoalsFiltersPage.regionSectionTitle', defaultMessage: 'Filter by region' },
    currenciesSectionTitle: { id: 'GoalsFiltersPage.currenciesSectionTitle', defaultMessage: 'Filter by currency' },
    currentYear: { id: 'GoalsFiltersPage.currentYear', defaultMessage: 'Current year' },
    allClients: { id: 'GoalsFiltersPage.allClients', defaultMessage: 'All clients' },
    allRepresentatives: { id: 'GoalsFiltersPage.allRepresentatives', defaultMessage: 'All representatives' },
    allCurrencies: { id: 'GoalsFiltersPage.allCurrencies', defaultMessage: 'All currencies (converted in CAD)' },
    cadCurrency: { id: 'GoalsFiltersPage.cadCurrency', defaultMessage: 'CAD only' },
    usdCurrency: { id: 'GoalsFiltersPage.usdCurrency', defaultMessage: 'USD only' },
});

const ALL_VALUE = -1;

export const GoalsFiltersPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const [filters, setFilters] = useState<GoalsFilters>({});

    useEffect(() => {
        const queryFilters = queryString.parse(location.search) as GoalsFilters;
        setFilters(queryFilters);
    }, [location]);

    const defaultDateOption: DropdownItemProps = { text: formatMessage(m.currentYear), value: ALL_VALUE };
    const dateOptions: DropdownItemProps[] = _.range(0, dayjs().month() + 1).map(x => ({
        text: _.capitalize(dayjs(new Date(2020, x, 1)).format('MMMM')),
        value: x
    }));

    const currenciesOptions: DropdownItemProps[] = [
        { text: formatMessage(m.allCurrencies), value: ALL_VALUE },
        { text: formatMessage(m.cadCurrency), value: 'CAD' },
        { text: formatMessage(m.usdCurrency), value: 'USD' },
    ];

    const allClientsOption: DropdownItemProps = { text: formatMessage(m.allClients), value: ALL_VALUE };
    const allRepresentativesOption: DropdownItemProps = { text: formatMessage(m.allRepresentatives), value: ALL_VALUE };

    const resetDates = () => setFilters({ ...filters, month: undefined });
    const resetAccounts = () => setFilters({ ...filters, clientId: undefined, representativeId: undefined });
    const resetCountries = () => setFilters({ ...filters, countryCode: undefined, provinceCode: undefined });
    const resetCurrencies = () => setFilters({ ...filters, currency: undefined });

    const updateFilter = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const fieldId = event.currentTarget.parentElement?.parentElement?.id || undefined;
        if (fieldId != null) {
            setFilters({
                ...filters,
                [fieldId]: data.value != ALL_VALUE ? data.value : undefined
            });
        }
    };

    const updateCountryFilter = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const countryCode = data.value != ALL_VALUE ? data.value : undefined;
        setFilters({
            ...filters,
            countryCode: countryCode?.toString(),
            provinceCode: undefined,
        });
    };

    const cancel = () => navigate(-1);
    const applyFilters = () => {
        const queryFilters = queryString.stringify(filters);
        navigate(`/goals?${queryFilters}`);
    };

    return (
        <FluidPageLayout>
            <div style={{ marginTop: '.25rem' }}>
                <FilterSection
                    title={formatMessage(m.datesSectionTitle)}
                    onClear={resetDates}
                    style={{ marginBottom: '1rem' }}
                >
                    <Dropdown
                        id={nameof<GoalsFilters>('month')}
                        fluid
                        selection
                        options={[defaultDateOption, ...dateOptions]}
                        value={filters.month != null ? Number(filters.month) : ALL_VALUE}
                        onChange={updateFilter}
                    />
                </FilterSection>

                <FilterSection
                    title={formatMessage(m.accountsSectionTitle)}
                    onClear={resetAccounts}
                    style={{ marginBottom: '1rem' }}
                >
                    <RepresentativesDropdown
                        id={nameof<GoalsFilters>('representativeId')}
                        fluid
                        selection
                        search
                        defaultOption={allRepresentativesOption}
                        style={{ marginBottom: '.5rem' }}
                        value={filters.representativeId != null ? filters.representativeId : ALL_VALUE}
                        onChange={updateFilter}
                    />

                    <ClientsDropdown
                        id={nameof<GoalsFilters>('clientId')}
                        fluid
                        selection
                        search
                        defaultOption={allClientsOption}
                        style={{ marginBottom: '.5rem' }}
                        value={filters.clientId != null ? Number(filters.clientId) : ALL_VALUE}
                        onChange={updateFilter}
                    />
                </FilterSection>

                <FilterSection
                    title={formatMessage(m.regionsSectionTitle)}
                    onClear={resetCountries}
                    style={{ marginBottom: '1rem' }}
                >
                    <CountryFilterDropdown
                        id={nameof<GoalsFilters>('countryCode')}
                        fluid
                        selection
                        value={filters.countryCode || ALL_VALUE}
                        onChange={updateCountryFilter}
                    />

                    <ProvinceFilterDropdown
                        id={nameof<GoalsFilters>('provinceCode')}
                        countryCode={filters.countryCode}
                        fluid
                        selection
                        value={filters.provinceCode || ALL_VALUE}
                        onChange={updateFilter}
                    />

                </FilterSection>

                <FilterSection
                    title={formatMessage(m.currenciesSectionTitle)}
                    onClear={resetCurrencies}
                    style={{ marginBottom: '1rem' }}
                >
                    <Dropdown
                        id={nameof<GoalsFilters>('currency')}
                        fluid
                        selection
                        options={currenciesOptions}
                        value={filters.currency != null ? _.upperCase(filters.currency) : ALL_VALUE}
                        onChange={updateFilter}
                    />
                </FilterSection>
            </div>

            <FloatingActionButtons>
                <FloatingActionButton
                    icon="cancel"
                    onClick={cancel}
                />
                <FloatingActionButton
                    primary
                    icon="check"
                    onClick={applyFilters}
                />
            </FloatingActionButtons>
        </FluidPageLayout>
    );
};