import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Table, TableCell } from "semantic-ui-react";
import { MonthlyStats } from "../../types";
import { RatioCell } from "../RatioCell";
import { AmountCell } from "../AmountCell";

const m = defineMessages({
    previous: { id: "OrdersReportTable.ClientRows.Previous", defaultMessage: "Previous" },
    current: { id: "OrdersReportTable.ClientRows.Current", defaultMessage: "Current" },
});

interface GroupMonthlyStatsRowsProps {
    groupName: string;
    monthlyStats: MonthlyStats;
    reportMonthsInOrder: number[];
}

export const GroupMonthlyStatsRows: React.FC<GroupMonthlyStatsRowsProps> = ({
    groupName,
    monthlyStats,
    reportMonthsInOrder,
}) => {
    const { formatMessage } = useIntl();

    const currentPeriodRow = (
        <Table.Row>
            <TableCell rowSpan={2} style={{ verticalAlign: "baseline" }}>
                <div style={{ paddingLeft: "2.5rem" }}>{groupName}</div>
            </TableCell>
            <TableCell></TableCell>
            <TableCell>{formatMessage(m.current)}</TableCell>
            {reportMonthsInOrder.map((month) => (
                <AmountCell
                    key={month}
                    amount={monthlyStats.statsByMonth[month]?.currentPeriodTotal}
                />
            ))}
            <AmountCell style={{fontWeight: 'bold'}} amount={monthlyStats.currentPeriodTotal} />
        </Table.Row>
    );

    const previousPeriodRow = (
        <Table.Row style={{ borderTop: "none" }}>
            <TableCell style={{ borderTop: "none" }}></TableCell>
            <TableCell style={{ borderTop: "none" }}>{formatMessage(m.previous)}</TableCell>
            {reportMonthsInOrder.map((month) => (
                <AmountCell
                    key={month}
                    style={{ borderTop: "none" }}
                    amount={monthlyStats.statsByMonth[month]?.previousPeriodTotal}
                />
            ))}
            <AmountCell style={{ fontWeight: 'bold', borderTop: "none" }} amount={monthlyStats.previousPeriodTotal} />
        </Table.Row>
    );

    const ratiosRow = (
        <Table.Row style={{ borderTop: "none" }}>
            <TableCell style={{ borderTop: "none" }}></TableCell>
            <TableCell style={{ borderTop: "none" }}></TableCell>
            <TableCell style={{ borderTop: "none" }}></TableCell>
            {reportMonthsInOrder.map((month) => (
                <RatioCell
                    key={month}
                    style={{ borderTop: "none" }}
                    ratio={monthlyStats.statsByMonth[month]?.ratio ?? 0}
                    previousAmount={monthlyStats.statsByMonth[month]?.previousPeriodTotal}
                    currentAmount={monthlyStats.statsByMonth[month]?.currentPeriodTotal}
                />
            ))}
            <RatioCell
                style={{ borderTop: "none" }}
                ratio={monthlyStats.ratio}
                previousAmount={monthlyStats.previousPeriodTotal}
                currentAmount={monthlyStats.currentPeriodTotal}
            />
        </Table.Row>
    );

    return (
        <>
            {currentPeriodRow}
            {previousPeriodRow}
            {ratiosRow}
        </>
    );
};
