export const formatPhoneNumber = (phoneNumber: string | undefined): string => {
    const actualNumber = phoneNumber ? phoneNumber.trim() : phoneNumber;

    if (actualNumber != null) {
        if (actualNumber.length === 10) {
            return formatTenDigitPhoneNumber(actualNumber);
        }

        if (actualNumber.length === 11) {
            const internationalCode = actualNumber[0];

            if (internationalCode === '1') {
                return formatTenDigitPhoneNumber(actualNumber.slice(1, 11));
            }

            return actualNumber[0] + ' ' + formatTenDigitPhoneNumber(actualNumber.slice(1, 11));
        }
    }

    return actualNumber?.toString() || '';
};

function formatTenDigitPhoneNumber(phoneNumber: string) {
    return phoneNumber.length === 10
        ? '(' + phoneNumber.slice(0, 3) + ') ' + phoneNumber.slice(3, 6) + '-' + phoneNumber.slice(6, 10)
        : phoneNumber;
}