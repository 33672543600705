import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as LayoutActions from "../../layout/actions";
import { Invoice } from "../../orders";
import { useReportDates } from "../hooks/useReportDates";
import queryString from "query-string";
import { FluidPageLayout } from "../../../components/layouts";
import { Header, Loader } from "semantic-ui-react";
import { PageHeader } from "../../../components/PageHeader";
import { BranchInfo } from "../components/BranchInfo";
import { InvoicesTable } from "../components/InvoicesTable/InvoicesTable";
import { Branch } from "../../clients/types";
import { getBranchByClientCode, getBranchInvoicesByClientCode } from "../api";
import { useDefaultErrorHandler } from "../../../utils/api";

const m = defineMessages({
    title: { id: "ClientInvoicesPage.title", defaultMessage: "Client invoices" },
    tableTitle: {
        id: "ClientInvoicesPage.TableTitle",
        defaultMessage: "Invoices from {start} to {end}",
    },
});

interface ClientInvoicesReportFilters {
    start?: string;
    end?: string;
}

export const ClientInvoicesReportPage = () => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();

    useEffect(() => {
        dispatch(LayoutActions.setPageTitle(formatMessage(m.title)));
    }, [dispatch, formatMessage]);

    const navigate = useNavigate();
    const location = useLocation();
    const defaultErrorHandler = useDefaultErrorHandler();

    const { currentPeriodEndDate, previousPeriodStartDate } = useReportDates();

    const { clientCode } = useParams();

    const filters = queryString.parse(location.search) as ClientInvoicesReportFilters;

    const invoicesStartDate = useMemo(
        () => dayjs.utc(filters.start).toDate() || previousPeriodStartDate,
        [filters.start, previousPeriodStartDate]
    );

    const invoicesEndDate = useMemo(
        () => dayjs.utc(filters.end).toDate() || currentPeriodEndDate,
        [currentPeriodEndDate, filters.end]
    );

    const [branch, setBranch] = useState<Branch>();
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [isLoadingBranch, setIsLoadingBranch] = useState(false);
    const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
    const isLoading = isLoadingBranch || isLoadingInvoices;

    useEffect(() => {
        if (!clientCode) return;

        setIsLoadingBranch(true);
        setIsLoadingInvoices(true);

        getBranchByClientCode(clientCode)
            .then((branch) => {
                setBranch(branch);
            })
            .catch(defaultErrorHandler)
            .finally(() => setIsLoadingBranch(false));

        getBranchInvoicesByClientCode(clientCode, invoicesStartDate, invoicesEndDate)
            .then((invoices) => {
                setInvoices(invoices);
            })
            .catch(defaultErrorHandler)
            .finally(() => setIsLoadingInvoices(false));
    }, [clientCode, invoicesEndDate, invoicesStartDate, navigate]);

    return (
        <FluidPageLayout>
            {isLoading && (
                <div style={{ display: "flex", justifyContent: "center", padding: "4rem 0" }}>
                    <Loader active inline />
                </div>
            )}
            {!isLoading && branch && (
                <>
                    <PageHeader
                        title={branch.clientCode}
                        subtitle={`${branch?.alternateName || ""} - ${branch?.city || ""}`}
                    />
                    <BranchInfo branch={branch} />
                    <Header>
                        {formatMessage(m.tableTitle, {
                            start: dayjs.utc(invoicesStartDate).format("LL"),
                            end: dayjs.utc(invoicesEndDate).format("LL"),
                        })}
                    </Header>
                    <InvoicesTable invoices={invoices} />
                </>
            )}
        </FluidPageLayout>
    );
};
