import { START_APPLICATION } from "./types";
import { takeEvery, all, fork, put, select } from 'redux-saga/effects';
import dayjs from 'dayjs';
import * as GoalsActions from '../goals/actions';
import * as OrdersActions from "../orders/actions";
import * as ClientsActions from '../clients/actions';
import * as RepresentativesActions from '../representatives/actions';
import { getCurrentUser } from "../account/selectors";
import { User } from "../account/types";

function* watchStartApplication() { yield takeEvery(START_APPLICATION, handleStartApplication); }
function* handleStartApplication() {
    yield put(OrdersActions.loadExchangeRates());
    yield put(ClientsActions.loadClients());
    yield put(OrdersActions.loadOrders(
        dayjs.utc().add(-1, 'year').startOf('year').toDate(),
        dayjs.utc().endOf('year').toDate()));

    const currentUser = (yield select(getCurrentUser)) as User;
    if (currentUser?.isAdmin) {
        yield put(RepresentativesActions.loadRepresentatives());
        yield put(GoalsActions.loadGoals());
    }
}

function* applicationSagas() {
    yield all([
        fork(watchStartApplication)
    ]);
}

export default applicationSagas;