import React from 'react';
import _ from 'lodash';
import { List } from 'semantic-ui-react';
import { Set } from '../../../clients/types';
import { SetListItem } from './SetListItem';
import { LoadingSetPlaceholder } from './LoadingSetPlaceholder';

export interface SetListProps {
    sets: Set[];
    selectedSets?: Set[];
    loading?: boolean;
    disabled?: boolean;

    onClick: (set: Set) => void;
}

export const SetList: React.FC<SetListProps> = (props) => {
    const selectedSets = props.selectedSets || [];
    const handleClick = (set: Set) => {
        if (!props.disabled) {
            props.onClick(set);
        }
    };

    return (
        <>
            {!props.loading &&
                <List relaxed selection verticalAlign="middle">
                    {_(props.sets)
                        .orderBy(x => x.setCode)
                        .map(x => {
                            const isSelected = selectedSets.find(result => result.id === x.id) != null;
                            return <SetListItem key={x.id} set={x} selected={isSelected} onClick={() => handleClick(x)} />;
                        })
                        .value()
                    }
                </List>
            }

            {props.loading &&
                <>
                    <LoadingSetPlaceholder />
                    <LoadingSetPlaceholder />
                    <LoadingSetPlaceholder />
                    <LoadingSetPlaceholder />
                </>
            }
        </>
    );
};