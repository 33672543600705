import React, { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SalesReportLayout } from "./SalesReportLayout";
import * as LayoutActions from "../../layout/actions";
import { useReportDates } from "../hooks/useReportDates";
import { ReportActiveFilters } from "../components/ReportActiveFilters";
import { useReportFilters } from "../hooks/useReportFilters";
import {
    FloatingActionButton,
    FloatingActionButtons,
} from "../../../components/floatingActionButton";
import { useReportSales } from "../hooks/useReportSales";
import { useSalesReportData } from "../hooks/useSalesReportData";
import { SalesReportTable } from "../components/SalesReportTable";
import { useSalesReportPdf } from "../hooks/useSalesReportPdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const m = defineMessages({
    title: { id: "SalesReportPage.title", defaultMessage: "Sales by client report" },
});

export const SalesReportPage = () => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(LayoutActions.setPageTitle(formatMessage(m.title)));
    }, [dispatch, formatMessage]);

    const { filters, clearFilter } = useReportFilters();

    const { reportMonthsInOrder, currentPeriodEndDate, previousPeriodStartDate } = useReportDates();

    const { salesStat, isLoading: isLoadingSalesStat } = useReportSales(
        previousPeriodStartDate,
        currentPeriodEndDate,
        filters
    );

    const { salesReportData, isProcessing } = useSalesReportData(
        salesStat,
        filters,
        reportMonthsInOrder
    );

    const isLoading = isLoadingSalesStat || isProcessing;

    const { getDocumentDefinition } = useSalesReportPdf(salesReportData);

    const generatePdf = () => {
        const documentDefinition = getDocumentDefinition();
        pdfMake.createPdf(documentDefinition).download(`${formatMessage(m.title)}.pdf`);
    };

    return (
        <SalesReportLayout>
            <ReportActiveFilters onClear={clearFilter} />
            <SalesReportTable salesReportData={salesReportData} isLoading={isLoading} />
            <FloatingActionButtons>
                <FloatingActionButton icon="file pdf" onClick={generatePdf} />
                <FloatingActionButton
                    icon="filter"
                    onClick={() => navigate(`/reports/sales/filters${location.search}`)}
                />
            </FloatingActionButtons>
        </SalesReportLayout>
    );
};
