import { BranchEdit, ClientEdit } from "./models";
import {
    LoadClientsRequestAction,
    LOAD_CLIENTS_REQUEST,
    LOAD_CLIENTS_SUCCESS,
    LoadClientsFailureAction,
    Client,
    LoadClientsSuccessAction,
    LOAD_CLIENTS_FAILURE,
    CreateClientRequestAction,
    CREATE_CLIENT_REQUEST,
    CREATE_CLIENT_SUCCESS,
    CreateClientSuccessAction,
    CreateClientFailureAction,
    CREATE_CLIENT_FAILURE,
    CreateBranchRequestAction,
    CREATE_BRANCH_REQUEST,
    CreateBranchSuccessAction,
    CREATE_BRANCH_SUCCESS,
    Branch,
    CREATE_BRANCH_FAILURE,
    CreateBranchFailureAction,
} from "./types";

export function loadClients(): LoadClientsRequestAction {
    return {
        type: LOAD_CLIENTS_REQUEST,
        payload: {}
    };
}

export function loadClientsSuccess(clientList: Client[]): LoadClientsSuccessAction {
    return {
        type: LOAD_CLIENTS_SUCCESS,
        payload: clientList
    };
}

export function loadClientsFailure(error: Error): LoadClientsFailureAction {
    return {
        type: LOAD_CLIENTS_FAILURE,
        payload: { error }
    };
}

export function createClient(client: ClientEdit): CreateClientRequestAction {
    return {
        type: CREATE_CLIENT_REQUEST,
        payload: { client }
    };
}

export function createClientSuccess(client: Client): CreateClientSuccessAction {
    return {
        type: CREATE_CLIENT_SUCCESS,
        payload: { client }
    };
}

export function createClientFailure(error: Error): CreateClientFailureAction {
    return {
        type: CREATE_CLIENT_FAILURE,
        payload: { error }
    };
}

export function createBranch(clientId: number, branch: BranchEdit): CreateBranchRequestAction {
    return {
        type: CREATE_BRANCH_REQUEST,
        payload: { clientId, branch }
    };
}

export function createBranchSuccess(branch: Branch): CreateBranchSuccessAction {
    return {
        type: CREATE_BRANCH_SUCCESS,
        payload: { branch }
    };
}

export function createBranchFailure(error: Error): CreateBranchFailureAction {
    return {
        type: CREATE_BRANCH_FAILURE,
        payload: { error }
    };
}