import  React, { useState } from 'react';
import { Dimmer, Container } from 'semantic-ui-react';
import { TopBar } from '../TopBar';
import { SidebarMenu } from '../SidebarMenu';
import styles from './FluidPageLayout.module.css';

interface FluidPageLayoutProps {
    children?: React.ReactNode;
  }

// This provides a 'fluid page layout', i.e. a a scrollable page.
export const FluidPageLayout: React.FC<FluidPageLayoutProps> = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <>
            <SidebarMenu visible={isMenuOpen} onHide={() => setIsMenuOpen(false)} />
            <Dimmer.Dimmable blurring={isMenuOpen} dimmed={isMenuOpen}>
                <Dimmer inverted onClickOutside={() => setIsMenuOpen(false)} style={{ zIndex: 700 }} />
                <TopBar onToggleMenu={() => setIsMenuOpen(!isMenuOpen)} />

                <Container className={styles.container}>
                    {props.children}
                </Container>
            </Dimmer.Dimmable>
        </>
    );
};

export default FluidPageLayout;