const siScale = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
export const formatMoney = (amount: number, hidePrefix?: boolean, precision?: number) => {
    // N.B.: we can't use Intl.NumberFormat with the 'compact' notation, because it is not yet
    // supported on Firefox 77 and Safari 13.1. As such, we must do the formatting ourselves.
    const siIndex = Math.log10(amount) / 3 | 0;
    if (siIndex === 0) {
        return hidePrefix
            ? `${amount}`
            : `$${amount}`;
    }

    const suffix = siScale[siIndex];
    const scale = Math.pow(10, siIndex * 3);
    const scaled = (amount / scale);

    return hidePrefix
        ? `${scaled.toFixed(precision != null ? 0 : 1)}${suffix}`
        : `$${scaled.toFixed(precision != null ? 0 : 1)}${suffix}`;
};