import type { Attributes } from 'react';
import React from 'react';

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  onCenterChanged?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
}

export const Map: React.FC<MapProps> = ({
  onClick,
  onIdle,
  onCenterChanged,
  children,
  style,
  ...options
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, { ...options }));
    }
  }, [ref, map, options]);

  React.useEffect(() => {
    if (map) {
      ['click', 'idle', 'center_changed'].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }

      if (onCenterChanged) {
        map.addListener('center_changed', () => onCenterChanged(map));
      }
    }
  }, [map, onClick, onIdle, onCenterChanged]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map } as Partial<unknown> & Attributes);
        }
      })}
    </>
  );
};