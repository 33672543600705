import {
    LoadGoalsRequestAction,
    LOAD_GOALS_REQUEST,
    LoadGoalsSuccessAction,
    LOAD_GOALS_SUCCESS,
    Goal,
    LoadGoalsFailureAction,
    LOAD_GOALS_FAILURE,
    CreateOrUpdateGoalRequestAction,
    CREATE_OR_UPDATE_GOAL_REQUEST,
    CreateOrUpdateGoalSuccessAction,
    CREATE_OR_UPDATE_GOAL_SUCCESS,
    CreateOrUpdateGoalFailureAction,
    CREATE_OR_UPDATE_GOAL_FAILURE,
} from "./types";
import { GoalEdit } from "./models/GoalEdit";

export function loadGoals(): LoadGoalsRequestAction {
    return {
        type: LOAD_GOALS_REQUEST, payload: {}
    };
}

export function loadGoalsSuccess(goalList: Goal[]): LoadGoalsSuccessAction {
    return {
        type: LOAD_GOALS_SUCCESS, payload: goalList
    };
}

export function loadGoalsFailure(error: Error): LoadGoalsFailureAction {
    return {
        type: LOAD_GOALS_FAILURE, payload: { error }
    };
}

export function createOrUpdateGoal(goal: GoalEdit): CreateOrUpdateGoalRequestAction {
    return {
        type: CREATE_OR_UPDATE_GOAL_REQUEST, payload: goal
    };
}

export function createOrUpdateGoalSuccess(goal: Goal): CreateOrUpdateGoalSuccessAction {
    return {
        type: CREATE_OR_UPDATE_GOAL_SUCCESS, payload: goal
    };
}

export function createOrUpdateGoalFailure(error: Error): CreateOrUpdateGoalFailureAction {
    return {
        type: CREATE_OR_UPDATE_GOAL_FAILURE, payload: { error }
    };
}