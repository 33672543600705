import { Goal } from './types';
import { get, post } from '../../utils/api';
import { GoalEdit } from './models/GoalEdit';

const endpoint = '/api/goals/';

export async function getGoals(): Promise<Goal[]> {
    return get<Goal[]>(endpoint);
}

export async function createOrUpdateGoal(goal: GoalEdit): Promise<Goal> {
    return post<Goal>(endpoint, goal);
}