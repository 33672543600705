import React from "react";
import { ClientDetailedSaleStats } from "../../types";
import { GroupingSection } from "./GroupSection";
import { defineMessages, useIntl } from "react-intl";

const m = defineMessages({
    byCover: { id: "OrdersReportTable.ClientRows.ByCover", defaultMessage: "By cover" },
    byModel: { id: "OrdersReportTable.ClientRows.ByModel", defaultMessage: "By model" },
});

interface DetailedStatsProps {
    detailedStats?: ClientDetailedSaleStats;
    reportMonthsInOrder: number[];
    outputCurrency: string;
}

export const DetailedStats: React.FC<DetailedStatsProps> = ({
    detailedStats,
    reportMonthsInOrder,
    outputCurrency,
}) => {
    const { formatMessage } = useIntl();

    if (!detailedStats) return null;

    const byModelSection = (
        <GroupingSection
            sectionName={formatMessage(m.byModel)}
            groupedAmounts={detailedStats.saleTotalAmountsGroupedByModel}
            groupsNameById={detailedStats.modelsNameById}
            reportMonthsInOrder={reportMonthsInOrder}
            outputCurrency={outputCurrency}
        />
    );

    const byCoverSection = (
        <GroupingSection
            sectionName={formatMessage(m.byCover)}
            groupedAmounts={detailedStats.saleTotalAmountsGroupedByCover}
            groupsNameById={detailedStats.coversDescriptionById}
            reportMonthsInOrder={reportMonthsInOrder}
            outputCurrency={outputCurrency}
        />
    );

    return (
        <>
            {byModelSection}
            {byCoverSection}
        </>
    );
};
