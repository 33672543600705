import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { Icon, Label } from "semantic-ui-react";
import { nameof } from "../../../../utils/nameof";
import { OrdersFilters } from "../../../orders";
import {
    getRepresentativeById,
    getRepresentativeDisplayName,
} from "../../../representatives/selectors";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store/store";
import { Client } from "../../../clients/types";
import { getClientById } from "../../../clients/selectors";
import { User } from "../../../account/types";

const m = defineMessages({
    filtersLabel: { id: 'ReportActiveFilters.filtersLabel', defaultMessage: 'Filters:' }
});

interface ReportActiveFiltersProps {
    onClear: (filterId: string) => void;
}

export const ReportActiveFilters: React.FC<ReportActiveFiltersProps> = ({ onClear }) => {
    const { formatMessage } = useIntl();
    const location = useLocation();
    const filters = queryString.parse(location.search) as OrdersFilters;

    const hasFilters =
        filters.clientId != null || filters.representativeId != null || filters.currency != null || filters.countryCode || filters.provinceCode;

    const client = useSelector<ApplicationState, Client | undefined>((state) =>
        getClientById(state, Number(filters.clientId))
    );

    const representative = useSelector<ApplicationState, User | undefined>((state) =>
        getRepresentativeById(state, filters.representativeId || "")
    );

    if (!hasFilters) {
        return <></>;
    }

    const renderFilter = (id: string, label: string) => {
        return (
            <Label
                size="tiny"
                as="a"
                onClick={() => onClear(id)}
                style={{ marginBottom: ".25rem" }}
            >
                {label}
                <Icon name="delete" />
            </Label>
        );
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
            }}
        >
            <p style={{ margin: "0 0.5rem 0 0", fontWeight: "bold" }}>
                {formatMessage(m.filtersLabel)}
            </p>

            {representative != null &&
                renderFilter(
                    nameof<OrdersFilters>("representativeId"),
                    getRepresentativeDisplayName(representative)
                )}

            {client != null && renderFilter(nameof<OrdersFilters>("clientId"), client.displayName)}

            {filters.countryCode != null && renderFilter(nameof<OrdersFilters>("countryCode"), filters.countryCode)}

            {filters.provinceCode != null && renderFilter(nameof<OrdersFilters>("provinceCode"), filters.provinceCode)}

            {filters.currency != null &&
                renderFilter(nameof<OrdersFilters>("currency"), filters.currency)}
        </div>
    );
};
