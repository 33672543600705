import { RepresentativesActionType, LOAD_REPRESENTATIVES_REQUEST, LOAD_REPRESENTATIVES_SUCCESS, LOAD_REPRESENTATIVES_FAILURE, RepresentativesState } from "./types";
import { Reducer } from "redux";

const initialState: RepresentativesState = {
    byId: {},
    allIds: [],
    loading: false,
};

export const representativesReducer: Reducer<RepresentativesState, RepresentativesActionType> = (state: RepresentativesState = initialState, action: RepresentativesActionType) => {
    switch (action.type) {
        case LOAD_REPRESENTATIVES_REQUEST:
            return {
                ...state,
                loading: true
            };

        case LOAD_REPRESENTATIVES_SUCCESS: {
            const byId = Object.assign({}, ...action.payload.map(x => ({ [x.id]: x })));
            const allIds = action.payload.map(x => x.id);

            return {
                ...state,
                byId,
                allIds,
                loading: false
            };
        }

        case LOAD_REPRESENTATIVES_FAILURE:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
};