import { BranchEdit, ClientEdit } from "./models";

export const LOAD_CLIENTS_REQUEST = 'CLIENTS_LOAD_REQUEST';
export const LOAD_CLIENTS_SUCCESS = 'CLIENTS_LOAD_SUCCESS';
export const LOAD_CLIENTS_FAILURE = 'CLIENTS_LOAD_FAILURE';

export const CREATE_CLIENT_REQUEST = 'CLIENTS_CREATE_REQUEST';
export const CREATE_CLIENT_SUCCESS = 'CLIENTS_CREATE_SUCCESS';
export const CREATE_CLIENT_FAILURE = 'CLIENTS_CREATE_FAILURE';

export const CREATE_BRANCH_REQUEST = 'CLIENTS_CREATE_BRANCH_REQUEST';
export const CREATE_BRANCH_SUCCESS = 'CLIENTS_CREATE_BRANCH_SUCCESS';
export const CREATE_BRANCH_FAILURE = 'CLIENTS_CREATE_BRANCH_FAILURE';

export interface LoadClientsRequestAction { type: typeof LOAD_CLIENTS_REQUEST, payload: unknown }
export interface LoadClientsSuccessAction { type: typeof LOAD_CLIENTS_SUCCESS, payload: Client[] }
export interface LoadClientsFailureAction { type: typeof LOAD_CLIENTS_FAILURE, payload: { error: Error } }

export interface CreateClientRequestAction { type: typeof CREATE_CLIENT_REQUEST, payload: { client: ClientEdit } }
export interface CreateClientSuccessAction { type: typeof CREATE_CLIENT_SUCCESS, payload: { client: Client } }
export interface CreateClientFailureAction { type: typeof CREATE_CLIENT_FAILURE, payload: { error: Error } }

export interface CreateBranchRequestAction { type: typeof CREATE_BRANCH_REQUEST, payload: { clientId: number, branch: BranchEdit } }
export interface CreateBranchSuccessAction { type: typeof CREATE_BRANCH_SUCCESS, payload: { branch: Branch } }
export interface CreateBranchFailureAction { type: typeof CREATE_BRANCH_FAILURE, payload: { error: Error } }

export type ClientActionTypes =
    | LoadClientsRequestAction | LoadClientsSuccessAction | LoadClientsFailureAction
    | CreateClientRequestAction | CreateClientSuccessAction | CreateClientFailureAction
    | CreateBranchRequestAction | CreateBranchSuccessAction | CreateBranchFailureAction;

export interface Client {
    id: number;
    legalName: string;
    displayName: string;
    ownerEmail: string;
    createdById: string;
    createdOn: Date;
    ownedById: string;
    isActive: boolean;
    type: ClientType;
    branches: Branch[];
    sets: Set[];
}

export interface Branch {
    id: number;
    email: string;
    createdById: string;
    createdOn: Date;
    ownedById: string;
    isActive: boolean;
    clientId: number;
    phone: string;
    address: Address;
    city: string;
    zipCode: string;
    client: Client;
    coordinates: Coordinates;
    hasSpaceReservedForJaymar: boolean;
    clientCode: string;
    alternateName: string;
}

export interface Set {
    id: number;
    name: string;
    setCode: string;
}

export interface Coordinates {
    x: number;
    y: number;
}

export enum ClientType {
    Lead,
    Client,
}

export interface ClientSet {
    id?: number;
    setId: number;
    clientId: number;
    set: Set;
}

export interface Address {
    address1: string;
    address2: string;
    city: string;
    province: string;
    postalCode: string;
    country: string;
}

export interface ClientsState {
    readonly byId: { [id: number]: Client };
    readonly allIds: number[];
    readonly loading: boolean;
    readonly saving: boolean;
}