import { get } from "../../utils/api";
import { ClientDetailedOrderStats, ClientDetailedSaleStats, OrdersStat, SalesStat } from "./types";
import queryString from "query-string";
import { Invoice, Order } from "../orders";
import { Branch } from "../clients/types";

const baseUrl = "/api/reports";

export interface GetOrdersStatFilters {
    clientId?: number;
    countryCode?: string;
    provinceCode?: string;
    currency?: string;
}

export async function getOrdersStat(startDate: Date, endDate: Date, filters: GetOrdersStatFilters) {
    const stringifiedQuery = queryString.stringify({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        ...filters,
    });

    return get<OrdersStat>(`${baseUrl}/orders?${stringifiedQuery}`);
}

export async function getBranchOrdersByClientCode(clientCode: string, startDate: Date, endDate: Date) {
    return get<Order[]>(
        `api/branches/all/by-clientcode/${clientCode}/orders?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
    );
}

export async function getClientDetailedOrderStats(
    clientCode: string,
    startDate: Date,
    endDate: Date
) {
    return get<ClientDetailedOrderStats>(
        `${baseUrl}/orders/clients/${clientCode}/detailed-stats?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
    );
}

export interface GetSalesStatFilters {
    clientId?: number;
    countryCode?: string;
    provinceCode?: string;
    currency?: string;
}

export async function getSalesStat(startDate: Date, endDate: Date, filters: GetSalesStatFilters) {
    const stringifiedQuery = queryString.stringify({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        ...filters,
    });

    return get<SalesStat>(`${baseUrl}/sales?${stringifiedQuery}`);
}

export async function getBranchInvoicesByClientCode(clientCode: string, startDate: Date, endDate: Date) {
    return get<Invoice[]>(
        `api/branches/all/by-clientcode/${clientCode}/invoices?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
    );
}

export async function getBranchByClientCode(clientCode: string) {
    return get<Branch>(`api/branches/all/by-clientcode/${clientCode}`);
}

export async function getClientDetailedSaleStats(
    clientCode: string,
    startDate: Date,
    endDate: Date
) {
    return get<ClientDetailedSaleStats>(
        `${baseUrl}/sales/clients/${clientCode}/detailed-stats?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
    );
}