import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Checkbox, Icon, Popup, Segment } from "semantic-ui-react";
import styles from './JaymarStudioForm.module.css';

interface OwnProps {
    hasJaymarStudio: boolean;
    disabled?: boolean;

    onChange: (hasJaymarStudio: boolean) => void;
}

export type JaymarStudioSectionProps =
    & OwnProps;

const m = defineMessages({
    title: { id: 'JaymarStudioSection.title', defaultMessage: 'Jaymar Studio' },
    subtitle: { id: 'JaymarStudioSection.subtitle', defaultMessage: 'Space reserved for Jaymar in the store.' },
    tooltip: { id: 'JaymarStudioSection.tooltip', defaultMessage: 'Specifies whether there is a space reserved in the store to showcase Jaymar\'s products.' }
});

export const JaymarStudioForm: React.FC<JaymarStudioSectionProps> = (props) => {
    const { formatMessage } = useIntl();

    return (
        <Segment disabled={props.disabled} style={{ marginBottom: 0, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ flexGrow: 1 }}>
                <strong style={{ marginRight: '.125rem' }}>{formatMessage(m.title)}</strong>
                <Popup on="click" position="bottom center" trigger={<Icon name="question circle outline" link />}>
                    {formatMessage(m.tooltip)}
                </Popup>
                <small style={{ display: 'block' }}>{formatMessage(m.subtitle)}</small>
            </div>
            <div>
                <Checkbox className={styles.toggle} checked={props.hasJaymarStudio} toggle onChange={(e, data) => props.onChange(data.checked || false)} />
            </div>
        </Segment>
    );
};