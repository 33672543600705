import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { ActivityType } from '../../types';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import styles from './ActivityTypeControl.module.css';

export interface ActivityTypeControlProps {
    selectedType: ActivityType | undefined;
    onChange: (type: ActivityType) => void;
}

const m = defineMessages({
    note: { id: 'ActivityTypeControl.note', defaultMessage: 'Note' },
    visit: { id: 'ActivityTypeControl.visit', defaultMessage: 'Visit' },
    call: { id: 'ActivityTypeControl.call', defaultMessage: 'Call' }
});

export const ActivityTypeControl: React.FC<ActivityTypeControlProps> = (props) => {
    const { formatMessage } = useIntl();
    const renderButton = (type: ActivityType, icon: SemanticICONS, title: string) => (
        <Button className={styles.activityTypeButton} primary={props.selectedType === type} onClick={() => props.onChange(type)}>
            <Icon name={icon} />{title}
        </Button>
    );

    return (
        <Button.Group fluid>
            {renderButton(ActivityType.Note, 'sticky note', formatMessage(m.note))}
            {renderButton(ActivityType.Visit, 'street view', formatMessage(m.visit))}
            {renderButton(ActivityType.Phone, 'phone', formatMessage(m.call))}
        </Button.Group>
    );
};