import { SupportedLocales } from '../../configureI18n';

export const SET_LOCALE = 'LAYOUT_SET_LOCALE';
export const SET_PAGE_TITLE = 'LAYOUT_SET_PAGE_TITLE';

export interface SetLocaleAction { type: typeof SET_LOCALE, payload: { locale: SupportedLocales } }
export interface SetPageTitleAction { type: typeof SET_PAGE_TITLE, payload: { title: string } }

export type LayoutActionTypes =
    | SetLocaleAction
    | SetPageTitleAction;

export interface LayoutState {
    currentLocale: SupportedLocales;
    currentPageTitle: string;
}