import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Icon } from "semantic-ui-react";
import { Branch } from "../../../clients/types";
import styles from "./BranchInfo.module.css";

const m = defineMessages({
    clientContactLabel: {
        id: "BranchInfo.Contact.clientContactLabel",
        defaultMessage: "Main contact",
    },
    branchContactLabel: {
        id: "BranchInfo.Contact.branchContactLabel",
        defaultMessage: "Branch contact",
    },
});

interface EmailProps {
    branch: Branch;
}

export const Email: React.FC<EmailProps> = ({ branch }) => {
    const { formatMessage } = useIntl();

    return (
        <div className={styles.property}>
            <div className={styles.icon}>
                <Icon size="large" name="mail" />
            </div>
            <div className={styles.content}>
                {branch.client?.ownerEmail != null && (
                    <div style={{ marginBottom: ".75rem" }}>
                        <label>{formatMessage(m.clientContactLabel)}</label>
                        <a href={`mailto:${branch.client?.ownerEmail}`}>
                            {branch.client?.ownerEmail}
                        </a>
                    </div>
                )}

                {branch.email != null && branch.email != branch.client?.ownerEmail && (
                    <div style={{ marginBottom: ".75rem" }}>
                        <label>{formatMessage(m.branchContactLabel)}</label>
                        <a href={`mailto:${branch.email}`} style={{ display: "block" }}>
                            {branch.email}
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};
