import _ from 'lodash';
import { ValidationResult } from '../../../utils/ValidationResult';
import { defineMessages } from 'react-intl';
import { ClientType } from '../types';
import { nameof } from '../../../utils/nameof';
import { BranchEdit } from './BranchEdit';

const m = defineMessages({
    nameRequired: { id: 'ClientEdit.nameRequired', defaultMessage: 'You must specify a name for this account.' },
    ownerEmailRequired: { id: 'ClientEdit.ownerEmailRequired', defaultMessage: 'You must specify the email address of the owner.' },
    branchRequired: { id: 'ClientEdit.branchRequired', defaultMessage: 'You must specify the branch information.' },
});

export class ClientEdit {
    public displayName: string | undefined;
    public clientId: number | undefined;
    public type: ClientType = ClientType.Lead;
    public ownerEmail: string | undefined;
    public branch: BranchEdit | undefined;

    public constructor(init?: Partial<ClientEdit>) {
        Object.assign(this, init);
    }

    public validate(): ValidationResult {
        const result = new ValidationResult();

        if (this.displayName == null) {
            result.isValid = false;
            result.errorFields.push(nameof<ClientEdit>('displayName'));
            result.errorMessages.push(m.nameRequired);
        }

        if (this.clientId == null) {
            result.isValid = false;
            result.errorFields.push(nameof<ClientEdit>('clientId'));
            result.errorMessages.push(m.nameRequired);
        }

        if (_.isEmpty(this.ownerEmail?.trim())) {
            result.isValid = false;
            result.errorFields.push(nameof<ClientEdit>('ownerEmail'));
            result.errorMessages.push(m.ownerEmailRequired);
        }

        if (this.branch == null) {
            result.isValid = false;
            result.errorFields.push(nameof<ClientEdit>('branch'));
            result.errorMessages.push(m.branchRequired);
        }

        return result;
    }
}