import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Dropdown, DropdownItemProps, DropdownProps } from "semantic-ui-react";
import { CurrencyFilter } from "../../types";

const m = defineMessages({
    allConvertedInUSD: { id: "CurrencyFilterDropdown.allConvertedInUSD", defaultMessage: "All currencies (converted in USD)" },
    cadCurrency: { id: "CurrencyFilterDropdown.cadCurrency", defaultMessage: "CAD only" },
    usdCurrency: { id: "CurrencyFilterDropdown.usdCurrency", defaultMessage: "USD only" },
});

interface CurrencyFilterDropdownProps extends DropdownProps {
    defaultOption?: DropdownItemProps;
}

export const CurrencyFilterDropdown: React.FC<CurrencyFilterDropdownProps> = (props) => {
    const { formatMessage } = useIntl();

    const { defaultOption, ...dropdownProps } = props;

    const currenciesOptions: DropdownItemProps[] = [
        { text: formatMessage(m.allConvertedInUSD), value: CurrencyFilter.AllConvertedInUsd },
        { text: formatMessage(m.cadCurrency), value: CurrencyFilter.CadOnly},
        { text: formatMessage(m.usdCurrency), value: CurrencyFilter.UsdOnly },
    ];

    return (
        <Dropdown
            options={
                defaultOption != null ? [defaultOption, ...currenciesOptions] : currenciesOptions
            }
            {...dropdownProps}
        />
    );
};
