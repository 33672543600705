import React from 'react';
import _ from 'lodash';
import { Divider, DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { Client, Branch } from '../../../../modules/clients/types';
import styles from './ClientBranchPicker.module.css';
import { defineMessages, useIntl } from 'react-intl';
import { ClientPicker } from '../../../clients/views/components/ClientPicker';

export interface ClientBranchPicker {
    clients: Client[];
    selectedClient: Client | undefined;
    selectedBranch: Branch | undefined;
    loading?: boolean;
    allowAdditions?: boolean;
    disabled?: boolean;
    isClientInError?: boolean;
    isBranchInError?: boolean;

    onClientChange: (client: Client | undefined) => void;
    onBranchChange: (branch: Branch | undefined) => void;
}

const m = defineMessages({
    clientPlaceholder: { id: 'ClientBranchPicker.clientPlaceholder', defaultMessage: 'Choose a client' },
    branchPlaceholder: { id: 'ClientBranchPicker.branchPlaceholder', defaultMessage: 'Choose a branch' }
});

export const ClientBranchPicker: React.FC<ClientBranchPicker> = (props) => {
    const { formatMessage } = useIntl();

    const branchOptions = _(props.selectedClient?.branches || [])
        .orderBy(x => x.city)
        .map((x): DropdownItemProps => ({
            key: x.id,
            text: x.city,
            value: x.id,
            selected: props.selectedBranch?.id === x.id
        }))
        .value();

    const handleClientChange = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const client = data.value ? props.clients.find(x => x.id === Number(data.value)) : undefined;
        props.onClientChange(client);
    };

    const handleBranchChange = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const branch = data.value && props.selectedClient ? props.selectedClient.branches.find(x => x.id === Number(data.value)) : undefined;
        props.onBranchChange(branch);
    };

    return (
        <>
            <Form.Field error={props.isClientInError} style={{ marginBottom: '.5rem' }}>
                <ClientPicker
                    clients={props.clients}
                    loading={props.loading}
                    disabled={props.loading}
                    allowAdditions={props.allowAdditions}
                    value={props.selectedClient?.id || ''}
                    onChange={handleClientChange}
                    placeholder={formatMessage(m.clientPlaceholder)}
                />
            </Form.Field>

            <Divider fitted />

            <Form.Dropdown
                error={props.isBranchInError}
                className={styles.branchPicker}
                loading={props.loading}
                disabled={props.loading || props.disabled || branchOptions.length <= 0}
                inline
                placeholder={formatMessage(m.branchPlaceholder)}
                options={branchOptions}
                value={props.selectedBranch?.id || ''}
                onChange={handleBranchChange}
            />
        </>
    );
};