import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { DropdownProps, Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';

interface OwnProps {
    from: number;
    to: number;
}

export type YearPickerProps =
    & OwnProps
    & DropdownProps;

const m = defineMessages({
    lastYear: { id: 'YearPicker.lastYear', defaultMessage: 'Last year' },
    thisYear: { id: 'YearPicker.thisYear', defaultMessage: 'This year' },
    nextYear: { id: 'YearPicker.nextYear', defaultMessage: 'Next year' }
});

export const YearPicker: React.FC<YearPickerProps> = (props) => {
    const { formatMessage } = useIntl();
    const { from, to, ...dropdownProps } = props;

    const currentYear = dayjs().year();
    const yearOptions = _.range(from, to).map((x): DropdownItemProps => {
        if (x === currentYear) {
            return { key: x, text: formatMessage(m.thisYear), value: x };
        } else if (x === currentYear - 1) {
            return { key: x, text: formatMessage(m.lastYear), value: x };
        } else if (x === currentYear + 1) {
            return { key: x, text: formatMessage(m.nextYear), value: x };
        }

        return { key: x, text: x, value: x };
    });

    return (
        <Dropdown
            options={yearOptions}
            {...dropdownProps}
        />
    );
};