import { applyMiddleware, combineReducers, legacy_createStore as createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import { createBrowserHistory } from "history";
import { LayoutState, layoutReducer } from "../modules/layout";
import { AccountState } from "../modules/account/types";
import { accountReducer } from "../modules/account/reducer";
import { clientsReducer } from "../modules/clients/reducer";
import { ClientsState } from "../modules/clients/types";
import { GoalsReducer } from "../modules/goals/reducer";
import { GoalsState } from "../modules/goals/types";
import { representativesReducer } from "../modules/representatives/reducer";
import { RepresentativesState } from "../modules/representatives/types";
import { OrdersState, ordersReducer } from "../modules/orders";
import { createReduxHistoryContext, RouterState } from "redux-first-history";
import { composeWithDevTools } from "redux-devtools-extension";

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
});

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
    combineReducers<ApplicationState>({
        router: routerReducer,
        layout: layoutReducer,
        account: accountReducer,
        clients: clientsReducer,
        goals: GoalsReducer,
        orders: ordersReducer,
        representatives: representativesReducer,
    }),
    composeWithDevTools(applyMiddleware(routerMiddleware), applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export const history = createReduxHistory(store);

export interface ApplicationState {
    router: RouterState;
    layout: LayoutState;
    account: AccountState;
    clients: ClientsState;
    orders: OrdersState;
    goals: GoalsState;
    representatives: RepresentativesState;
}
