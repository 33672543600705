import { GoalEdit } from "./models/GoalEdit";

export const LOAD_GOALS_REQUEST = 'GOALS_LOAD_REQUEST';
export const LOAD_GOALS_SUCCESS = 'GOALS_LOAD_SUCCESS';
export const LOAD_GOALS_FAILURE = 'GOALS_LOAD_FAILURE';

export const CREATE_OR_UPDATE_GOAL_REQUEST = 'CREATE_OR_UPDATE_GOAL_REQUEST';
export const CREATE_OR_UPDATE_GOAL_SUCCESS = 'CREATE_OR_UPDATE_GOAL_SUCCESS';
export const CREATE_OR_UPDATE_GOAL_FAILURE = 'CREATE_OR_UPDATE_GOAL_FAILURE';

export interface LoadGoalsRequestAction { type: typeof LOAD_GOALS_REQUEST; payload: unknown }
export interface LoadGoalsSuccessAction { type: typeof LOAD_GOALS_SUCCESS; payload: Goal[] }
export interface LoadGoalsFailureAction { type: typeof LOAD_GOALS_FAILURE; payload: { error: Error } }

export interface CreateOrUpdateGoalRequestAction { type: typeof CREATE_OR_UPDATE_GOAL_REQUEST; payload: GoalEdit }
export interface CreateOrUpdateGoalSuccessAction { type: typeof CREATE_OR_UPDATE_GOAL_SUCCESS; payload: Goal }
export interface CreateOrUpdateGoalFailureAction { type: typeof CREATE_OR_UPDATE_GOAL_FAILURE; payload: { error: Error } }

export type GoalActionTypes = LoadGoalsRequestAction | LoadGoalsSuccessAction | LoadGoalsFailureAction
    | CreateOrUpdateGoalRequestAction | CreateOrUpdateGoalSuccessAction | CreateOrUpdateGoalFailureAction;

export interface Goal {
    id: number;
    year: number;
    clientId: number;
    target: number;
}

export interface GoalsState {
    readonly byId: { [id: number]: Goal };
    readonly byClientId: { [clientId: number]: number[] };
    readonly byYear: { [year: number]: number[] };
    readonly allIds: number[];
    readonly loading: boolean;
    readonly saving: boolean;
}

export interface GoalsFilters {
    month?: string;
    clientId?: string;
    representativeId?: string;
    countryCode?: string;
    provinceCode?: string;
    currency?: string;
    [key: string]: any;
}

export interface Point {
    x: number;
    y: number;
}

export enum GoalsChartType {
    wholeYear,
    specificMonth,
}