import React from 'react';
import { List, Header, Icon } from 'semantic-ui-react';
import { useIntl, defineMessages } from 'react-intl';
import { Set } from '../../../clients/types';
import { LoadingSetPlaceholder } from './LoadingSetPlaceholder';

export interface SelectedSetsListProps {
    selectedSets: Set[];
    loading?: boolean;
    disabled?: boolean;

    onUnselect: (set: Set) => void;
}

const m = defineMessages({
    selectedSetsCount: { id: 'SelectedSetsList.selectedSetsCount', defaultMessage: '{count, plural, =0 {No sets} one {{count} set} other {{count} sets}} selected' },
    productCodeFormat: { id: 'SelectedSetsList.productCodeFormat', defaultMessage: '#{code}' },
    noContentTitle: { id: 'SelectedSetsList.noContentTitle', defaultMessage: 'There are no sets selected.' },
    noContentDescription: { id: 'SelectedSetsList.noContentDescription', defaultMessage: 'Search for a set and click it to add it to this list.' }
});

export const SelectedSetsList: React.FC<SelectedSetsListProps> = (props) => {
    const { formatMessage } = useIntl();
    const handleClick = (set: Set) => {
        if (!props.disabled) {
            props.onUnselect(set);
        }
    };

    return (
        <>
            {!props.loading && props.selectedSets.length > 0 &&
                <>
                    <h3 style={{ marginBottom: '.5rem' }}>{formatMessage(m.selectedSetsCount, { count: props.selectedSets.length })}</h3>
                    <List>
                        {props.selectedSets.map(x => (
                            <List.Item key={x.id}>
                                <List.Icon name="trash" color="red" onClick={() => handleClick(x)} style={{ cursor: 'pointer' }} />
                                <List.Content>
                                    <List.Header>{x.name}</List.Header>
                                    <List.Description>{formatMessage(m.productCodeFormat, { code: x.setCode })}</List.Description>
                                </List.Content>
                            </List.Item>
                        )
                        )}
                    </List>
                </>
            }

            {!props.loading && props.selectedSets.length <= 0 &&
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <Header icon size="tiny" disabled textAlign="center">
                        <Icon name="box" />
                        {formatMessage(m.noContentTitle)}
                        <Header.Subheader>{formatMessage(m.noContentDescription)}</Header.Subheader>
                    </Header>
                </div>
            }

            {props.loading &&
                <>
                    <LoadingSetPlaceholder />
                    <LoadingSetPlaceholder />
                </>
            }
        </>
    );
};