import { Reducer } from "redux";

import { ApiError } from "../../utils/api";
import {
    AccountActionTypes,
    AccountState,
    LOGOUT_REQUEST,
    RETRIEVE_USER_REQUEST,
    RETRIEVE_USER_SUCCESS,
    RETRIEVE_USER_FAILURE,
} from "./types";

const initialState: AccountState = {
    user: undefined,
    isLoading: false,
    hasFailedAuthentication: false,
    isAuthenticated: false,
    isSessionLoaded: false,
};

const reducer: Reducer<AccountState, AccountActionTypes> = (state = initialState, action) => {
    if (
        action.payload &&
        ((action.payload as any).error instanceof ApiError || action.payload instanceof ApiError)
    ) {
        const error = ((action.payload as any).error || action.payload) as ApiError;
        if (error.statusCode === 401) {
            return {
                ...state,
                isAuthenticated: false,
                isSessionLoaded: true,
                isLoading: false,
            };
        }
    }

    switch (action.type) {
        case LOGOUT_REQUEST: {
            return {
                ...state,
                isAuthenticated: false,
            };
        }
        case RETRIEVE_USER_REQUEST: {
            return {
                ...state,
                isLoading: true,
                hasFailedAuthentication: false,
                isSessionLoaded: false,
            };
        }
        case RETRIEVE_USER_SUCCESS: {
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload,
                hasFailedAuthentication: false,
                isSessionLoaded: true,
            };
        }
        case RETRIEVE_USER_FAILURE: {
            return {
                ...state,
                isAuthenticated: false,
                isLoading: false,
                hasFailedAuthentication: true,
                isSessionLoaded: true,
            };
        }
        default:
            return state;
    }
};

export { reducer as accountReducer };
