import React from "react";
import orderBy from "lodash/orderBy";
import { Loader, Table, TableCell } from "semantic-ui-react";
import { OrdersReportData } from "../../hooks/useOrdersReportData";
import deburr from "lodash/deburr";
import { TableHeaders } from "./TableHeaders";
import { TableClientRows } from "./TableClientRows";
import { OrdersTotalRows } from "./OrdersTotalRows";

interface OrdersReportTableProps {
    ordersReportData: OrdersReportData;
    isLoading: boolean;
}

export const OrdersReportTable: React.FC<OrdersReportTableProps> = ({
    ordersReportData,
    isLoading,
}) => {
    const orderedClientCodes = orderBy(ordersReportData.clientCodes, (clientCode) =>
        deburr((ordersReportData.clientNameByClientCode[clientCode] || "").toLowerCase())
    );

    return (
        <Table compact celled structured unstackable style={{ fontSize: "0.7rem" }}>
            <TableHeaders reportMonthsInOrder={ordersReportData.monthsInOrder} />
            <Table.Body>
                {isLoading && (
                    <Table.Row>
                        <TableCell colSpan={15} textAlign="center">
                            <Loader style={{ margin: "1rem" }} inline active />
                        </TableCell>
                    </Table.Row>
                )}
                {!isLoading &&
                    orderedClientCodes.map((clientCode) => (
                        <TableClientRows
                            key={clientCode}
                            clientCode={clientCode}
                            ordersReportData={ordersReportData}
                        />
                    ))}
                {!isLoading && <OrdersTotalRows ordersReportData={ordersReportData} />}
            </Table.Body>
        </Table>
    );
};
