import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FluidPageLayout } from "../../../components/layouts";
import queryString from "query-string";
import {
    FloatingActionButton,
    FloatingActionButtons,
} from "../../../components/floatingActionButton";
import { OrdersFilters } from "../../orders";
import { FiltersForm } from "../components/FiltersForm";

export const SalesReportFiltersPage = () => {
    const navigate = useNavigate();

    const [filters, setFilters] = useState<OrdersFilters>({});

    const cancel = () => navigate(-1);

    const applyFilters = () => {
        const queryFilters = queryString.stringify(filters);
        navigate(`/reports/sales?${queryFilters}`);
    };

    return (
        <FluidPageLayout>
            <div style={{ marginTop: ".25rem" }}>
                <FiltersForm onChange={setFilters} />
            </div>

            <FloatingActionButtons>
                <FloatingActionButton icon="cancel" onClick={cancel} />
                <FloatingActionButton primary icon="check" onClick={applyFilters} />
            </FloatingActionButtons>
        </FluidPageLayout>
    );
};
