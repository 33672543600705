import React from 'react';
import { Icon, Placeholder } from 'semantic-ui-react';
import { Branch } from '../../../types';
import styles from './BranchSummary.module.css';
import { formatPhoneNumber } from '../../../../../utils/formatters';
import { getApiKey } from '../../../../../utils/GoogleMapsUtils';
import { defineMessages, useIntl } from 'react-intl';
import { BranchMap } from '../BranchMap';

export interface BranchSummaryProps {
    branch: Branch | undefined;
    loading?: boolean;
    className?: string;
    style?: React.CSSProperties;
}

const m = defineMessages({
    clientContactLabel: { id: 'BranchSummary.clientContactLabel', defaultMessage: 'Main contact' },
    branchContactLabel: { id: 'BranchSummary.branchContactLabel', defaultMessage: 'Branch contact' }
});

export const BranchSummary: React.FC<BranchSummaryProps> = (props) => {
    const { formatMessage } = useIntl();

    if (!props.loading && props.branch == null) {
        return (null);
    }

    const branchCoordinates = props.branch?.coordinates;
    const address = props.branch?.address;
    const addressLink = address != null ? `${address.address1}, ${address.address2}, ${address.city}, ${address.province} ${address.postalCode}, ${address.country}` : '';

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flexGrow: 1 }}>
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <Icon size="large" name="map marker alternate" />
                    </div>
                    <div className={styles.content}>
                        {!props.loading &&
                            <a href={`https://www.google.com/maps/search/?api=1&query=${addressLink}`}>
                                <span style={{ display: 'block' }}>{props.branch?.address.address1}</span>
                                <span style={{ display: 'block' }}>{props.branch?.address.address2}</span>
                                <span style={{ display: 'block' }}>{props.branch?.address.city} {props.branch?.address.province}  {props.branch?.address.postalCode}</span>
                                <span style={{ display: 'block' }}>{props.branch?.address.country}</span>
                            </a>
                        }

                        {props.loading &&
                            <Placeholder className="grey" style={{ marginTop: '-12px' }}>
                                <Placeholder.Line length="full" />
                                <Placeholder.Line length="full" />
                                <Placeholder.Line length="full" />
                                <Placeholder.Line length="full" />
                            </Placeholder>
                        }
                    </div>
                </div>

                <div className={styles.property} style={{ marginTop: '1rem' }}>
                    <div className={styles.icon}>
                        <Icon size="large" name="phone" />
                    </div>
                    <div className={styles.content}>
                        {!props.loading &&
                            <div>
                                <label>{formatMessage(m.branchContactLabel)}</label>
                                <a href={`tel:${props.branch?.phone}`}>{formatPhoneNumber(props.branch?.phone)}</a>
                            </div>
                        }
                        {props.loading &&
                            <Placeholder className="grey" style={{ marginTop: '-6px' }}>
                                <Placeholder.Line length="medium" />
                                <Placeholder.Line length="medium" />
                            </Placeholder>
                        }
                    </div>
                </div>

                <div className={styles.property} style={{ marginTop: '1rem' }}>
                    <div className={styles.icon}>
                        <Icon size="large" name="mail" />
                    </div>
                    <div className={styles.content}>
                        {!props.loading &&
                            <>
                                {props.branch?.client?.ownerEmail != null &&
                                    <div style={{ marginBottom: '.75rem' }}>
                                        <label>{formatMessage(m.clientContactLabel)}</label>
                                        <a href={`mailto:${props.branch?.client?.ownerEmail}`}>{props.branch?.client?.ownerEmail}</a>
                                    </div>
                                }

                                {props.branch?.email != null && props.branch?.email != props.branch?.client?.ownerEmail &&
                                    <div style={{ marginBottom: '.75rem' }}>
                                        <label>{formatMessage(m.branchContactLabel)}</label>
                                        <a href={`mailto:${props.branch?.email}`} style={{ display: 'block' }}>{props.branch?.email}</a>
                                    </div>
                                }
                            </>
                        }
                        {props.loading &&
                            <Placeholder className="grey" style={{ marginTop: '-6px' }}>
                                <Placeholder.Line length="medium" />
                                <Placeholder.Line length="medium" />
                            </Placeholder>
                        }
                    </div>
                </div>
            </div>
            <div style={{ flexGrow: 1 }}>
                <div style={{ minWidth: '100px', minHeight: '100px', height: '100%', marginLeft: '0.5rem', display: 'flex', flexGrow: 1 }}>
                    {!props.loading && branchCoordinates != null &&
                        <BranchMap 
                            branches={props.branch ? [props.branch] : []}
                            defaultCenter={{ lat: branchCoordinates.y, lng: branchCoordinates.x }}
                        />
                    }
                    {props.loading &&
                        <Placeholder style={{ width: '100px', height: '100px' }}>
                            <Placeholder.Image square />
                        </Placeholder>
                    }
                </div>
            </div>
        </div>
    );
};