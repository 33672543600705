import dayjs from 'dayjs';
import { ValidationResult } from '../../../utils/ValidationResult';
import { defineMessages } from 'react-intl';
import { nameof } from '../../../utils/nameof';

const m = defineMessages({
    clientRequired: { id: 'GoalEdit.clientRequired', defaultMessage: 'You must specify the client for this goal.' },
    targetRequired: { id: 'GoalEdit.targetRequired', defaultMessage: 'You must specify the goal\'s target.' },
});

export class GoalEdit {
    public clientId: number | undefined;
    public target: number | undefined;
    public year: number = dayjs().year();

    public constructor(init?: Partial<GoalEdit>) {
        Object.assign(this, init);
    }

    public validate(): ValidationResult {
        const result = new ValidationResult();

        if (this.clientId == null) {
            result.isValid = false;
            result.errorFields.push(nameof<GoalEdit>('clientId'));
            result.errorMessages.push(m.clientRequired);
        }

        if (this.target == null) {
            result.isValid = false;
            result.errorFields.push(nameof<GoalEdit>('target'));
            result.errorMessages.push(m.targetRequired);
        }

        return result;
    }
}