import _ from 'lodash';
import { Reducer, combineReducers } from "redux";
import { OrdersDataState, OrdersActionTypes, LOAD_ORDERS_SUCCESS, LOAD_ORDERS_REQUEST, LOAD_ORDERS_FAILURE, ExchangeRatesState, LOAD_EXCHANGE_RATES_SUCCESS } from "./types";
import dayjs from 'dayjs';

const initialOrdersState: OrdersDataState = {
    byId: {},
    byYear: {},
    byYearAndMonth: {},
    byClientId: {},
    byRepresentativeId: {},
    byCountry: {},
    byProvince: {},
    byCurrency: {},
    allIds: [],
    loading: false
};

const ordersReducer: Reducer<OrdersDataState, OrdersActionTypes> = (state = initialOrdersState, action: OrdersActionTypes) => {
    switch (action.type) {
        case LOAD_ORDERS_REQUEST: {
            return { ...state, loading: true };
        }

        case LOAD_ORDERS_SUCCESS: {
            const byId = Object.assign({}, ...action.payload.orders.map(x => ({ [x.id]: x })));
            const byYear = Object.assign({}, ..._(action.payload.orders).groupBy(x => dayjs(x.orderedOn).year()).map((group, key) => ({ [key]: group.map(x => x.id) })).value());
            const byYearAndMonth = Object.assign({}, ..._(action.payload.orders).groupBy(x => dayjs(x.orderedOn).startOf('month').format('YYYYMMDD')).map((group, key) => ({ [key]: group.map(x => x.id) })).value());
            const byClientId = Object.assign({}, ..._(action.payload.orders).groupBy(x => x.clientId).map((group, key) => ({ [key]: group.map(x => x.id) })).value());
            const byCountry = Object.assign({}, ..._(action.payload.orders).groupBy(x => x.shippingCountryCode).map((group, key) => ({ [key]: group.map(x => x.id) })).value());
            const byProvince = Object.assign({}, ..._(action.payload.orders).groupBy(x => x.shippingProvinceCode).map((group, key) => ({ [key]: group.map(x => x.id) })).value());
            const byCurrency = Object.assign({}, ..._(action.payload.orders).groupBy(x => x.currency).map((group, key) => ({ [key]: group.map(x => x.id) })).value());
            const allIds = action.payload.orders.map(x => x.id);

            return {
                ...state,
                byId: _.merge(state.byId, byId),
                byYear: _.merge(state.byYear, byYear),
                byYearAndMonth: _.merge(state.byYearAndMonth, byYearAndMonth),
                byClientId: _.merge(state.byClientId, byClientId),
                byCountry: _.merge(state.byCountry, byCountry),
                byProvince: _.merge(state.byProvince, byProvince),
                byCurrency: _.merge(state.byCurrency, byCurrency),
                allIds: _.union(state.allIds, allIds),
                loading: false
            };
        }

        case LOAD_ORDERS_FAILURE: {
            return { ...state, loading: false };
        }

        default:
            return state;
    }
};

const initialExchangeRatesState: ExchangeRatesState = {
    byCurrency: {},
    allCurrencies: []
};

const exchangeRatesReducer: Reducer<ExchangeRatesState, OrdersActionTypes> = (state = initialExchangeRatesState, action: OrdersActionTypes) => {
    switch (action.type) {
        case LOAD_EXCHANGE_RATES_SUCCESS: {
            const byCurrency = Object.assign({}, ...action.payload.rates.map(x => ({ [x.currency]: x })));
            const allCurrencies = action.payload.rates.map(x => x.currency);

            return {
                ...state,
                byCurrency,
                allCurrencies
            };
        }

        default:
            return state;
    }
};

const reducers = combineReducers({
    data: ordersReducer,
    exchangeRates: exchangeRatesReducer
});

export { reducers as ordersReducer };