import * as React from 'react';
import { Menu } from 'semantic-ui-react';
import { getCurrentPageTitle } from '../modules/layout';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './TopBar.module.css';

export interface TopBarProps {
    onToggleMenu: () => void;
}

export const TopBar: React.FC<TopBarProps> = (props) => {
    const navigate = useNavigate();
    const pageTitle = useSelector(getCurrentPageTitle);

    return (
        <Menu className={styles.topBar} size="large" borderless inverted>
            <Menu.Item icon="sidebar" onClick={props.onToggleMenu} />
            <Menu.Item header style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {pageTitle}
            </Menu.Item>
            <Menu.Item icon="home" onClick={() => navigate('/')} position="right" />
        </Menu>
    );
};