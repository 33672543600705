import React, { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Progress, Table, TableCell } from "semantic-ui-react";
import { getClientDetailedOrderStats } from "../../api";
import { OrdersReportData } from "../../hooks/useOrdersReportData";
import { useReportDates } from "../../hooks/useReportDates";
import { ClientDetailedOrderStats } from "../../types";
import { RatioCell } from "../RatioCell";
import { DetailedStats } from "./DetailedStats";
import { ExpandButton } from "../ExpandButton/ExpandButton";
import { MonthTotalCell } from "./MonthTotalCell";
import { PeriodTotalCell } from "./PeriodTotalCell";

const m = defineMessages({
    previous: { id: "OrdersReportTable.ClientRows.Previous", defaultMessage: "Previous" },
    current: { id: "OrdersReportTable.ClientRows.Current", defaultMessage: "Current" },
});

interface TableClientRowsProps {
    clientCode: string;
    ordersReportData: OrdersReportData;
}

export const TableClientRows: React.FC<TableClientRowsProps> = ({
    clientCode,
    ordersReportData,
}) => {
    const { formatMessage } = useIntl();

    const {
        getCurrentPeriodYear,
        getPreviousPeriodYear,
        currentPeriodStartDate,
        currentPeriodEndDate,
        previousPeriodStartDate,
        previousPeriodEndDate,
    } = useReportDates();

    const [isExpanded, setIsExpanded] = useState(false);
    const [detailedStats, setDetailedStats] = useState<ClientDetailedOrderStats>();
    const [isLoadingDetailedStats, setIsLoadingDetailedStats] = useState(false);

    useEffect(() => {
        if (!isExpanded || detailedStats) return;

        setIsLoadingDetailedStats(true);

        getClientDetailedOrderStats(clientCode, previousPeriodStartDate, currentPeriodEndDate).then(
            (stats) => {
                setDetailedStats(stats);
            }
        ).finally(() => {
            setIsLoadingDetailedStats(false);
        });
    }, [clientCode, currentPeriodEndDate, detailedStats, isExpanded, previousPeriodStartDate]);

    const clientName = ordersReportData.clientNameByClientCode[clientCode] || "";
    const monthlyOrders = ordersReportData.monthlyOrdersByClientCode[clientCode];

    const currentPeriodRow = (
        <Table.Row>
            <TableCell rowSpan={2} style={{ verticalAlign: "baseline" }}>
                <div>{clientName}</div>
                <div style={{ color: "#686869" }}>{clientCode}</div>
            </TableCell>
            <TableCell>{formatMessage(m.current)}</TableCell>
            {ordersReportData.monthsInOrder.map((month) => (
                <MonthTotalCell
                    key={month}
                    clientCode={clientCode}
                    total={monthlyOrders?.statsByMonth[month]?.currentPeriodTotal}
                    month={month}
                    year={getCurrentPeriodYear(month)}
                />
            ))}
            <PeriodTotalCell
                clientCode={clientCode}
                total={monthlyOrders?.currentPeriodTotal}
                startDate={currentPeriodStartDate}
                endDate={currentPeriodEndDate}
            />
        </Table.Row>
    );

    const previousPeriodRow = (
        <Table.Row style={{ borderTop: "none" }}>
            <TableCell style={{ borderTop: "none" }}>{formatMessage(m.previous)}</TableCell>
            {ordersReportData.monthsInOrder.map((month) => (
                <MonthTotalCell
                    key={month}
                    clientCode={clientCode}
                    total={monthlyOrders?.statsByMonth[month]?.previousPeriodTotal}
                    month={month}
                    year={getPreviousPeriodYear(month)}
                    style={{ borderTop: "none" }}
                />
            ))}
            <PeriodTotalCell
                clientCode={clientCode}
                total={monthlyOrders?.previousPeriodTotal}
                startDate={previousPeriodStartDate}
                endDate={previousPeriodEndDate}
                style={{ borderTop: "none" }}
            />
        </Table.Row>
    );

    const ratiosRow = (
        <Table.Row style={{ borderTop: "none" }}>
            <TableCell style={{ borderTop: "none" }}>
                <ExpandButton
                    isExpanded={isExpanded}
                    onChange={setIsExpanded}
                />
            </TableCell>
            <TableCell style={{ borderTop: "none" }}></TableCell>
            {ordersReportData.monthsInOrder.map((month) => (
                <RatioCell
                    key={month}
                    style={{ borderTop: "none" }}
                    ratio={monthlyOrders?.statsByMonth[month]?.ratio}
                    previousAmount={monthlyOrders?.statsByMonth[month]?.previousPeriodTotal}
                    currentAmount={monthlyOrders?.statsByMonth[month]?.currentPeriodTotal}
                />
            ))}
            <RatioCell
                style={{ borderTop: "none" }}
                ratio={monthlyOrders?.ratio}
                previousAmount={monthlyOrders?.previousPeriodTotal}
                currentAmount={monthlyOrders?.currentPeriodTotal}
            />
        </Table.Row>
    );

    const detailedStatsLoader = isLoadingDetailedStats && (
        <Table.Row style={{ backgroundColor: "#F9FAFB" }}>
            <TableCell colSpan={15}>
                <Progress percent={100} color="blue" indicating inverted size="tiny" />
            </TableCell>
        </Table.Row>
    );

    return (
        <>
            {currentPeriodRow}
            {previousPeriodRow}
            {ratiosRow}
            {isExpanded && (
                <>
                    {detailedStatsLoader}
                    <DetailedStats
                        detailedStats={detailedStats}
                        reportMonthsInOrder={ordersReportData.monthsInOrder}
                        outputCurrency={ordersReportData.outputCurrency}
                    />
                </>
            )}
        </>
    );
};
