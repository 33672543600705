import React from 'react';
import { Table, Placeholder } from 'semantic-ui-react';

export const LoadingActivityRow = () => {
    return (
        <Table.Row>
            <Table.Cell textAlign="right" style={{ width: '95px' }}>
                <Placeholder className="grey">
                    <Placeholder.Line length="full" />
                    <Placeholder.Line className="right" length="short" />
                </Placeholder>
            </Table.Cell>

            <Table.Cell>
                <Placeholder className="grey">
                    <Placeholder.Line length="full" />
                    <Placeholder.Line length="very long" />
                </Placeholder>
            </Table.Cell>
        </Table.Row>
    );
};