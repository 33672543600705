import React from "react";
import { useIntl } from "react-intl";
import { Table, TableCell } from "semantic-ui-react";
import { Order } from "../../../orders";
import { calculateOrderReportPrice } from "../../utils";
import { OrderStatus } from "../OrderStatus";

interface TableRowProps {
    order: Order;
}

export const TableRow: React.FC<TableRowProps> = ({order}) => {
    const {formatDate, formatNumber} = useIntl();

    const orderPrice = calculateOrderReportPrice(order, order.currency, []);

    return (
        <Table.Row key={order.id}>
            <TableCell>{order.id}</TableCell>
            <TableCell>
                <OrderStatus status={order.status} />
            </TableCell>
            <TableCell>
                {formatDate(order.orderedOn, {
                    day: "numeric",
                    month: "numeric",
                    year: "2-digit",
                })}
            </TableCell>
            <TableCell>
                {formatDate(order.shippedOn, {
                    day: "numeric",
                    month: "numeric",
                    year: "2-digit",
                })}
            </TableCell>
            <TableCell>{`${order.shippingCountryCode} ${order.shippingProvinceCode}`}</TableCell>
            <TableCell>
                {order.invoices.map((invoice) => (
                    <div key={invoice.id}>{invoice.id}</div>
                ))}
            </TableCell>
            <TableCell textAlign="right">
                {formatNumber(orderPrice, {
                    style: "decimal",
                    useGrouping: true,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })}{" "}
                {order.currency}
            </TableCell>
        </Table.Row>
    );
};
