import React from 'react';
import { Table, Icon, TableRowProps } from 'semantic-ui-react';
import styles from './RecentActivityRow.module.css';
import { Activity } from '../../activities/types';
import dayjs from 'dayjs';
import { defineMessages, useIntl } from 'react-intl';

interface OwnProps {
    activity: Activity;
}

export type RecentActivityRowProps =
    & OwnProps
    & TableRowProps;

const m = defineMessages({
    activityTitle: { id: 'RecentActivityRow.activityTitle', defaultMessage: "{clientName}: {branchCity}" }
});

export const RecentActivityRow: React.FC<RecentActivityRowProps> = (props) => {
    const { formatMessage } = useIntl();
    const { activity, ...rowProps } = props;
    const hasAttachment = activity.attachmentIds && activity.attachmentIds.length > 0;
    const localActivityDate = dayjs.utc(activity.createdOn).local();

    const branchCity = activity.branch?.city || '';
    const clientName = activity.branch?.client?.displayName || '';

    return (
        <Table.Row verticalAlign="top" {...rowProps}>
            <Table.Cell className={styles.activityDate} textAlign="right">
                <p>{localActivityDate.format('ll')}</p>
                <p>{localActivityDate.format('LT')}</p>
            </Table.Cell>

            <Table.Cell className={styles.activitySummary}>
                <div style={{ overflow: 'hidden' }}>
                    <p>{formatMessage(m.activityTitle, { clientName, branchCity })}</p>
                    <p>{props.activity.note}</p>
                </div>
                {hasAttachment && <Icon size="large" name="attach" />}
            </Table.Cell>
        </Table.Row>
    );
};