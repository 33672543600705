import React, { SyntheticEvent, useState } from 'react';
import { Form, DropdownItemProps, Divider, Dropdown, InputOnChangeData, DropdownProps } from 'semantic-ui-react';
import { ClientPicker } from './ClientPicker';
import { Client, ClientType } from '../../types';
import { useIntl, defineMessages } from 'react-intl';
import { ClientEdit } from '../../models';
import { nameof } from '../../../../utils/nameof';
import { ValidationResult } from '../../../../utils/ValidationResult';

export interface ClientFormProps {
    client: ClientEdit;
    validationResult: ValidationResult;
    allClients: Client[];
    loading?: boolean;

    onChange: (fieldId: string, fieldValue: any) => void;
    onClientChange: (clientId?: number, clientName?: string) => void;
}

const newClientId = 0;

const m = defineMessages({
    clientNamePlaceholder: { id: 'ClientForm.clientNamePlaceholder', defaultMessage: 'Account name' },
    accountTypeLabel: { id: 'ClientForm.accountTypeLabel', defaultMessage: 'Account type:' },
    accountTypeLead: { id: 'ClientForm.accountTypeLead', defaultMessage: 'lead' },
    accountTypeClient: { id: 'ClientForm.accountTypeClient', defaultMessage: 'client' },
    ownerEmailLabel: { id: 'ClientForm.ownerEmailLabel', defaultMessage: 'Owner\'s email' },
    addLabel: { id: 'ClientForm.addLabel', defaultMessage: 'Add ' }
});

export const ClientForm: React.FC<ClientFormProps> = (props) => {
    const { formatMessage } = useIntl();

    const [newClient, setNewClient] = useState<Client | undefined>(undefined);
    const clients = newClient != null ? [newClient, ...props.allClients] : props.allClients;
    const isNewClient = props.client.clientId == null || props.client.clientId === newClientId;

    const options: DropdownItemProps[] = [
        { text: formatMessage(m.accountTypeLead), value: ClientType.Lead },
        { text: formatMessage(m.accountTypeClient), value: ClientType.Client }
    ];

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        const fieldId = event.currentTarget.id;
        props.onChange(fieldId, data.value);
    };

    const handleDropdownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const fieldId = event.currentTarget.parentElement?.parentElement?.id || undefined;
        if (fieldId != null) {
            props.onChange(fieldId, data.value);
        }
    };

    const selectExistingClient = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const clientId = data.value != null ? Number(data.value) : undefined;
        props.onClientChange(clientId);
    };

    const addClient = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        setNewClient({
            id: newClientId,
            legalName: data.value != null ? data.value.toString() : '',
            displayName: data.value != null ? data.value.toString() : '',
            ownerEmail: props.client.ownerEmail || '',
            createdById: '',
            createdOn: new Date(),
            ownedById: '',
            isActive: true,
            type: props.client.type,
            branches: [],
            sets: []
        });

        props.onClientChange(newClientId, data.value?.toString());
    };

    return (
        <Form error={!props.validationResult.isValid}>
            <Form.Field error={props.validationResult.isFieldInvalid(nameof<ClientEdit>('clientId'))} style={{ marginBottom: '.5rem' }}>
                <ClientPicker
                    clients={clients}
                    loading={props.loading}
                    disabled={props.loading}
                    allowAdditions
                    additionLabel={formatMessage(m.addLabel)}
                    value={props.client.clientId}
                    onAddItem={addClient}
                    onChange={selectExistingClient}
                    placeholder={formatMessage(m.clientNamePlaceholder)}
                />
            </Form.Field>
            <Divider fitted />

            <Form.Field style={{ marginTop: '.25rem' }}>
                <span>{formatMessage(m.accountTypeLabel)}</span>
                <Dropdown
                    id={nameof<ClientEdit>('type')}
                    inline
                    disabled={!isNewClient}
                    options={options}
                    value={props.client.type}
                    style={{ marginLeft: '.5rem' }}
                    onChange={handleDropdownChange}
                />
            </Form.Field>

            <Form.Input
                id={nameof<ClientEdit>('ownerEmail')}
                inputMode="email"
                required
                error={props.validationResult.isFieldInvalid(nameof<ClientEdit>('ownerEmail'))}
                disabled={!isNewClient}
                label={formatMessage(m.ownerEmailLabel)}
                value={props.client.ownerEmail || ''}
                onChange={handleInputChange}
            />
        </Form>
    );
};