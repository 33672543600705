import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { Icon, Segment, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import styles from './JaymarStudioSection.module.css';

interface OwnProps {
    clientId: number;
    branchId: number;
    hasSpaceReservedForJaymar: boolean;
}

export type JaymarStudioSectionProps =
    & OwnProps;

const m = defineMessages({
    modifyProperties: { id: 'ClientDetailsPage.modifyStudioProperties', defaultMessage: 'Modify properties' },
    hasSpaceReserved: { id: 'JaymarStudioSection.hasSpaceReserved', defaultMessage: 'Space reserved for Jaymar in the store.' },
    noSpaceReserved: { id: 'JaymarStudioSection.noSpaceReserved', defaultMessage: 'No space reserved for Jaymar.' },
});

export const JaymarStudioSection: React.FC<JaymarStudioSectionProps> = (props) => {
    const { formatMessage } = useIntl();
    const iconColor: SemanticCOLORS | undefined = props.hasSpaceReservedForJaymar ? 'green' : 'grey';
    const iconName: SemanticICONS = props.hasSpaceReservedForJaymar ? 'check circle' : 'times circle';
    const message = props.hasSpaceReservedForJaymar ? formatMessage(m.hasSpaceReserved) : formatMessage(m.noSpaceReserved);

    return (
        <Segment className={styles.studioSection}>
            <p>
                <Icon name={iconName} color={iconColor} />
                <span>{message}</span>
            </p>
            <div style={{ fontSize: '1rem', marginTop: '.5rem' }}>
                <NavLink to={`/clients/${props.clientId}/branches/${props.branchId}/studio`}>
                    {formatMessage(m.modifyProperties)}
                </NavLink>
            </div>
        </Segment>
    );
};