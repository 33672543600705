import React from 'react';
import { Segment, SegmentProps, Icon, Header } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import styles from './NoContentPlaceholder.module.css';

interface OwnProps {
    title: string;
    subtitle?: string;
    icon?: SemanticICONS
}

export type NoContentPlaceholderProps =
    & OwnProps
    & SegmentProps;

export const NoContentPlaceholder: React.FC<NoContentPlaceholderProps> = (props) => {
    const { title, subtitle, icon, ...segmentProps } = props;

    return (
        <Segment className={styles.container} basic {...segmentProps}>
            <Header icon style={{ margin: 0 }}>
                {icon && <Icon className={styles.icon} name={icon} />}
                <span className={styles.title}>{title}</span>
                {subtitle && <Header.Subheader className={styles.subtitle}>{subtitle}</Header.Subheader>}
            </Header>
        </Segment>
    );
};