import orderBy from "lodash/orderBy";
import React, { useMemo } from "react";
import { Table } from "semantic-ui-react";
import { Order } from "../../../orders";
import { TableHeaders } from "./TableHeaders";
import { TableRow } from "./TableRow";

interface OrdersTableProps {
    orders: Order[];
}

export const OrdersTable: React.FC<OrdersTableProps> = ({ orders }) => {
    const sortedOrders = useMemo(() => {
        return orderBy(orders, (order) => new Date(order.orderedOn));
    }, [orders]);

    return (
        <Table>
            <TableHeaders />
            <Table.Body>
                {sortedOrders.map((order) => (
                    <TableRow key={order.id} order={order} />
                ))}
            </Table.Body>
        </Table>
    );
};
