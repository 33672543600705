import React from 'react';
import { Divider } from 'semantic-ui-react';
import styles from './PageHeader.module.css';

export interface PageHeaderProps {
    title: string;
    subtitle: string;
    backLink?: React.ReactNode;
}

export const PageHeader: React.FC<PageHeaderProps> = (props) => {
    return (
        <>
            <h2 className={styles.title}>{props.title}</h2>
            <Divider fitted />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <p className={styles.subtitle}>{props.subtitle}</p>
                {props.backLink}
            </div>
        </>
    );
};