import React from 'react';
import _ from 'lodash';
import { Segment, List } from 'semantic-ui-react';
import { formatMoney } from '../../../../utils/formatMoney';
import { NoContentPlaceholder } from '../../../../components/NoContentPlaceholder';
import { defineMessages, useIntl } from 'react-intl';
import { Goal } from '../../types';

const m = defineMessages({
    title: { id: 'GoalsList.title', defaultMessage: 'All goals for this client' },
    noContentTitle: { id: 'GoalsList.noContentTitle', defaultMessage: 'No goals defined' },
    noContentSubtitle: { id: 'GoalsList.noContentSubtitle', defaultMessage: 'There are no goals currently defined for this client. You can add a new one above.' }
});

interface OwnProps {
    goals: Goal[];
    disabled?: boolean;
    loading?: boolean;
}

export type GoalsListProps =
    & OwnProps;

export const GoalsList: React.FC<GoalsListProps> = (props) => {
    const { formatMessage } = useIntl();

    const hasGoals = props.goals.length > 0;
    const renderGoal = (goal: Goal) => (
        <List.Item key={`${goal.clientId}-${goal.year}`}>
            <List.Content floated="right">{formatMoney(goal.target)}</List.Content>
            <List.Content>{goal.year}</List.Content>
        </List.Item>
    );

    return (
        <>
            <p style={{ fontWeight: 'bold', marginBottom: '.3rem', fontSize: '1rem' }}>
                {formatMessage(m.title)}
            </p>

            <Segment loading={props.loading} disabled={props.disabled} style={{ margin: 0 }}>
                {hasGoals &&
                    <List divided relaxed="very" verticalAlign="middle">
                        {_(props.goals).sortBy(x => x.year).reverse().map(renderGoal).value()}
                    </List>
                }

                {!hasGoals &&
                    <NoContentPlaceholder
                        title={formatMessage(m.noContentTitle)}
                        subtitle={formatMessage(m.noContentSubtitle)}

                    />
                }
            </Segment>
        </>
    );
};