import React from "react";
import orderBy from "lodash/orderBy";
import { Loader, Table, TableCell } from "semantic-ui-react";
import { SalesReportData } from "../../hooks/useSalesReportData";
import deburr from "lodash/deburr";
import { TableHeaders } from "./TableHeaders";
import { TableClientRows } from "./TableClientRows";
import { SalesTotalRows } from "./SalesTotalRows";

interface SalesReportTableProps {
    salesReportData: SalesReportData;
    isLoading: boolean;
}

export const SalesReportTable: React.FC<SalesReportTableProps> = ({
    salesReportData,
    isLoading,
}) => {
    const orderedClientCodes = orderBy(salesReportData.clientCodes, (clientCode) =>
        deburr((salesReportData.clientNameByClientCode[clientCode] || "").toLowerCase())
    );

    return (
        <Table compact celled structured unstackable style={{ fontSize: "0.7rem" }}>
            <TableHeaders reportMonthsInOrder={salesReportData.monthsInOrder} />
            <Table.Body>
                {isLoading && (
                    <Table.Row>
                        <TableCell colSpan={16} textAlign="center">
                            <Loader style={{ margin: "1rem" }} inline active />
                        </TableCell>
                    </Table.Row>
                )}
                {!isLoading &&
                    orderedClientCodes.map((clientCode) => (
                        <TableClientRows
                            key={clientCode}
                            clientCode={clientCode}
                            salesReportData={salesReportData}
                        />
                    ))}
                {!isLoading && <SalesTotalRows salesReportData={salesReportData} />}
            </Table.Body>
        </Table>
    );
};
