import dayjs from "dayjs";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Table } from "semantic-ui-react";

const m = defineMessages({
    client: { id: 'SalesReportTable.Headers.Client', defaultMessage: 'Client' },
    period: { id: 'SalesReportTable.Headers.Period', defaultMessage: 'Period' },
    totals: { id: 'SalesReportTable.Headers.Totals', defaultMessage: 'Totals' },
});

interface TableHeadersProps {
    reportMonthsInOrder: number[];
}

export const TableHeaders: React.FC<TableHeadersProps> = ({ reportMonthsInOrder }) => {
    const { formatMessage } = useIntl();

    return (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>{formatMessage(m.client)}</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>{formatMessage(m.period)}</Table.HeaderCell>
                {reportMonthsInOrder.map((month) => (
                    <Table.HeaderCell key={month} textAlign="right">
                        {dayjs().month(month).format("MMM")}
                    </Table.HeaderCell>
                ))}
                <Table.HeaderCell textAlign="right">{formatMessage(m.totals)}</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    );
};
