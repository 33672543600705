import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Label, Icon } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { nameof } from '../../../../utils/nameof';
import { useSelector } from 'react-redux';
import { getClientById } from '../../../clients/selectors';
import { ApplicationState } from '../../../../store/store';
import { Client } from '../../../clients/types';
import { getRepresentativeById, getRepresentativeDisplayName } from '../../../representatives/selectors';
import { User } from '../../../account/types';
import { OrdersFilters } from '../../../orders';

const m = defineMessages({
    filtersLabel: { id: 'GoalsActiveFilters.filtersLabel', defaultMessage: 'Filters:' }
});

interface OwnProps {
    onClear: (filterId: string) => void;
}

export type GoalsActiveFiltersProps =
    & OwnProps;

export const GoalsActiveFilters: React.FC<GoalsActiveFiltersProps> = (props) => {
    const { formatMessage } = useIntl();
    const location = useLocation();
    const filters = queryString.parse(location.search) as OrdersFilters;
    const hasFilters = filters.clientId != null || filters.month != null || filters.representativeId != null || filters.currency != null;

    const client = useSelector<ApplicationState, Client | undefined>(state => getClientById(state, Number(filters.clientId)));
    const representative = useSelector<ApplicationState, User | undefined>(state => getRepresentativeById(state, filters.representativeId || ''));

    if (!hasFilters) {
        return <></>;
    }

    const renderFilter = (id: string, label: string) => {
        return (
            <Label size="tiny" as="a" onClick={() => props.onClear(id)} style={{ marginBottom: '.25rem' }}>
                {label}
                <Icon name="delete" />
            </Label>
        );
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
            <p style={{ margin: '0 0.5rem 0 0', fontWeight: 'bold' }}>{formatMessage(m.filtersLabel)}</p>
            {filters.month &&
                renderFilter(nameof<OrdersFilters>('month'), _.capitalize(dayjs(new Date(2020, Number(filters.month), 1)).format('MMMM')))
            }

            {representative != null &&
                renderFilter(nameof<OrdersFilters>('representativeId'), getRepresentativeDisplayName(representative))
            }

            {client != null &&
                renderFilter(nameof<OrdersFilters>('clientId'), client.displayName)
            }

            {filters.currency != null &&
                renderFilter(nameof<OrdersFilters>('currency'), filters.currency)
            }
        </div>
    );
};