import React from "react";
import { TableCellProps } from "semantic-ui-react";
import { getUrlForClientOrders } from "../../utils";
import { AmountCell } from "../AmountCell";

interface PeriodTotalCellProps extends TableCellProps {
    clientCode: string;
    total: number;
    startDate: Date;
    endDate: Date;
}

export const PeriodTotalCell: React.FC<PeriodTotalCellProps> = ({
    clientCode,
    total,
    startDate,
    endDate,
    ...tableCellProps
}) => {
    const url = getUrlForClientOrders(clientCode, startDate, endDate);

    return (
        <AmountCell
            {...tableCellProps}
            amount={total}
            style={{ fontWeight: "bold", ...tableCellProps.style }}
            linkTo={url}
        />
    );
};
