import React from "react";
import { Route, Routes } from "react-router-dom";
import { HomePage } from "./modules/home/views";
import { LoginPage } from "./modules/account/view/LoginPage";
import { LogoutPage } from "./modules/account/view/LogoutPage";
import { AppSplashScreen } from "./components/AppSplashScreen";
import { ForbiddenPage, NotFoundPage } from "./modules/errors";
import { ClientDetailsPage, ClientsPage, ClientSetsPage, CreateClientPage } from "./modules/clients/views";
import { CreateActivityPage, ActivityDetailsPage, UpdateActivityPage, ActivitiesPage } from "./modules/activities/views";
import { GoalsPage, GoalsFiltersPage, CreateGoalPage } from "./modules/goals/views";
import { JaymarStudioPage } from "./modules/clients/views/JaymarStudioPage";
import { RequireAuthentification } from "./components/RequireAuthentication";
import { UnauthorizedPage } from "./modules/errors";
import { OrdersReportPage } from "./modules/reports/views/OrdersReportPage";
import { OrdersReportFiltersPage } from "./modules/reports/views/OrdersReportFiltersPage";
import { SalesReportPage } from "./modules/reports/views/SalesReportPage";
import { SalesReportFiltersPage } from "./modules/reports/views/SalesReportFiltersPage";
import { ClientOrdersReportPage } from "./modules/reports/views/ClientOrdersReportPage";
import { ClientInvoicesReportPage } from "./modules/reports/views/ClientInvoicesReportPage";
import { ServerErrorPage } from "./modules/errors/ServerErrorPage";

export const App: React.FC = () => {
    return (
        <AppSplashScreen>
            <Routes>
                <Route element={<RequireAuthentification />}>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/clients/:clientId/branches/:branchId" element={<ClientDetailsPage />} />
                    <Route path="/clients/:clientId/branches/:branchId/activities/:id/edit" element={<UpdateActivityPage />} />
                    <Route path="/clients/:clientId/branches/:branchId/activities/:id" element={<ActivityDetailsPage />} />
                    <Route path="/clients/:clientId/branches/:branchId/activities" element={<ActivitiesPage />} />
                    <Route path="/clients/:clientId/branches/:branchId/sets" element={<ClientSetsPage />} />
                    <Route path="/clients/:clientId/branches/:branchId/studio" element={<JaymarStudioPage />} />
                    <Route path="/clients" element={<ClientsPage />} />
                    <Route path="/leads" element={<ClientsPage />} />
                    <Route path="/leads/new" element={<CreateClientPage />} />
                    <Route path="/clients/new" element={<CreateClientPage />} />
                    <Route path="/activities/new" element={<CreateActivityPage />} />
                    <Route path="/activities/new/:clientId/:branchId" element={<CreateActivityPage />} />
                    <Route path="/goals" element={<GoalsPage />} />
                    <Route path="/goals/filters" element={<GoalsFiltersPage />} />
                    <Route path="/goals/new" element={<CreateGoalPage />} />
                    <Route path="/reports/orders" element={<OrdersReportPage />} />
                    <Route path="/reports/orders/filters" element={<OrdersReportFiltersPage />} />
                    <Route path="/reports/orders/clients/:clientCode" element={<ClientOrdersReportPage />} />
                    <Route path="/reports/sales" element={<SalesReportPage />} />
                    <Route path="/reports/sales/filters" element={<SalesReportFiltersPage />} />
                    <Route path="/reports/sales/clients/:clientCode" element={<ClientInvoicesReportPage />} />
                    <Route path="/account/logout" element={<LogoutPage />} />
                </Route>

                <Route path="/account/login" element={<LoginPage />} />
                <Route path="/error/401" element={<UnauthorizedPage />} />
                <Route path="/error/403" element={<ForbiddenPage />} />
                <Route path="/error/500" element={<ServerErrorPage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </AppSplashScreen>
    );
};
