import React from "react";
import { Container } from "semantic-ui-react";
import BasicPageLayout from "../../../components/layouts/BasicPageLayout";

interface OrdersReportLayoutProps {
    children?: React.ReactNode;
}

export const OrdersReportLayout: React.FC<OrdersReportLayoutProps> = (props) => {
    return (
        <BasicPageLayout>
            <div style={{ height: "100%", overflow: "auto" }}>
                <Container>{props.children}</Container>
            </div>
        </BasicPageLayout>
    );
};
