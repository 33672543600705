import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { ClientLayout } from './components/ClientLayout';
import { useDispatch } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { PageHeader } from '../../../components/PageHeader';
import * as LayoutActions from '../../layout/actions';
import * as api from '../api';
import { useNavigate, useParams } from 'react-router-dom';
import { Client, Set } from '../types';
import { Segment, Input, InputOnChangeData, Divider } from 'semantic-ui-react';
import { FloatingActionButton, FloatingActionButtons } from '../../../components/floatingActionButton';
import styles from './ClientSetsPage.module.css';
import { SetList } from './components/SetList';
import { SelectedSetsList } from './components/SelectedSetsList';

const m = defineMessages({
    pageTitle: { id: 'ClientSetsPage.pageTitle', defaultMessage: 'Sets management' },
    backLinkTitle: { id: 'ClientSetsPage.backLinkTitle', defaultMessage: 'Return to the profile' },
    allBranches: { id: 'ClientSetsPage.allBranches', defaultMessage: 'All branches' },
    searchPlaceholder: { id: 'ClientSetsPage.searchPlaceholder', defaultMessage: 'Product id or name (e.g. 22111)' },
    noResults: { id: 'ClientSetsPage.noResults', defaultMessage: 'No results were found.' },
    clearSearch: { id: 'ClientSetsPage.clearSearch', defaultMessage: 'Clear query' },
});

const maxResults = 25;

export const ClientSetsPage: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const { clientId, branchId } = useParams<any>();
    const [isSaving, setSaving] = useState(false);
    const [isClientLoading, setClientLoading] = useState(false);
    const [client, setClient] = useState<Client | undefined>(undefined);
    const [areSetsLoading, setSetsLoading] = useState(false);
    const [allSets, setSets] = useState<Set[]>([]);
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState<Set[]>([]);
    const [selectedResults, setSelectedResults] = useState<Set[]>([]);
    const hasSearchResults = isClientLoading || searchResults.length > 0;

    useEffect(() => {
        dispatch(LayoutActions.setPageTitle(formatMessage(m.pageTitle)));
    }, [dispatch, formatMessage]);

    useEffect(() => {
        const id = Number(clientId);

        if (!isNaN(id)) {
            setClientLoading(true);
            api.getClient(id)
                .then(result => {
                    setClient(result);
                    setSelectedResults(result.sets || []);
                })
                .finally(() => setClientLoading(false));

            setSetsLoading(true);
            api.getSets()
                .then(result => setSets(result))
                .finally(() => setSetsLoading(false));
        }

    }, [clientId]);

    useEffect(() => {
        if (_.isEmpty(searchText)) {
            setSearchResults(_(allSets).orderBy(x => x.setCode).take(maxResults).value());
            return;
        }

        const deburrSearchText = _.deburr(searchText).toLocaleLowerCase();
        const results = _(allSets)
            .filter(x => x.name.toLocaleLowerCase().includes(deburrSearchText) || x.setCode.toLocaleLowerCase().includes(deburrSearchText))
            .take(maxResults)
            .value();

        setSearchResults(results);

    }, [allSets, searchText]);

    const clearSearch = () => setSearchText('');
    const handleSearch = (_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setSearchText(data.value);

    const unselectSet = (set: Set) => setSelectedResults(selectedResults.filter(x => x.id !== set.id));
    const selectSet = (set: Set) => {
        const isAlreadyAdded = selectedResults.find(x => x.id === set.id) != null;
        if (!isAlreadyAdded) {
            setSelectedResults([...selectedResults, set]);
        }
    };

    const navigateBack = () => navigate(`/clients/${clientId}/branches/${branchId}`);
    const saveSets = () => {
        setSaving(true);

        api.updateClientSets(Number(clientId), selectedResults.map(x => x.id))
            .then(navigateBack)
            .finally(() => setSaving(false));
    };

    return (
        <ClientLayout>
            <PageHeader
                title={client?.displayName || ''}
                subtitle={formatMessage(m.allBranches)}
            />
            <Segment style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
                <div>
                    <Input
                        fluid
                        inputMode="search"
                        disabled={areSetsLoading || isClientLoading || isSaving}
                        loading={areSetsLoading || isClientLoading}
                        icon="search"
                        value={searchText}
                        placeholder={formatMessage(m.searchPlaceholder)}
                        onChange={handleSearch}
                    />
                    <Divider />
                </div>
                <div className={styles.searchResults}>
                    {hasSearchResults &&
                        <SetList
                            disabled={isSaving}
                            loading={areSetsLoading || isClientLoading}
                            sets={searchResults}
                            selectedSets={selectedResults}
                            onClick={selectSet}
                        />
                    }
                    {!hasSearchResults &&
                        <div className={styles.noSearchResults}>
                            <p>{formatMessage(m.noResults)}</p>
                            <a onClick={clearSearch}>{formatMessage(m.clearSearch)}</a>
                        </div>
                    }
                </div>

                <Divider />
                <div className={styles.mappedResults}>
                    <SelectedSetsList
                        loading={isClientLoading}
                        disabled={isSaving}
                        selectedSets={selectedResults}
                        onUnselect={unselectSet}
                    />
                </div>
            </Segment>

            <FloatingActionButtons>
                <FloatingActionButton
                    icon="cancel"
                    onClick={navigateBack}
                />

                <FloatingActionButton
                    disabled={isSaving}
                    loading={isSaving}
                    primary
                    icon="save"
                    onClick={saveSets}
                />
            </FloatingActionButtons>
        </ClientLayout>
    );
};