import React from 'react';
import dayjs from 'dayjs';
import { defineMessages, useIntl } from 'react-intl';
import styles from './HomeHeader.module.css';

interface HomeHeaderProps {
    name: string;
    style?: React.CSSProperties;
}

const m = defineMessages({
    hello: {id: 'HomeHeader.Hello', defaultMessage: 'Hello {username}'},
});


export const HomeHeader: React.FunctionComponent<HomeHeaderProps> = (props) => {
    const { formatMessage } = useIntl();
    return (
        <div style={props.style}>
            <p className={styles.greetings}>
                {formatMessage(m.hello, { username: props.name })}
            </p>

            <p className={styles.title}>{dayjs().format('dddd')}</p>
            <p className={styles.subtitle}>{dayjs().format('LL')}</p>
        </div>
    );
};
