import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as AccountActions from '../modules/account/actions';
import { Loader } from 'semantic-ui-react';
import { isSessionLoaded } from '../modules/account/selectors';

const m = defineMessages({
    loading: { id: 'AppSplashScreen.loading', defaultMessage: 'Loading...' }
});

interface AppSplashScreenProps {
    children?: React.ReactNode;
  }

export const AppSplashScreen: React.FC<AppSplashScreenProps> = (props) => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const loaded = useSelector(isSessionLoaded);

    useEffect(() => {
        dispatch(AccountActions.retrieveUser());
    }, [dispatch]);

    if (loaded) {
        return <>{props.children}</>;
    }

    return (
        <div>
            <Loader active>{formatMessage(m.loading)}</Loader>
        </div>
    );
};