import { Activity } from './types';
import { get, postMultiPart } from '../../utils/api';
import { ActivityEdit, UpdateActivityEdit } from './models';
import { resizeImage } from '../../utils/ImageUtils';

const endpoint = '/api/activities';

export async function getActivitiesByBranch(branchId: number): Promise<Activity[]> {
    return get<Activity[]>(`${endpoint}?branchId=${branchId}`);
}

export async function getActivityById(id: number): Promise<Activity> {
    return get<Activity>(`${endpoint}/${id}`);
}

export async function loadRecentActivities(): Promise<Activity[]> {
    return get<Activity[]>(`${endpoint}/recent`);
}

export async function createActivity(activity: ActivityEdit): Promise<Activity> {
    activity.attachments = await resizeActivityAttachments(activity);

    return postMultiPart<Activity>(
        `${endpoint}/clients/${activity.clientId}/branches/${activity.branchId}`,
        activity);
}

export async function updateActivity(activityId: number, activity: UpdateActivityEdit): Promise<Activity> {
    activity.attachments = await resizeActivityAttachments(activity);

    return postMultiPart<Activity>(
        `${endpoint}/${activityId}`,
        activity);
}

async function resizeActivityAttachments(activity: ActivityEdit): Promise<Blob[]> {
    if (activity.attachments.length > 0) {
        const files = activity.attachments.filter(x => x instanceof File) as File[];
        const resizedAttachments = await Promise.all(files.map(async x => await resizeImage({ file: x, maxSize: 1024 })));
        return resizedAttachments;
    }

    return [];
}