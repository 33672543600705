import React from 'react';
import { Tab, TabProps } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './ClientTab.module.css';

const m = defineMessages({
    clientsTab: { id: 'ClientTab.clients', defaultMessage: 'Clients' },
    leadsTab: { id: 'ClientTab.leads', defaultMessage: 'Leads' }
});


export const ClientTab: React.FC = () => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const activeTabIndex = location.pathname.endsWith('leads') ? 1 : 0;

    const changeTab = (_event: React.MouseEvent<HTMLDivElement>, data: TabProps) => {
        switch (data.activeIndex) {
            case 1:
                navigate('/leads');
                break;

            default:
                navigate('/clients');
                break;
        }
    };

    return (
        <Tab
            className={styles.tab}
            activeIndex={activeTabIndex}
            menu={{ secondary: true, pointing: true }}
            panes={[
                { menuItem: formatMessage(m.clientsTab) },
                { menuItem: formatMessage(m.leadsTab) }
            ]}
            onTabChange={changeTab}
        />
    );
};