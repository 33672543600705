import _ from 'lodash';
import { ActivityType } from '../types';
import { ValidationResult } from '../../../utils/ValidationResult';
import { nameof } from '../../../utils/nameof';
import { defineMessages } from 'react-intl';

const m = defineMessages({
    clientRequired: { id: 'ActivityEdit.clientRequired', defaultMessage: 'You must select a client associated with this activity.' },
    branchRequired: { id: 'ActivityEdit.branchRequired', defaultMessage: 'You must select a branch associated with this activity.' },
    noteRequired: { id: 'ActivityEdit.noteRequired', defaultMessage: 'You must enter a note.' }
});

export class ActivityEdit {
    public type: ActivityType = ActivityType.Note;
    public clientId: number | undefined;
    public branchId: number | undefined;
    public note: string | undefined;
    public attachments: (File | Blob)[] = [];

    public constructor(init?: Partial<ActivityEdit>) {
        Object.assign(this, init);
    }

    public validate(): ValidationResult {
        const result = new ValidationResult();

        if (this.clientId == null) {
            result.isValid = false;
            result.errorFields.push(nameof<ActivityEdit>('clientId'));
            result.errorMessages.push(m.clientRequired);
        }

        if (this.branchId == null) {
            result.isValid = false;
            result.errorFields.push(nameof<ActivityEdit>('branchId'));
            result.errorMessages.push(m.branchRequired);
        }

        if (_.isEmpty(this.note?.trim())) {
            result.isValid = false;
            result.errorFields.push(nameof<ActivityEdit>('note'));
            result.errorMessages.push(m.noteRequired);
        }

        return result;
    }
}