import React from 'react';
import { Divider } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';

export interface FilterSectionProps {
    title: string;
    onClear?: () => void;
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

const m = defineMessages({
    clear: { id: 'FilterSection.clear', defaultMessage: 'Clear' }
});

export const FilterSection: React.FC<FilterSectionProps> = (props) => {
    const { formatMessage } = useIntl();
    return (
        <div style={props.style}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <p style={{ flexGrow: 1, fontWeight: 'bold', margin: 0 }}>{props.title}</p>
                {props.onClear && <a onClick={props.onClear} style={{ cursor: 'pointer' }}>{formatMessage(m.clear)}</a>}
            </div>
            <Divider fitted style={{ margin: '.5rem 0' }} />
            {props.children}
        </div>
    );
};