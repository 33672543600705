import { ApplicationState } from "../../store/store";
import _ from "lodash";
import { Order, ExchangeRate } from "../orders";
import { Goal } from "./types";

export const getGoalById = (state: ApplicationState, id: number) => state.goals.byId[id];

export const getGoalsTotalForYear = (state: ApplicationState, year: number, clientId?: number): number => {
    const yearlyGoals = state.goals.byYear[year];
    const clientGoals = clientId != null ? state.goals.byClientId[clientId] || [] : undefined;
    const goalsIds = [yearlyGoals, clientGoals].filter(x => x != null) as number[][];
    const goals = _.intersection(...goalsIds).map(x => getGoalById(state, x)).filter(x => x != null);

    const totalYearlyGoals = _(goals).sumBy(x => x.target);
    return totalYearlyGoals;
};

export const getClientGoals = (state: ApplicationState, clientId: number): Goal[] => {
    const clientGoalsIds = state.goals.byClientId[clientId] || [];
    const goals = clientGoalsIds.map(x => getGoalById(state, x)).filter(x => x != null);
    return goals;
};

export const areGoalsLoading = (state: ApplicationState): boolean => state.goals.loading;

export const isSavingGoal = (state: ApplicationState): boolean => state.goals.saving;

export const calculateOrderPrice = (order: Order, convertToCad: boolean, defaultExchangeRates: ExchangeRate[]) => {
    if (_.isEmpty(order.invoices)) {
        // The exchange rate is stored in the invoice, so we'll need to use the default exchange rate.
        const exchangeRateToCad = convertToCad ? defaultExchangeRates.find(x => x.currency === order.currency)?.rate || 1.0 : 1.0;
        return order.price * exchangeRateToCad;
    }

    // We use the final invoice to calculate the price instead of the order price, since rebates may have been
    // applied. Furthermore, we make sure to take into consideration the invoice exchange rate if we need to convert
    // the order's price into Canadian dollars.
    return _.sum(order.invoices.map(x => (x.salesAmount - x.rebateAmount) * (convertToCad ? x.exchangeRate : 1.0)));
};