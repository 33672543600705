import {
    GoalActionTypes,
    LOAD_GOALS_REQUEST,
    LOAD_GOALS_SUCCESS,
    LOAD_GOALS_FAILURE,
    CREATE_OR_UPDATE_GOAL_SUCCESS,
    CREATE_OR_UPDATE_GOAL_FAILURE,
    GoalsState,
    CREATE_OR_UPDATE_GOAL_REQUEST,
} from "./types";
import { Reducer } from "redux";
import _ from "lodash";

const initialState: GoalsState = {
    byId: {},
    byClientId: {},
    byYear: {},
    allIds: [],
    loading: false,
    saving: false,
};

export const GoalsReducer: Reducer<GoalsState, GoalActionTypes> = (state = initialState, action: GoalActionTypes) => {
    switch (action.type) {
        case LOAD_GOALS_REQUEST: {
            return {
                ...state,
                loading: true
            };
        }

        case LOAD_GOALS_SUCCESS: {
            const byId = Object.assign({}, ...action.payload.map(x => ({ [x.id]: x })));

            const byClientId = Object.assign({}, ..._(action.payload)
                .groupBy(x => x.clientId)
                .map((group, key) => ({ [key]: group.map(x => x.id) }))
                .value());

            const byYear = Object.assign({}, ..._(action.payload)
                .groupBy(x => x.year)
                .map((group, key) => ({ [key]: group.map(x => x.id) }))
                .value());

            const allIds = action.payload.map(x => x.id);

            return {
                ...state,
                byId,
                byClientId,
                byYear,
                allIds,
                loading: false
            };
        }

        case LOAD_GOALS_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case CREATE_OR_UPDATE_GOAL_REQUEST: {
            return {
                ...state,
                saving: true
            };
        }

        case CREATE_OR_UPDATE_GOAL_SUCCESS: {
            const byId = _.merge({}, state.byId, ({ [action.payload.id]: action.payload }));
            const allIds = _.union(state.allIds, [action.payload.id]);

            return {
                ...state,
                byId,
                allIds,
                saving: false,
            };
        }

        case CREATE_OR_UPDATE_GOAL_FAILURE: {
            return {
                ...state,
                saving: false,
            };
        }

        default:
            return state;
    }
};