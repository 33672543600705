import { fork } from 'redux-saga/effects';
import accountSagas from '../modules/account/sagas';
import clientsSagas from '../modules/clients/sagas';
import goalsSaga from '../modules/goals/sagas';
import representativesSaga from '../modules/representatives/sagas';
import ordersSagas from '../modules/orders/sagas';
import applicationSagas from '../modules/application/sagas';

export default function* rootSaga() {
    yield fork(accountSagas);
    yield fork(applicationSagas);
    yield fork(clientsSagas);
    yield fork(goalsSaga);
    yield fork(ordersSagas);
    yield fork(representativesSaga);
}