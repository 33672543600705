import { useQuery } from "@tanstack/react-query";
import { OrdersFilters } from "../../orders";
import { getOrdersStat, GetOrdersStatFilters } from "../api";
import { getFilterPickCurrency } from "../utils";

export const useReportOrders = (startDate: Date, endDate: Date, filters: OrdersFilters) => {
    const queryFilters: GetOrdersStatFilters = {
        clientId: filters.clientId,
        countryCode: filters.countryCode,
        provinceCode: filters.provinceCode,
        currency: getFilterPickCurrency(filters.currency),
    };

    const { data: ordersStat, isFetching: isLoading } = useQuery(
        [
            "ordersstat",
            queryFilters.clientId,
            queryFilters.countryCode,
            queryFilters.provinceCode,
            queryFilters.currency,
        ],
        async () => getOrdersStat(startDate, endDate, queryFilters),
        {
            staleTime: 15 * 60 * 1000,
        }
    );

    return { ordersStat, isLoading };
};
