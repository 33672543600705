import React from "react";
import { Branch } from "../../types";
import { Map, Marker } from "../../../../components/GoogleMap";
import { useResponsive } from "../../../../hooks";

interface OwnProps {
  branches: Branch[];
  defaultCenter?: google.maps.LatLngLiteral;
}

export type BranchMapProps =
  & OwnProps;

export const BranchMap: React.FC<BranchMapProps> = (props) => {
  const { isMobile } = useResponsive();
  const branches = props.branches.map((branch) => ({
    id: branch.id,
    coordinates: new google.maps.LatLng(branch.coordinates.y, branch.coordinates.x)
  }));

  const defaultCenter = props.defaultCenter ?? { lat: 45.702556, lng: -73.62413 };

    return (
      <Map
        center={defaultCenter}
        zoom={8}
        gestureHandling={isMobile ? 'cooperative': 'auto' }
        style={{ flexGrow: '1', height: '100%' }}
        mapTypeControlOptions = {{ position: 10 }}
      >
        {branches.map((branch) => (
          <Marker key={branch.id} position={branch.coordinates} />
        ))}
      </Map>
    );
};