import React from "react";
import { defineMessages, useIntl } from "react-intl";

const m = defineMessages({
    creating: { id: "OrderStatus.Creating", defaultMessage: "Creating" },
    verified: { id: "OrderStatus.Verified", defaultMessage: "Verified" },
    inProduction: { id: "OrderStatus.InProduction", defaultMessage: "In production" },
    partiallyCompleted: {
        id: "OrderStatus.PartiallyCompleted",
        defaultMessage: "Partially completed",
    },
    completed: { id: "OrderStatus.Completed", defaultMessage: "Completed" },
    loaded: { id: "OrderStatus.Loaded", defaultMessage: "Loaded" },
    partiallyLoaded: { id: "OrderStatus.PartiallyLoaded", defaultMessage: "Partially loaded" },
    shipped: { id: "OrderStatus.Shipped", defaultMessage: "Shipped" },
    billed: { id: "OrderStatus.Billed", defaultMessage: "Billed" },
    cancelled: { id: "OrderStatus.Cancelled", defaultMessage: "Cancelled" },
});

interface OrderStatusProps {
    status: number;
}

export const OrderStatus: React.FC<OrderStatusProps> = ({ status }) => {
    const { formatMessage } = useIntl();

    const statusNames: Record<number, string> = {
        0: formatMessage(m.creating),
        1: formatMessage(m.verified),
        2: formatMessage(m.inProduction),
        3: formatMessage(m.partiallyCompleted),
        4: formatMessage(m.completed),
        5: formatMessage(m.partiallyLoaded),
        6: formatMessage(m.loaded),
        7: formatMessage(m.shipped),
        8: formatMessage(m.billed),
        9: formatMessage(m.cancelled),
    };

    return <>{statusNames[status] || ""}</>;
};
