import { SupportedLocales } from '../../configureI18n';
import { SetLocaleAction, SetPageTitleAction, SET_LOCALE, SET_PAGE_TITLE } from './types';

export function setLocale(locale: SupportedLocales): SetLocaleAction {
    return {
        type: SET_LOCALE,
        payload: { locale }
    };
}

export function setPageTitle(title: string): SetPageTitleAction {
    return {
        type: SET_PAGE_TITLE,
        payload: { title }
    };
}