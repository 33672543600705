import { StyleDictionary, TableLayout } from "pdfmake/interfaces";

export const styles: StyleDictionary = {
    documentTitle: {
        fontSize: 12,
        bold: true,
        alignment: "center",
    },
    monthHeader: {
        alignment: "right",
    },
    totalHeader: {
        alignment: "right",
    },
    periodAmount: {
        alignment: "right",
    },
    periodRatio: {
        alignment: "right",
        bold: true,
    },
    totalAmount: {
        alignment: "right",
        bold: true,
    },
    totalRatio: {
        alignment: "right",
        bold: true,
    },
};

export const mainTableLayout: TableLayout = {
    hLineWidth: (i: number) => {
        return (i - 1) % 3 === 0 ? 2 : 0;
    },
    vLineWidth: () => 0,
    hLineColor: () => "black",
    vLineColor: () => "black",
    paddingTop: () => 2,
    paddingBottom: () => 2,
};