import React, { useState } from 'react';
import _ from 'lodash';
import { Segment, Form, Input, InputOnChangeData, DropdownProps } from 'semantic-ui-react';
import dayjs from 'dayjs';
import { YearPicker } from './YearPicker';
import styles from './GoalForm.module.css';
import { defineMessages, useIntl } from 'react-intl';
import { GoalEdit } from '../../models/GoalEdit';
import { nameof } from '../../../../utils/nameof';
import { ValidationResult } from '../../../../utils/ValidationResult';

const m = defineMessages({
    newObjectiveFor: { id: 'GoalForm.newGoalFor', defaultMessage: 'New goal for' },
    absoluteAmount: { id: 'GoalForm.absoluteAmount', defaultMessage: 'In absolute value' },
    relativePercentage: { id: 'GoalForm.relativePercentage', defaultMessage: 'Or relative' }
});

interface OwnProps {
    goal: GoalEdit;
    validationResult: ValidationResult;
    ordersLastYear: number;
    disabled?: boolean;
    onChange: (fieldId: string, fieldValue: any) => void;
}

export type GoalFormProps =
    & OwnProps;

export const GoalForm: React.FC<GoalFormProps> = (props) => {
    const { formatMessage } = useIntl();
    const currentYear = dayjs().year();
    const [absoluteGoal, setAbsoluteGoal] = useState(props.goal.target);
    const [relativePercentage, setRelativePercentage] = useState<number | undefined>(undefined);

    const confirmTargetChange = () => {
        props.onChange(nameof<GoalEdit>('target'), absoluteGoal);
    };

    const handleDropdownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const fieldId = event.currentTarget.parentElement?.parentElement?.id || undefined;
        if (fieldId != null) {
            props.onChange(fieldId, data.value);
        }
    };

    const handleTargetChange = (_event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        if (_.isEmpty(data.value)) {
            setAbsoluteGoal(undefined);
            setRelativePercentage(undefined);
            return;
        }

        const target = Number(data.value);

        if (!isNaN(target)) {
            setAbsoluteGoal(target);

            if (props.ordersLastYear > 0) {
                const percentage = Math.round(((target / props.ordersLastYear) - 1) * 100);
                setRelativePercentage(percentage);
            }
        }
    };

    const handlePercentageChange = (_event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        if (_.isEmpty(data.value)) {
            setAbsoluteGoal(undefined);
            setRelativePercentage(undefined);
            return;
        }

        const percentage = Number(data.value);

        if (!isNaN(percentage)) {
            setRelativePercentage(percentage);

            if (props.ordersLastYear > 0) {
                const absoluteGoal = Math.round(props.ordersLastYear * (percentage / 100) + props.ordersLastYear);
                setAbsoluteGoal(absoluteGoal);
            }
        }
    };

    return (
        <>
            <div style={{ fontWeight: 'bold', marginBottom: '.5rem', fontSize: '1rem' }}>
                {formatMessage(m.newObjectiveFor)}&nbsp;
                <YearPicker
                    id={nameof<GoalEdit>('year')}
                    disabled={props.disabled}
                    className={styles.yearPicker}
                    inline
                    from={currentYear} to={currentYear + 5}
                    value={props.goal.year}
                    onChange={handleDropdownChange}
                />
            </div>

            <Segment disabled={props.disabled} style={{ margin: 0 }}>
                <Form error={!props.validationResult.isValid}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Form.Field error={props.validationResult.isFieldInvalid(nameof<GoalEdit>('target'))} disabled={props.disabled} required style={{ flexGrow: 1 }}>
                            <label>{formatMessage(m.absoluteAmount)}</label>
                            <Input
                                id={nameof<GoalEdit>('target')}
                                fluid
                                type="number"
                                inputMode="numeric"
                                label={{ icon: { name: 'dollar', fitted: true } }}
                                labelPosition="left"
                                value={absoluteGoal || ''}
                                onChange={handleTargetChange}
                                onBlur={confirmTargetChange}
                            />
                        </Form.Field>

                        <Form.Field disabled={props.disabled} style={{ marginLeft: '.5rem' }}>
                            <label style={{ position: 'relative' }}>{formatMessage(m.relativePercentage)}</label>
                            <Input
                                fluid
                                type="number"
                                inputMode="numeric"
                                disabled={props.disabled || props.ordersLastYear <= 0}
                                label={{ icon: { name: 'percent', fitted: true } }}
                                labelPosition="left"
                                value={relativePercentage || ''}
                                onChange={handlePercentageChange}
                                onBlur={confirmTargetChange}
                                style={{ minWidth: '115px' }}
                            />
                        </Form.Field>
                    </div>
                </Form>
            </Segment>
        </>
    );
};