import { Branch } from "../clients/types";

export interface Activity {
    id: number;
    branchId: number;
    branch: Branch;
    note: string;
    type: ActivityType;
    createdOn: Date;
    attachmentIds: number[];
}

export enum ActivityType {
    Note,
    Visit,
    Phone
}