import React from "react";
import { Branch } from "../../../clients/types";
import { Address } from "./Address";
import { Contact } from "./Contact";
import { Email } from "./Email";

interface BranchInfoProps {
    branch: Branch;
}

export const BranchInfo: React.FC<BranchInfoProps> = ({ branch }) => {
    return (
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "1.5rem" }}>
            <div style={{ marginRight: "2rem", marginBottom: "1.5rem" }}>
                <Address branch={branch} />
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div style={{ marginRight: "2rem", marginBottom: "1.5rem" }}>
                    <Contact branch={branch} />
                </div>
                <Email branch={branch} />
            </div>
        </div>
    );
};
