import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Table } from "semantic-ui-react";

const m = defineMessages({
    status: { id: "OrdersTable.Headers.Status", defaultMessage: "Status" },
    orderedOn: { id: "OrdersTable.Headers.OrderedOn", defaultMessage: "Ordered on" },
    shippingDate: { id: "OrdersTable.Headers.ShippingDate", defaultMessage: "Shipping date" },
    shippingLocation: {
        id: "OrdersTable.Headers.ShippingLocation",
        defaultMessage: "Shipping location",
    },
    invoicesNumber: { id: "OrdersTable.Headers.InvoicesNumber", defaultMessage: "Invoices #" },
    total: { id: "OrdersTable.Headers.Total", defaultMessage: "Total" },
});

export const TableHeaders = () => {
    const { formatMessage } = useIntl();
    return (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>{formatMessage(m.status)}</Table.HeaderCell>
                <Table.HeaderCell>{formatMessage(m.orderedOn)}</Table.HeaderCell>
                <Table.HeaderCell>{formatMessage(m.shippingDate)}</Table.HeaderCell>
                <Table.HeaderCell>{formatMessage(m.shippingLocation)}</Table.HeaderCell>
                <Table.HeaderCell>{formatMessage(m.invoicesNumber)}</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">{formatMessage(m.total)}</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    );
};
