import { Client, Branch, Set } from './types';
import { get, post } from '../../utils/api';
import { ClientEdit, BranchEdit } from './models';

const endpoint = '/api/clients';
const branchEndpoint = '/api/branches';
const setEndpoint = '/api/sets';

export async function getClients(): Promise<Client[]> {
    return get<Client[]>(endpoint);
}

export async function getBranchById(id: number): Promise<Branch> {
    return get<Branch>(`${branchEndpoint}/${id}`);
}

export async function getClient(id: number): Promise<Client> {
    return get<Client>(`${endpoint}/${id}`);
}

export async function createBranch(clientId: number, branch: BranchEdit): Promise<Branch> {
    return post<Branch>(`${endpoint}/${clientId}/branches`, branch);
}

export async function createClient(client: ClientEdit): Promise<Client> {
    return post<Client>(endpoint, client);
}

export async function getSets(): Promise<Set[]> {
    return get<Set[]>(setEndpoint);
}

export async function updateClientSets(clientId: number, setIds: number[]) {
    return post<void>(`${endpoint}/${clientId}/sets`, setIds);
}

export async function toggleJaymarStudio(branchId: number, hasJaymarStudio: boolean) {
    return post<void>(`${branchEndpoint}/${branchId}/studio`, hasJaymarStudio);
}