import React, { useState } from 'react';
import { Dimmer, Transition } from 'semantic-ui-react';
import styles from './FloatingActionMenu.module.css';
import { FloatingActionButton, FloatingActionButtonProps } from './FloatingActionButton';

export type FloatingActionMenuProps =
    & FloatingActionButtonProps;

export const FloatingActionMenu: React.FC<FloatingActionMenuProps> = (props) => {
    const { children, ...buttonProps } = props;
    const [open, setOpen] = useState(false);

    return (
        <>
            <Transition visible={open} animation="fade" duration={{ show: 250, hide: 750 }}>
                <Dimmer inverted active={open} />
            </Transition>

            <div className={styles.container}>
                <Transition visible={open} animation="fly up" duration={500}>
                    <div className={styles.menu}>
                        {children}
                    </div>
                </Transition>

                <FloatingActionButton
                    primary
                    icon={open ? 'close' : props.icon || 'add'}
                    onClick={() => setOpen(!open)}
                    style={{ zIndex: 1002, position: 'relative' }}
                    {...buttonProps}
                />
            </div>
        </>
    );
};