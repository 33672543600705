import { Content, TableCell, TableLayout } from "pdfmake/interfaces";
import { defineMessages, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store/store";
import { User } from "../../account/types";
import { getClientById } from "../../clients/selectors";
import { Client } from "../../clients/types";
import { OrdersFilters } from "../../orders";
import {
    getRepresentativeById,
    getRepresentativeDisplayName,
} from "../../representatives/selectors";

const m = defineMessages({
    representative: {
        id: "ReportPdf.Filters.Representative",
        defaultMessage: "Representative",
    },
    client: { id: "ReportPdf.Filters.Client", defaultMessage: "Client" },
    country: { id: "ReportPdf.Filters.Country", defaultMessage: "Country" },
    province: { id: "ReportPdf.Filters.Province", defaultMessage: "Province/State" },
    currency: { id: "ReportPdf.Filters.Currency", defaultMessage: "Currency" },
});

const filtersTableLayout: TableLayout = {
    hLineWidth: (i: number) => (i ? 1 : 0),
    vLineWidth: () => 0,
    hLineColor: () => "black",
    paddingTop: () => 2,
    paddingBottom: () => 2,
};

export const useReportFiltersPdf = (filters: OrdersFilters) => {
    const { formatMessage } = useIntl();

    const client = useSelector<ApplicationState, Client | undefined>((state) =>
        getClientById(state, Number(filters.clientId))
    );

    const representative = useSelector<ApplicationState, User | undefined>((state) =>
        getRepresentativeById(state, filters.representativeId || "")
    );

    const getFilterRow = (filterName: string, value: string): TableCell[][] => {
        return [
            [
                { text: `${filterName.toUpperCase()} :`, alignment: "right" },
                { text: value.toUpperCase() },
            ],
        ];
    };

    const getRepresentativeFilterRow = (): TableCell[][] =>
        representative != null
            ? getFilterRow(
                  formatMessage(m.representative),
                  getRepresentativeDisplayName(representative)
              )
            : [];

    const getClientFilterRow = (): TableCell[][] =>
        client != null ? getFilterRow(formatMessage(m.client), client.displayName) : [];

    const getCountryFilterRow = (): TableCell[][] =>
        filters.countryCode != null
            ? getFilterRow(formatMessage(m.country), filters.countryCode)
            : [];

    const getProvinceFilterRow = (): TableCell[][] =>
        filters.provinceCode != null
            ? getFilterRow(formatMessage(m.province), filters.provinceCode)
            : [];

    const getCurrencyFilterRow = (): TableCell[][] =>
        filters.currency != null
            ? getFilterRow(formatMessage(m.currency), filters.currency)
            : [];

    const getActiveFilterTable = (): Content => {
        const hasFilters =
            filters.clientId != null ||
            filters.representativeId != null ||
            filters.currency != null ||
            filters.countryCode ||
            filters.provinceCode;

        if (!hasFilters) return "";

        return {
            table: {
                widths: ["auto", "*"],
                body: [
                    ...getRepresentativeFilterRow(),
                    ...getClientFilterRow(),
                    ...getCountryFilterRow(),
                    ...getProvinceFilterRow(),
                    ...getCurrencyFilterRow(),
                ],
            },
            layout: filtersTableLayout,
            margin: [0, 0, 0, 20],
        };
    };

    return {
        getActiveFilterTable,
    };
};
