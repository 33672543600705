import React from "react";
import { Link } from "react-router-dom";
import { TableCell, TableCellProps } from "semantic-ui-react";
import { FormattedAmount } from "../FormattedAmount";

interface AmountCellProps extends TableCellProps {
    amount?: number;
    linkTo?: string;
}

export const AmountCell: React.FC<AmountCellProps> = ({ amount, linkTo, ...tableCellProps }) => {
    const formattedAmount = <FormattedAmount amount={amount} />;

    return (
        <TableCell textAlign="right" {...tableCellProps}>
            {linkTo ? <Link to={linkTo}>{formattedAmount}</Link> : formattedAmount}
        </TableCell>
    );
};
