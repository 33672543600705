import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import { FluidPageLayout } from '../../../components/layouts';
import { FloatingActionButtons, FloatingActionButton } from '../../../components/floatingActionButton';
import { useNavigate } from 'react-router-dom';
import { ClientPicker } from '../../clients/views/components/ClientPicker';
import { useSelector, useDispatch } from 'react-redux';
import { getAllClients, areClientsLoading } from '../../clients/selectors';
import { GoalForm } from './components/GoalForm';
import { Statistic, DropdownProps } from 'semantic-ui-react';
import { formatMoney } from '../../../utils/formatMoney';
import { GoalsList } from './components/GoalsList';
import { defineMessages, useIntl } from 'react-intl';
import * as LayoutActions from '../../layout/actions';
import * as GoalsActions from '../actions';
import { GoalEdit } from '../models/GoalEdit';
import { getOrders, getExchangeRates } from '../../orders';
import { ApplicationState } from '../../../store/store';
import { calculateOrderPrice, isSavingGoal, getClientGoals, areGoalsLoading } from '../selectors';
import { ValidationResult } from '../../../utils/ValidationResult';
import { nameof } from '../../../utils/nameof';

const m = defineMessages({
    title: { id: 'CreateGoalPage.title', defaultMessage: 'New goal' },
    lastYearOrdersLabel: { id: 'CreateGoalPage.lastYearOrdersLabel', defaultMessage: 'In orders last year' }
});

export const CreateGoalPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [goal, setGoal] = useState<GoalEdit>(new GoalEdit());
    const [validationResult, setValidationResult] = useState(ValidationResult.empty());
    const isClientSelected = goal.clientId != null;
    const allClients = useSelector(getAllClients);
    const loadingGoals = useSelector(areGoalsLoading);
    const loadingClients = useSelector(areClientsLoading);
    const savingGoal = useSelector(isSavingGoal);

    const defaultExchangeRates = useSelector(getExchangeRates);
    const lastYear = dayjs().add(-1, 'year').startOf('year').toDate();
    const lastYearOrders = useSelector((state: ApplicationState) => getOrders(state, lastYear, dayjs(lastYear).endOf('year').toDate(), { clientId: goal.clientId }));
    const lastYearOrdersTotal = useMemo(() => _(lastYearOrders).sumBy(x => calculateOrderPrice(x, true, defaultExchangeRates)), [lastYearOrders, defaultExchangeRates]);

    const selectedClientGoals = useSelector((state: ApplicationState) => getClientGoals(state, goal.clientId || -1));

    useEffect(() => {
        dispatch(LayoutActions.setPageTitle(formatMessage(m.title)));
    }, [dispatch, formatMessage]);

    const navigateBack = () => navigate(-1);

    const updateGoal = (fieldId: string, fieldValue: any) => {
        setGoal(new GoalEdit({
            ...goal,
            [fieldId]: fieldValue
        }));
    };

    const selectClient = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const clientId = data.value as number;

        setGoal(new GoalEdit({
            ...goal,
            clientId: clientId
        }));
    };

    const createGoal = () => {
        const result = goal.validate();
        setValidationResult(result);

        if (result.isValid) {
            dispatch(GoalsActions.createOrUpdateGoal(goal));
        }
    };

    return (
        <FluidPageLayout>
            <ClientPicker
                error={validationResult.isFieldInvalid(nameof<GoalEdit>('clientId'))}
                clients={allClients}
                disabled={savingGoal}
                loading={loadingClients}
                onChange={selectClient}
            />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '1rem 0 1.5rem 0' }}>
                <Statistic
                    size="tiny"
                    value={formatMoney(lastYearOrdersTotal)}
                    label={formatMessage(m.lastYearOrdersLabel)}
                    style={{ visibility: isClientSelected ? 'visible' : 'hidden' }}
                />
            </div>

            <GoalForm
                goal={goal}
                validationResult={validationResult}
                ordersLastYear={lastYearOrdersTotal}
                disabled={!isClientSelected || savingGoal}
                onChange={updateGoal}
            />

            <div style={{ marginTop: '1rem' }}>
                <GoalsList
                    goals={selectedClientGoals}
                    disabled={!isClientSelected}
                    loading={loadingGoals}
                />
            </div>

            <FloatingActionButtons>
                <FloatingActionButton
                    icon="cancel"
                    onClick={navigateBack}
                />
                <FloatingActionButton
                    disabled={savingGoal}
                    loading={savingGoal}
                    primary
                    icon="save"
                    onClick={createGoal}
                />
            </FloatingActionButtons>
        </FluidPageLayout>
    );
};