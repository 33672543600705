import { Reducer } from 'redux';
import {
    ClientsState,
    ClientActionTypes,
    LOAD_CLIENTS_SUCCESS,
    LOAD_CLIENTS_FAILURE,
    LOAD_CLIENTS_REQUEST,
    CREATE_CLIENT_SUCCESS,
    CREATE_CLIENT_REQUEST,
    CREATE_CLIENT_FAILURE,
    CREATE_BRANCH_REQUEST,
    CREATE_BRANCH_FAILURE,
    CREATE_BRANCH_SUCCESS,
} from './types';
import _ from 'lodash';

const initialState: ClientsState = {
    byId: {},
    allIds: [],
    loading: false,
    saving: false,
};

export const clientsReducer: Reducer<ClientsState, ClientActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_CLIENTS_REQUEST: {
            return {
                ...state,
                loading: true
            };
        }

        case LOAD_CLIENTS_SUCCESS: {
            const byId = Object.assign({}, ...action.payload.map(x => ({ [x.id]: x })));
            const allIds = action.payload.map(x => x.id);

            return {
                ...state,
                byId,
                allIds,
                loading: false
            };
        }

        case LOAD_CLIENTS_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }

        case CREATE_CLIENT_REQUEST:
        case CREATE_BRANCH_REQUEST: {
            return { ...state, saving: true };
        }

        case CREATE_CLIENT_SUCCESS: {
            const byId = _.merge({}, state.byId, { [action.payload.client.id]: action.payload.client });
            const allIds = _.union(state.allIds, [action.payload.client.id]);

            return {
                ...state,
                byId,
                allIds,
                saving: false
            };
        }

        case CREATE_BRANCH_SUCCESS: {
            const clientId = action.payload.branch.clientId;
            const client = state.byId[clientId];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [clientId]: {
                        ...state.byId[clientId],
                        branches: [...client.branches, action.payload.branch]
                    }
                },
                saving: false
            };
        }

        case CREATE_CLIENT_FAILURE:
        case CREATE_BRANCH_FAILURE: {
            return { ...state, saving: false };
        }


        default:
            return state;
    }
};