import React from 'react';
import _ from 'lodash';
import { defineMessages, useIntl } from 'react-intl';
import { DropdownProps, Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { Client } from '../../types';
import styles from './ClientPicker.module.css';

interface OwnProps {
    clients: Client[];
}

export type ClientPickerProps =
    & OwnProps
    & DropdownProps;

const m = defineMessages({
    defaultPlaceholder: { id: 'ClientPicker.defaultPlaceholder', defaultMessage: 'Choose a client' },
});

export const ClientPicker: React.FC<ClientPickerProps> = (props) => {
    const { formatMessage } = useIntl();
    const { ...dropdownProps } = props;

    const clientOptions = _(props.clients)
        .orderBy(x => x.displayName)
        .map((x): DropdownItemProps => ({
            key: x.id,
            text: x.displayName,
            value: x.id,
            selected: props.defaultValue === x.id
        }))
        .value();

    return (
        <Dropdown
            className={styles.clientPicker}
            fluid
            inline
            search
            options={clientOptions}
            placeholder={props.placeholder || formatMessage(m.defaultPlaceholder)}
            {...dropdownProps}
        />
    );
};