export const LOAD_ORDERS_REQUEST = 'ORDERS_LOAD_ORDERS_REQUEST';
export const LOAD_ORDERS_SUCCESS = 'ORDERS_LOAD_ORDERS_SUCCESS';
export const LOAD_ORDERS_FAILURE = 'ORDERS_LOAD_ORDERS_FAILURE';

export const LOAD_EXCHANGE_RATES_REQUEST = 'ORDERS_LOAD_EXCHANGE_RATES_REQUEST';
export const LOAD_EXCHANGE_RATES_SUCCESS = 'ORDERS_LOAD_EXCHANGE_RATES_SUCCESS';
export const LOAD_EXCHANGE_RATES_FAILURE = 'ORDERS_LOAD_EXCHANGE_RATES_FAILURE';

export interface LoadOrdersRequestAction { type: typeof LOAD_ORDERS_REQUEST; payload: { startDate: Date, endDate: Date }; }
export interface LoadOrdersSuccessAction { type: typeof LOAD_ORDERS_SUCCESS; payload: { orders: Order[] }; }
export interface LoadOrdersFailureAction { type: typeof LOAD_ORDERS_FAILURE; payload: { error: Error }; }

export interface LoadExchangeRatesRequestAction { type: typeof LOAD_EXCHANGE_RATES_REQUEST; }
export interface LoadExchangeRatesSuccessAction { type: typeof LOAD_EXCHANGE_RATES_SUCCESS; payload: { rates: ExchangeRate[] }; }
export interface LoadExchangeRatesFailureAction { type: typeof LOAD_EXCHANGE_RATES_FAILURE; payload: { error: Error }; }

export type OrdersActionTypes =
    | LoadOrdersRequestAction | LoadOrdersSuccessAction | LoadOrdersFailureAction
    | LoadExchangeRatesRequestAction | LoadExchangeRatesSuccessAction | LoadExchangeRatesFailureAction;

export enum OrderStatus {
    creating = 0,
    verified = 1,
    inProduction = 2,
    partiallyCompleted = 3,
    completed = 4,
    partiallyLoaded = 5,
    loaded = 6,
    shipped = 7,
    billed = 8,
    cancelled = 9
}

export interface Order {
    id: number;
    status: OrderStatus;
    currency: string;
    price: number;
    orderedOn: Date | string;
    shippedOn?: Date | string;
    shippingCountryCode: string;
    shippingProvinceCode: string;
    clientId: number;
    invoices: Invoice[];
    clientCode: string;
    totalDiscount: number;
    priceAfterDiscount: number;
    isClosed: boolean;
}

export interface Invoice {
    id: number;
    billedOn: Date | string;
    orderId: number | undefined;
    currency: string;
    totalAmount: number;
    salesAmount: number;
    ledgerAmount: number;
    rebateAmount: number;
    secondRebateAmount: number;
    canadaSalesTax: number;
    quebecSalesTax: number;
    harmonizedSalesTax: number;
    shippingFees: number;
    additionalFees: number;
    adjustmentForSalesCommission: number;
    exchangeRate: number;
}

export interface OrdersState {
    data: OrdersDataState;
    exchangeRates: ExchangeRatesState;
}

export interface OrdersDataState {
    byId: { [orderId: number]: Order };
    byYear: { [year: number]: number[] };
    byYearAndMonth: { [yearAndMonth: string]: number[] };
    byClientId: { [clientId: number]: number[] };
    byRepresentativeId: { [representativeId: string]: number[] };
    byCountry: { [countryCode: string]: number[] };
    byProvince: { [provinceCode: string]: number[] };
    byCurrency: { [currency: string]: number[] };
    allIds: number[];
    loading: boolean;
}

export interface ExchangeRatesState {
    byCurrency: { [currency: string]: ExchangeRate };
    allCurrencies: string[];
}

export interface OrdersFilters {
    month?: number;
    clientId?: number;
    representativeId?: string;
    currency?: string;
    countryCode?: string;
    provinceCode?: string;
    [key: string]: any;
}

export interface ExchangeRate {
    currency: string;
    rate: number;
    lastUpdatedOn: string | Date;
}

export interface BocExchangeRatesResponse {
    terms: { url: string };
    seriesDetail: {
        [currency: string]: {
            label: string,
            description: string,
            dimension: {
                key: string;
                name: string;
            }
        }
    },
    observations: BocObservation[]
}

interface BocObservation {
    d: Date | string;
    [currency: string]: { v: number | string } | any,
}