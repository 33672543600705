import {
    LOAD_GOALS_REQUEST,
    CREATE_OR_UPDATE_GOAL_REQUEST,
    CreateOrUpdateGoalRequestAction,
    Goal,
} from "./types";
import * as api from './api';
import * as actions from './actions';
import { call, put, all, takeLatest, fork, takeEvery } from 'redux-saga/effects';
import { push } from "redux-first-history";

function* watchLoadGoals() { yield takeLatest(LOAD_GOALS_REQUEST, handleLoadGoals); }
function* handleLoadGoals() {
    try {
        const result: Goal[] = yield call(api.getGoals);
        yield put(actions.loadGoalsSuccess(result));
    } catch (e) {
        yield put(actions.loadGoalsFailure(e as Error));
    }
}

function* watchCreateOrUpdateGoal() { yield takeEvery(CREATE_OR_UPDATE_GOAL_REQUEST, handleCreateOrUpdateGoal); }
function* handleCreateOrUpdateGoal(action: CreateOrUpdateGoalRequestAction) {
    try {
        const result: Goal = yield call(api.createOrUpdateGoal, action.payload);
        yield put(actions.createOrUpdateGoalSuccess(result));
        yield put(push('/goals'));
    } catch (e) {
        yield put(actions.createOrUpdateGoalFailure(e as Error));
    }
}

export default function* goalsSaga() {
    yield all([
        fork(watchLoadGoals),
        fork(watchCreateOrUpdateGoal)
    ]);
}