import React from 'react';
import { Activity } from '../../activities/types';
import { Table, Divider } from 'semantic-ui-react';
import { RecentActivityRow } from './RecentActivityRow';
import { defineMessages, useIntl } from 'react-intl';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { LoadingActivityRow } from './LoadingActivityRow';

export interface RecentActivitiesProps {
    title: string;
    activities: Activity[];
    loading?: boolean;
    style?: React.CSSProperties;
}

const m = defineMessages({
    noActivity: { id: 'RecentActivities.noActivity', defaultMessage: 'No recent activity' }
});

export const RecentActivities: React.FC<RecentActivitiesProps> = (props) => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const hasActivities = props.activities.length > 0;

    return (
        <div style={props.style}>
            <h3>{props.title}</h3>
            <Divider fitted style={{ margin: '0.5rem 0' }} />

            {props.loading &&
                <Table basic="very" unstackable fixed singleLine style={{ margin: 0 }}>
                    <Table.Body>
                        <LoadingActivityRow />
                        <LoadingActivityRow />
                        <LoadingActivityRow />
                    </Table.Body>
                </Table>
            }

            {!props.loading && hasActivities &&
                <Table basic="very" selectable unstackable fixed singleLine style={{ margin: 0 }}>
                    <Table.Body>
                        {_(props.activities)
                            .sortBy(x => x.createdOn)
                            .reverse()
                            .map(x => (
                                <RecentActivityRow
                                    key={x.id}
                                    activity={x}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => navigate(`/clients/${x.branch?.clientId}/branches/${x.branchId}/activities/${x.id}`)}
                                />
                            ))
                            .value()
                        }
                    </Table.Body>
                </Table>
            }

            {!props.loading && !hasActivities &&
                <p style={{ color: 'grey', margin: '.25rem 0 .25rem 1rem' }}>
                    {formatMessage(m.noActivity)}
                </p>
            }

            <Divider fitted style={{ margin: '0.5rem 0' }} />
        </div>
    );
};