import React, { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { FloatingActionButton, FloatingActionButtons } from "../../../components/floatingActionButton";
import { FluidPageLayout } from "../../../components/layouts";
import { PageHeader } from "../../../components/PageHeader";
import * as LayoutActions from '../../layout/actions';
import * as clientApi from '../../clients/api';
import { Branch } from "../types";
import { JaymarStudioForm } from "./components/JaymarStudioForm";

const m = defineMessages({
    title: { id: 'JaymarStudioPage.title', defaultMessage: 'Jaymar Studio' }
});

export const JaymarStudioPage = () => {
    const { formatMessage } = useIntl();
    const { clientId, branchId } = useParams<any>();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [branch, setBranch] = useState<Branch | undefined>(undefined);
    const [hasStudio, setHasStudio] = useState(false);
    const [loadingBranch, setLoadingBranch] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        dispatch(LayoutActions.setPageTitle(formatMessage(m.title)));
    }, [dispatch, formatMessage]);

    useEffect(() => {
        const id = branchId ? Number(branchId) : undefined;

        if (id != null && !isNaN(id)) {
            setLoadingBranch(true);

            clientApi.getBranchById(id)
                .then(result => {
                    setBranch(result);
                    setHasStudio(result.hasSpaceReservedForJaymar);
                })
                .finally(() => setLoadingBranch(false));
        }
    }, [branchId]);

    const navigateBack = () => navigate(`/clients/${clientId}/branches/${branchId}`);
    const saveChanges = () => {
        if (branch != null) {
            setSaving(true);

            clientApi.toggleJaymarStudio(branch.id, hasStudio)
                .then(() => navigateBack())
                .finally(() => setSaving(false));
        }
    };

    return (
        <FluidPageLayout>
            <PageHeader title={branch?.client?.displayName || ''} subtitle={branch?.city || ''} />
            <JaymarStudioForm
                disabled={saving || loadingBranch}
                hasJaymarStudio={hasStudio}
                onChange={hasJaymarStudio => setHasStudio(hasJaymarStudio)}
            />

            <FloatingActionButtons>
                <FloatingActionButton
                    icon="cancel"
                    onClick={navigateBack}
                />
                <FloatingActionButton
                    disabled={saving || loadingBranch}
                    loading={saving}
                    primary
                    icon="save"
                    onClick={saveChanges}
                />
            </FloatingActionButtons>
        </FluidPageLayout>
    );
};