import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as AccountActions from "../actions";

const LogoutPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(AccountActions.logout());
    }, [dispatch]);

    return <Navigate to="/account/login" />;
};

export { LogoutPage };
