import React from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import type { GeoLocationValue } from './types';
import { Search, SearchResultData } from 'semantic-ui-react';
import { GoogleAutocompleteResult } from './GoogleAutocompleteResult';
import { Address } from '../../modules/clients/types';

const KiloMetersRadius = 20;
const jaymarOfficeLatitude = 45.702556;
const jaymarOfficeLongitude = -73.62413;

interface GoogleAutoCompleteInputProps {
  disableClearable?: boolean;
  small?: boolean;
  label?: string;
  error?: boolean;
  helperText?: string;
  defaultValue?: GeoLocationValue;
  language: string;

  onChange: (value: {
    geoLocation: GeoLocationValue | null;
    address: Address | null;
  }) => void;
}

export const GoogleAutocomplete: React.FC<GoogleAutoCompleteInputProps> = (
  props: GoogleAutoCompleteInputProps
) => {
  const {
    value,
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      language: props.language,
      origin: new google.maps.LatLng(
        jaymarOfficeLatitude,
        jaymarOfficeLongitude
      ),
      location: new google.maps.LatLng(
        jaymarOfficeLatitude,
        jaymarOfficeLongitude
      ),
      radius: KiloMetersRadius * 1000,
      componentRestrictions: {
        country: ['ca', 'us'],
      },
      types: [],
    },
    defaultValue: props.defaultValue?.formattedAddress,
    debounce: 300,
  });

  const handleOnSelect = (
    _event: React.MouseEvent<HTMLDivElement>,
    data: SearchResultData
  ) => {
    const result = data.result as google.maps.places.AutocompletePrediction;
    if (!result) {
      props.onChange({ geoLocation: null, address: null });
      return;
    }

    setValue(result.description);

    getGeocode({ address: result.description }).then((results) => {
      if (results.length > 0) {
        const postalCode = results[0].address_components.find(x => x.types.find(p => p === 'postal_code'));
        const cityName = results[0].address_components.find(x => x.types.find(p => p === 'locality'));
        const province = results[0].address_components.find(x => x.types.find(p => p === 'administrative_area_level_1'));
        const country = results[0].address_components.find(x => x.types.find(p => p === 'country'));
        const number = results[0].address_components.find(x => x.types.find(p => p === 'street_number'));
        const route = results[0].address_components.find(x => x.types.find(p => p === 'route'));

        const address: Address = {
          address1: `${number && number.long_name} ${route && route.long_name}`,
          address2: '',
          city: cityName ? cityName.long_name : '',
          postalCode: postalCode ? postalCode.long_name : '',
          province: province ? province.short_name : '',
          country: country ? country.short_name : '',
        };

        const coordinates = getLatLng(results[0]);
        props.onChange({
          geoLocation: {
            formattedAddress: results[0].formatted_address,
            coordinates: {
              latitude: coordinates.lat,
              longitude: coordinates.lng,
            },
          },
          address: address,
        });
      }
    });
  };

  return (
    <Search
        fluid
        style={{ width: '100%' }}
        defaultValue={props.defaultValue?.formattedAddress ?? ''}
        value={value}
        onSearchChange={(_, data) => setValue(data.value ?? '')}
        results={data}
        resultRenderer={(option) => <GoogleAutocompleteResult key={option.description} prediction={option as unknown as google.maps.places.AutocompletePrediction} />}
        onResultSelect={handleOnSelect}
    />
  );
};
