import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SinglePageLayout from '../../../components/layouts/SinglePageLayout';
import { HomeHeader } from '../components/HomeHeader';
import { RecentActivities } from '../components/RecentActivities';
import { getUserName } from '../../account/selectors';
import { defineMessages, useIntl } from 'react-intl';
import { FloatingActionMenu, FloatingActionMenuItem } from '../../../components/floatingActionButton';

import * as activityApi from '../../activities/api';
import * as LayoutActions from '../../layout/actions';
import { Activity, ActivityType } from '../../activities/types';

const m = defineMessages({
    pageTitle: { id: 'HomePage.pageTitle', defaultMessage: 'Home' },
    recentActivitiesSection: { id: 'HomePage.recentActivitiesSection', defaultMessage: 'Activities' },
    recentNotesTitle: { id: 'HomePage.recentNotesTitle', defaultMessage: 'Notes' },
    recentVisitsTitle: { id: 'HomePage.recentVisitsTitle', defaultMessage: 'Visits' },
    recentCallsTitle: { id: 'HomePage.recentCallsTitle', defaultMessage: 'Calls' },
    newClientAction: { id: 'HomePage.newClientAction', defaultMessage: 'New client' },
    newBranchAction: { id: 'HomePage.newBranchAction', defaultMessage: 'New branch' },
    newActivityAction: { id: 'HomePage.newActivityAction', defaultMessage: 'New activity' },
    newLeadAction: { id: 'HomePage.newLeadAction', defaultMessage: 'New lead' }
});

export const HomePage: React.FC = () => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();

    const [loading, setLoading] = useState(false);
    const [activities, setActivities] = useState<Activity[]>([]);

    const username = useSelector(getUserName);

    useEffect(() => {
        dispatch(LayoutActions.setPageTitle(formatMessage(m.pageTitle)));

        setLoading(true);

        activityApi
            .loadRecentActivities()
            .then(result => setActivities(result))
            .finally(() => setLoading(false));
    }, [dispatch, formatMessage]);


    return (
        <SinglePageLayout>
            <HomeHeader name={username} style={{ marginTop: '1em' }} />
            <h2 style={{ marginTop: 0 }}>{formatMessage(m.recentActivitiesSection)}</h2>

            <div style={{ overflowY: 'auto', paddingTop: '.5em' }}>
                <RecentActivities
                    title={formatMessage(m.recentNotesTitle)}
                    activities={activities.filter(x => x.type === ActivityType.Note)}
                    loading={loading}
                />

                <RecentActivities
                    title={formatMessage(m.recentVisitsTitle)}
                    activities={activities.filter(x => x.type === ActivityType.Visit)}
                    style={{ marginTop: '1em' }}
                    loading={loading}
                />

                <RecentActivities
                    title={formatMessage(m.recentCallsTitle)}
                    activities={activities.filter(x => x.type === ActivityType.Phone)}
                    style={{ marginTop: '1em' }}
                    loading={loading}
                />
            </div>

            <FloatingActionMenu>
                <FloatingActionMenuItem to="/leads/new" icon="user outline" title={formatMessage(m.newLeadAction)} />
                <FloatingActionMenuItem to="/clients/new" icon="user" title={formatMessage(m.newClientAction)} />
                <FloatingActionMenuItem to="/clients/new" icon="sitemap" title={formatMessage(m.newBranchAction)} />
                <FloatingActionMenuItem to="/activities/new" icon="sticky note" title={formatMessage(m.newActivityAction)} />
            </FloatingActionMenu>
        </SinglePageLayout>
    );
};