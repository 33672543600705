import React from "react";
import { Icon } from "semantic-ui-react";
import { Branch } from "../../../clients/types";
import styles from "./BranchInfo.module.css";

interface AddressProps {
    branch: Branch;
}

export const Address: React.FC<AddressProps> = ({ branch }) => {
    const address = branch?.address;

    if (!address) {
        return null;
    }

    const addressLink =
        address != null
            ? `${address.address1}, ${address.address2}, ${address.city}, ${address.province} ${address.postalCode}, ${address.country}`
            : "";

    return (
        <div className={styles.property}>
            <div className={styles.icon}>
                <Icon size="large" name="map marker alternate" />
            </div>
            <div style={{ display: "flex", flexGrow: 1 }}>
                <div className={styles.content}>
                    <a href={`https://www.google.com/maps/search/?api=1&query=${addressLink}`}>
                        <span style={{ display: "block" }}>{address.address1}</span>
                        <span style={{ display: "block" }}>{address.address2}</span>
                        <span style={{ display: "block" }}>
                            {address.city} {address.province} {address.postalCode}
                        </span>
                        <span style={{ display: "block" }}>{address.country}</span>
                    </a>
                </div>
            </div>
        </div>
    );
};
