import React from 'react';
import dayjs from 'dayjs';
import { FeedEventProps, Icon, Feed } from 'semantic-ui-react';
import { Activity, ActivityType } from '../../types';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';
import styles from './ActivityFeedEvent.module.css';

interface OwnProps {
    activity: Activity
    multiline?: boolean;
    onClick?: () => void;
}

export type ActivityFeedEventProps =
    & OwnProps
    & FeedEventProps;

interface ActivityInfoMap {
    [key: number]: {
        icon: SemanticICONS;
        title: MessageDescriptor;
    },
    unknown: { icon: SemanticICONS, title: MessageDescriptor }
}

const m = defineMessages({
    noteTitle: { id: 'ActivityFeedEvent.noteTitle', defaultMessage: 'A note was added to the branch.' },
    visitTitle: { id: 'ActivityFeedEVent.visitTitle', defaultMessage: 'A representative visited the branch.' },
    phoneTitle: { id: 'ActivityFeedEvent.phoneTitle', defaultMessage: 'A phone call was made to the branch.' }
});

const activityInfoMap: ActivityInfoMap = {
    [ActivityType.Note]: { icon: 'sticky note outline', title: m.noteTitle },
    [ActivityType.Visit]: { icon: 'street view', title: m.visitTitle },
    [ActivityType.Phone]: { icon: 'phone', title: m.phoneTitle },
    unknown: { icon: 'phone', title: m.phoneTitle },
};

export const ActivityFeedEvent: React.FC<ActivityFeedEventProps> = (props) => {
    const { formatMessage } = useIntl();
    const activityHeader = activityInfoMap[props.activity.type] || activityInfoMap.unknown;

    return (
        <Feed.Event className={props.onClick ? styles.selectable : ''} onClick={props.onClick}>
            <Feed.Label style={{ paddingTop: '.25rem' }}>
                <Icon name={activityHeader.icon} />
            </Feed.Label>
            <Feed.Content style={{ overflow: 'hidden', marginLeft: '.25rem' }}>
                <Feed.Date style={{ margin: 0 }}>{dayjs.utc(props.activity.createdOn).local().format('LLL')}</Feed.Date>
                <Feed.Summary className={styles.trimText}>{formatMessage(activityHeader.title)}</Feed.Summary>
                <Feed.Extra className={!props.multiline ? styles.trimText : undefined}>
                    {!props.multiline &&
                        <>{props.activity.note}</>
                    }
                    {props.multiline &&
                        <p style={{ whiteSpace: 'pre-line' }}>{props.activity.note}</p>
                    }
                </Feed.Extra>
            </Feed.Content>
        </Feed.Event>
    );
};