import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Table, TableCell } from "semantic-ui-react";
import { OrdersReportData } from "../../hooks/useOrdersReportData";
import { AmountCell } from "../AmountCell";
import { RatioCell } from "../RatioCell";

const m = defineMessages({
    previous: { id: "OrdersReportTable.ClientRows.Previous", defaultMessage: "Previous" },
    current: { id: "OrdersReportTable.ClientRows.Current", defaultMessage: "Current" },
    total: { id: "OrdersReportTable.ClientRows.Total", defaultMessage: "Total" },
});

interface TotalRowsProps {
    ordersReportData: OrdersReportData;
}

export const OrdersTotalRows: React.FC<TotalRowsProps> = ({ ordersReportData }) => {
    const { formatMessage } = useIntl();

    const monthlyOrders = ordersReportData.totalMonthlyOrders;

    const firstRowTopBorderStyle = {
        borderTopWidth: "medium",
        borderTopColor: "#404040",
    };

    const currentPeriodRow = (
        <Table.Row>
            <TableCell rowSpan={2} style={{ ...firstRowTopBorderStyle, verticalAlign: "baseline" }}>
                <div>{formatMessage(m.total)}</div>
            </TableCell>
            <TableCell style={{ ...firstRowTopBorderStyle }}>{formatMessage(m.current)}</TableCell>
            {ordersReportData.monthsInOrder.map((month) => (
                <AmountCell
                    key={month}
                    amount={monthlyOrders?.statsByMonth[month]?.currentPeriodTotal}
                    style={{ ...firstRowTopBorderStyle }}
                />
            ))}
            <AmountCell
                amount={monthlyOrders?.currentPeriodTotal}
                style={{ fontWeight: "bold", ...firstRowTopBorderStyle }}
            />
        </Table.Row>
    );

    const previousPeriodRow = (
        <Table.Row style={{ borderTop: "none" }}>
            <TableCell style={{ borderTop: "none" }}>{formatMessage(m.previous)}</TableCell>
            {ordersReportData.monthsInOrder.map((month) => (
                <AmountCell
                    key={month}
                    amount={monthlyOrders?.statsByMonth[month]?.previousPeriodTotal}
                    style={{ borderTop: "none" }}
                />
            ))}
            <AmountCell
                amount={monthlyOrders?.previousPeriodTotal}
                style={{ fontWeight: "bold" }}
            />
        </Table.Row>
    );

    const ratiosRow = (
        <Table.Row style={{ borderTop: "none" }}>
            <TableCell style={{ borderTop: "none" }}></TableCell>
            <TableCell style={{ borderTop: "none" }}></TableCell>
            {ordersReportData.monthsInOrder.map((month) => (
                <RatioCell
                    key={month}
                    style={{ borderTop: "none" }}
                    ratio={monthlyOrders?.statsByMonth[month]?.ratio}
                    previousAmount={monthlyOrders?.statsByMonth[month]?.previousPeriodTotal}
                    currentAmount={monthlyOrders?.statsByMonth[month]?.currentPeriodTotal}
                />
            ))}
            <RatioCell
                style={{ borderTop: "none" }}
                ratio={monthlyOrders?.ratio}
                previousAmount={monthlyOrders?.previousPeriodTotal}
                currentAmount={monthlyOrders?.currentPeriodTotal}
            />
        </Table.Row>
    );

    return (
        <>
            {currentPeriodRow}
            {previousPeriodRow}
            {ratiosRow}
        </>
    );
};
