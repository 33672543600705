import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Icon } from "semantic-ui-react";
import { formatPhoneNumber } from "../../../../utils/formatters";
import { Branch } from "../../../clients/types";
import styles from "./BranchInfo.module.css";

const m = defineMessages({
    clientContactLabel: {
        id: "BranchInfo.Contact.clientContactLabel",
        defaultMessage: "Main contact",
    },
    branchContactLabel: {
        id: "BranchInfo.Contact.branchContactLabel",
        defaultMessage: "Branch contact",
    },
});

interface ContactProps {
    branch: Branch;
}

export const Contact: React.FC<ContactProps> = ({ branch }) => {
    const { formatMessage } = useIntl();

    return (
        <div className={styles.property}>
            <div className={styles.icon}>
                <Icon size="large" name="phone" />
            </div>
            <div className={styles.content}>
                <div>
                    <label>{formatMessage(m.branchContactLabel)}</label>
                    <a href={`tel:${branch.phone}`}>{formatPhoneNumber(branch.phone)}</a>
                </div>
            </div>
        </div>
    );
};
