import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FluidPageLayout } from "../../../components/layouts";
import { PageHeader } from "../../../components/PageHeader";
import { Order } from "../../orders";
import { BranchInfo } from "../components/BranchInfo";
import queryString from "query-string";
import dayjs from "dayjs";
import { useReportDates } from "../hooks/useReportDates";
import { OrdersTable } from "../components/OrdersTable";
import { useDispatch } from "react-redux";
import { defineMessages, useIntl } from "react-intl";
import * as LayoutActions from "../../layout/actions";
import { Header, Loader } from "semantic-ui-react";
import { Branch } from "../../clients/types";
import { getBranchByClientCode, getBranchOrdersByClientCode } from "../api";
import { useDefaultErrorHandler } from "../../../utils/api";

const m = defineMessages({
    title: { id: "ClientOrdersPage.title", defaultMessage: "Client orders" },
    tableTitle: {
        id: "ClientOrdersPage.TableTitle",
        defaultMessage: "Orders from {start} to {end}",
    },
});

interface ClientOrdersReportFilters {
    start?: string;
    end?: string;
}

export const ClientOrdersReportPage = () => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();

    useEffect(() => {
        dispatch(LayoutActions.setPageTitle(formatMessage(m.title)));
    }, [dispatch, formatMessage]);

    const navigate = useNavigate();
    const location = useLocation();
    const defaultErrorHandler = useDefaultErrorHandler();

    const { currentPeriodEndDate, previousPeriodStartDate } = useReportDates();

    const { clientCode } = useParams();

    const filters = queryString.parse(location.search) as ClientOrdersReportFilters;

    const ordersStartDate = useMemo(
        () => dayjs.utc(filters.start).toDate() || previousPeriodStartDate,
        [filters.start, previousPeriodStartDate]
    );

    const ordersEndDate = useMemo(
        () => dayjs.utc(filters.end).toDate() || currentPeriodEndDate,
        [currentPeriodEndDate, filters.end]
    );

    const [branch, setBranch] = useState<Branch>();
    const [orders, setOrders] = useState<Order[]>([]);
    const [isLoadingBranch, setIsLoadingBranch] = useState(false);
    const [isLoadingOrders, setIsLoadingOrders] = useState(false);
    const isLoading = isLoadingBranch || isLoadingOrders;

    useEffect(() => {
        if (!clientCode) return;

        setIsLoadingBranch(true);
        setIsLoadingOrders(true);

        getBranchByClientCode(clientCode)
            .then((branch) => {
                setBranch(branch);
            })
            .catch(defaultErrorHandler)
            .finally(() => setIsLoadingBranch(false));

        getBranchOrdersByClientCode(clientCode, ordersStartDate, ordersEndDate)
            .then((orders) => {
                setOrders(orders);
            })
            .catch(defaultErrorHandler)
            .finally(() => setIsLoadingOrders(false));
    }, [clientCode, defaultErrorHandler, navigate, ordersEndDate, ordersStartDate]);

    return (
        <FluidPageLayout>
            {isLoading && (
                <div style={{ display: "flex", justifyContent: "center", padding: "4rem 0" }}>
                    <Loader active inline />
                </div>
            )}
            {!isLoading && branch && (
                <>
                    <PageHeader
                        title={branch.clientCode}
                        subtitle={`${branch?.alternateName || ""} - ${branch?.city || ""}`}
                    />
                    <BranchInfo branch={branch} />
                    <Header>
                        {formatMessage(m.tableTitle, {
                            start: dayjs.utc(ordersStartDate).format("LL"),
                            end: dayjs.utc(ordersEndDate).format("LL"),
                        })}
                    </Header>
                    <OrdersTable orders={orders} />
                </>
            )}
        </FluidPageLayout>
    );
};
