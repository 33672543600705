import React, { useState } from "react";
import { Table, TableCell } from "semantic-ui-react";
import { GroupedMonthlyTotalAmount } from "../../types";
import { GroupMonthlyStatsRows } from "./GroupMonthlyStatsRows";
import { ExpandButton } from "../ExpandButton/ExpandButton";
import orderBy from "lodash/orderBy";
import { useGroupMonthlyStats } from "../../hooks/useGroupMonthlyStats";

interface GroupingSectionProps {
    sectionName: string;
    groupedAmounts: GroupedMonthlyTotalAmount[];
    groupsNameById: Record<number, string>;
    reportMonthsInOrder: number[];
    outputCurrency: string;
}

export const GroupingSection: React.FC<GroupingSectionProps> = ({
    sectionName,
    groupedAmounts,
    groupsNameById,
    reportMonthsInOrder,
    outputCurrency,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const {monthlyStatsByGroupId, groupsId } = useGroupMonthlyStats(
        groupedAmounts,
        outputCurrency
    );

    const orderedGroupsId = orderBy(
        groupsId,
        [
            (groupId) => monthlyStatsByGroupId[groupId]?.currentPeriodTotal || 0,
            (groupId) => monthlyStatsByGroupId[groupId]?.previousPeriodTotal || 0,
        ],
        ["desc", "desc"]
    );

    return (
        <>
            <Table.Row style={{ backgroundColor: "#F9FAFB" }}>
                <TableCell colSpan={16}>
                    <div style={{ paddingLeft: "1rem" }}>
                        <ExpandButton
                            isExpanded={isExpanded}
                            onChange={setIsExpanded}
                            expandedLabel={sectionName}
                            collapsedLabel={sectionName}
                        />
                    </div>
                </TableCell>
            </Table.Row>
            {isExpanded &&
                orderedGroupsId.map((groupId) => (
                    <GroupMonthlyStatsRows
                        key={groupId}
                        groupName={groupsNameById[groupId as number]}
                        monthlyStats={monthlyStatsByGroupId[groupId]}
                        reportMonthsInOrder={reportMonthsInOrder}
                    />
                ))}
        </>
    );
};
