export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const RETRIEVE_USER_REQUEST = "RETRIEVE_USER_REQUEST";
export const RETRIEVE_USER_SUCCESS = "RETRIEVE_USER_SUCCESS";
export const RETRIEVE_USER_FAILURE = "RETRIEVE_USER_FAILURE";

export interface LogoutRequestAction {
    type: typeof LOGOUT_REQUEST;
    payload: unknown;
}

export interface RetrieveUserRequestAction {
    type: typeof RETRIEVE_USER_REQUEST;
    payload: unknown;
}
export interface RetrieveUserSuccessAction {
    type: typeof RETRIEVE_USER_SUCCESS;
    payload: User;
}
export interface RetrieveUserFailureAction {
    type: typeof RETRIEVE_USER_FAILURE;
    payload: { error: Error };
}

export type AccountActionTypes =
    | RetrieveUserRequestAction
    | RetrieveUserSuccessAction
    | RetrieveUserFailureAction
    | LogoutRequestAction;

export interface AccountState {
    readonly user: User | undefined;
    readonly isLoading: boolean;
    readonly hasFailedAuthentication: boolean;
    readonly isAuthenticated: boolean;
    readonly isSessionLoaded: boolean;
}

export interface User {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    isAdmin: boolean;
}
