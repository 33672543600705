import dayjs from "dayjs";
import { ExchangeRate, Invoice, Order } from "../orders";
import { Currency, CurrencyFilter, Amount, MonthlyStats } from "./types";

export const calculateInvoiceOrderReportPrice = (
    invoice: Invoice,
    outputCurrency: string | null,
    defaultExchangeRates: ExchangeRate[]
) => {
    const invoiceTotal =
        invoice.salesAmount +
        invoice.ledgerAmount +
        invoice.rebateAmount +
        invoice.secondRebateAmount;

    return convertAmountToCurrency(
        {
            totalAmount: invoiceTotal,
            currency: invoice.currency,
            exchangeRate: invoice.exchangeRate,
        },
        outputCurrency,
        defaultExchangeRates
    );
};

const getOrderExchangeRate = (order: Order): number | null => {
    if (order.currency === Currency.CanadianDollar) return 1;
    if ((order.invoices || []).length === 0) return null;
    return order.invoices[0].exchangeRate;
};

export const calculateOrderReportPrice = (
    order: Order,
    outputCurrency: string | null,
    defaultExchangeRates: ExchangeRate[]
) => {
    const orderPrice = order.priceAfterDiscount;

    return convertAmountToCurrency(
        {
            totalAmount: orderPrice,
            currency: order.currency,
            exchangeRate: getOrderExchangeRate(order),
        },
        outputCurrency,
        defaultExchangeRates
    );
};

export const getFilterOutputCurrency = (currencyFilter: string | undefined) =>
    currencyFilter === CurrencyFilter.AllConvertedInUsd
        ? Currency.UsDollar
        : currencyFilter || Currency.CanadianDollar;

export const getFilterPickCurrency = (currencyFilter: string | undefined) =>
    !currencyFilter || currencyFilter === CurrencyFilter.AllConvertedInUsd
        ? undefined
        : currencyFilter;

export const getExchangeRateInCad = (currency: string, defaultExchangeRates: ExchangeRate[]) =>
    currency === Currency.CanadianDollar
        ? 1
        : defaultExchangeRates.find((rate) => rate.currency === currency)?.rate || 1.0;

export const convertAmountToCurrency = (
    amount: Amount,
    outputCurrency: string | null,
    defaultExchangeRates: ExchangeRate[]
) => {
    if (amount.currency == outputCurrency || !outputCurrency) {
        return amount.totalAmount;
    }

    if (outputCurrency === Currency.CanadianDollar && amount.exchangeRate != null) {
        return amount.totalAmount * amount.exchangeRate;
    }

    return convertToCurrency(
        amount.totalAmount,
        amount.currency,
        outputCurrency,
        defaultExchangeRates
    );
};

const convertToCurrency = (
    sourceAmount: number,
    sourceCurrency: string,
    outputCurrency: string | null,
    defaultExchangeRates: ExchangeRate[]
) => {
    if (sourceCurrency === outputCurrency || !outputCurrency) return sourceAmount;

    const toCADrate = getExchangeRateInCad(sourceCurrency, defaultExchangeRates);
    const amountCAD = sourceAmount * toCADrate;
    const fromCADrate = getExchangeRateInCad(outputCurrency, defaultExchangeRates);
    const amountInOutputCurrency = amountCAD / fromCADrate;

    return amountInOutputCurrency;
};

export const calculateRatio = (previous: number, current: number): number => {
    if (previous === 0 && current > 0) return 1;
    if (previous > 0) return current / previous - 1;
    return 0;
};

export const sumMonthlyStats = (allMonthlyStats: MonthlyStats[]): MonthlyStats => {
    const totalMonthlyOrders: MonthlyStats = {
        statsByMonth: {},
        currentPeriodTotal: 0,
        previousPeriodTotal: 0,
        ratio: 0,
    };

    const allStatsByMonth = allMonthlyStats.flatMap((monthlyStats) =>
        Object.entries(monthlyStats.statsByMonth)
    );

    allStatsByMonth.forEach(([, monthStat]) => {
        const month = monthStat.month;

        if (!totalMonthlyOrders.statsByMonth[month]) {
            totalMonthlyOrders.statsByMonth[month] = {
                month: month,
                currentPeriodTotal: 0,
                previousPeriodTotal: 0,
                ratio: 0,
            };
        }

        totalMonthlyOrders.statsByMonth[month].currentPeriodTotal += monthStat.currentPeriodTotal;
        totalMonthlyOrders.currentPeriodTotal += monthStat.currentPeriodTotal;

        totalMonthlyOrders.statsByMonth[month].previousPeriodTotal += monthStat.previousPeriodTotal;
        totalMonthlyOrders.previousPeriodTotal += monthStat.previousPeriodTotal;
    });

    Object.keys(totalMonthlyOrders.statsByMonth).forEach((month) => {
        const monthStats = totalMonthlyOrders.statsByMonth[Number(month)];
        monthStats.ratio = calculateRatio(
            monthStats.previousPeriodTotal,
            monthStats.currentPeriodTotal
        );
    });

    totalMonthlyOrders.ratio = calculateRatio(
        totalMonthlyOrders.previousPeriodTotal,
        totalMonthlyOrders.currentPeriodTotal
    );

    return totalMonthlyOrders;
};

const formatDateForUrl = (date: Date) => dayjs.utc(date).format("YYYY/MM/DD");

const getMonthDateRange = (month: number, year: number) => {
    const startDate = dayjs.utc().year(year).month(month).startOf("month").toDate();
    const endDate = dayjs.utc(startDate).endOf("month").toDate();
    return [startDate, endDate];
};

export const getUrlForMonthClientOrders = (clientCode: string, month: number, year: number) => {
    const [startDate, endDate] = getMonthDateRange(month, year);
    return getUrlForClientOrders(clientCode, startDate, endDate);
};

export const getUrlForClientOrders = (clientCode: string, startDate: Date, endDate: Date) => {
    return `/reports/orders/clients/${clientCode}?start=${formatDateForUrl(
        startDate
    )}&end=${formatDateForUrl(endDate)}`;
};

export const getUrlForMonthClientSales = (clientCode: string, month: number, year: number) => {
    const [startDate, endDate] = getMonthDateRange(month, year);
    return getUrlForClientSales(clientCode, startDate, endDate);
};

export const getUrlForClientSales = (clientCode: string, startDate: Date, endDate: Date) => {
    return `/reports/sales/clients/${clientCode}?start=${formatDateForUrl(
        startDate
    )}&end=${formatDateForUrl(endDate)}`;
};
