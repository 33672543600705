import { OrdersFilters } from "../../orders";
import { Currency, MonthlyStats, OrdersStat } from "../types";
import { getFilterOutputCurrency, sumMonthlyStats } from "../utils";
import { useGroupMonthlyStats } from "./useGroupMonthlyStats";

export interface OrdersReportData {
    filters: OrdersFilters;
    currencyFilter: string;
    outputCurrency: string;
    monthsInOrder: number[];
    clientNameByClientCode: Record<string, string>;
    clientCodes: string[];
    monthlyOrdersByClientCode: Record<string, MonthlyStats>;
    totalMonthlyOrders: MonthlyStats;
}

export const useOrdersReportData = (
    ordersStat: OrdersStat | undefined,
    filters: OrdersFilters,
    monthsInOrder: number[]
) => {
    const outputCurrency = getFilterOutputCurrency(filters.currency);

    const {
        monthlyStatsByGroupId: monthlyStatsByClientCode,
        groupsId: monthlyStatsGroupsId,
        isProcessing,
    } = useGroupMonthlyStats(ordersStat?.ordersByClientCode || [], outputCurrency);

    const allMonthlyStats = Object.values(monthlyStatsByClientCode);
    const totalMonthlyOrders: MonthlyStats = sumMonthlyStats(allMonthlyStats);

    const ordersReportData: OrdersReportData = {
        filters: filters,
        currencyFilter: filters.currency || Currency.CanadianDollar,
        outputCurrency: getFilterOutputCurrency(filters.currency),
        clientNameByClientCode: ordersStat?.clientNamesByClientCode || {},
        clientCodes: monthlyStatsGroupsId as string[],
        monthlyOrdersByClientCode: monthlyStatsByClientCode,
        monthsInOrder: monthsInOrder,
        totalMonthlyOrders,
    };

    return { ordersReportData, isProcessing };
};
