export enum Currency {
    CanadianDollar = "CAD",
    UsDollar = "USD",
    Euro = "EUR",
}

export enum CurrencyFilter {
    AllConvertedInUsd = "ALLINUSD",
    CadOnly = "CAD",
    UsdOnly = "USD",
}

export interface ClientDetailedOrderStats {
    orderTotalAmountsGroupedByModel: GroupedMonthlyTotalAmount[];
    orderTotalAmountsGroupedByCover: GroupedMonthlyTotalAmount[];
    modelsNameById: Record<number, string>;
    coversDescriptionById: Record<number, string>;
}

export interface ClientDetailedSaleStats {
    saleTotalAmountsGroupedByModel: GroupedMonthlyTotalAmount[];
    saleTotalAmountsGroupedByCover: GroupedMonthlyTotalAmount[];
    modelsNameById: Record<number, string>;
    coversDescriptionById: Record<number, string>;
}

export interface GroupedMonthlyTotalAmount extends Amount {
    groupId: string | number;
    year: number;
    month: number;
}

export interface GroupedTotalAmount extends Amount {
    groupId: string | number;
}

export interface Amount {
    totalAmount: number;
    currency: string;
    exchangeRate: number | null;
}

export interface MonthlyStats {
    statsByMonth: Record<number, MonthStats>;
    currentPeriodTotal: number;
    previousPeriodTotal: number;
    ratio: number;
}

export interface MonthStats {
    month: number;
    currentPeriodTotal: number;
    previousPeriodTotal: number;
    ratio: number;
}

export interface SalesStat {
    salesByClientCode: GroupedMonthlyTotalAmount[];
    upcomingBillingAmountByClientCode: GroupedTotalAmount[];
    billedAmountAfterEndDateByClientCode: GroupedTotalAmount[];
    clientNamesByClientCode: Record<string, string>;
}

export interface OrdersStat {
    ordersByClientCode: GroupedMonthlyTotalAmount[];
    clientNamesByClientCode: Record<string, string>;
}
