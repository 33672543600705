import { User } from '../account/types';

export const LOAD_REPRESENTATIVES_REQUEST = 'REPRESENTATIVES_LOAD_REQUEST';
export const LOAD_REPRESENTATIVES_SUCCESS = 'REPRESENTATIVES_LOAD_SUCCESS';
export const LOAD_REPRESENTATIVES_FAILURE = 'REPRESENTATIVES_LOAD_FAILURE';

export interface LoadRepresentativesRequestAction { type: typeof LOAD_REPRESENTATIVES_REQUEST; payload: unknown }
export interface LoadRepresentativesSuccessAction { type: typeof LOAD_REPRESENTATIVES_SUCCESS; payload: User[] }
export interface LoadRepresentativesFailureAction { type: typeof LOAD_REPRESENTATIVES_FAILURE; payload: { error: Error } }

export type RepresentativesActionType =
    LoadRepresentativesRequestAction | LoadRepresentativesSuccessAction | LoadRepresentativesFailureAction;

export interface RepresentativesState {
    readonly byId: { [id: string]: User };
    readonly allIds: string[];
    readonly loading: boolean;
}