import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const baseConfiguration = (): RequestInit => ({
    credentials: 'same-origin',
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        'Accept-Language': dayjs.locale()
    }
});

const multiPartConfiguration = (): RequestInit => ({
    credentials: 'same-origin',
    headers: {
        // For multipart configurations, the content type needs to remain blank, as it will be auto generated by the browser.
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        'Accept-Language': dayjs.locale()
    }
});

export class ApiError extends Error {
    public readonly statusCode: number;
    constructor(statusCode: number, message?: string | undefined) {
        super(message);
        this.statusCode = statusCode;
    }
}

export const useDefaultErrorHandler = () => {
    const navigate = useNavigate();

    const defaultErrorHandler = useCallback(
        (error: ApiError): void => {
            switch (error.statusCode) {
                case 401:
                    navigate("/error/401");
                    break;
                case 403:
                    navigate("/error/403");
                    break;
                case 404:
                    navigate("/error/404", { replace: true });
                    break;
                default:
                    navigate("/error/500");
            }
        },
        [navigate]
    );

    return defaultErrorHandler;
};

const isDevelopment = process.env.NODE_ENV == null || process.env.NODE_ENV === 'development';

export async function get<TResponse>(requestUrl: string): Promise<TResponse> {
    const result = await fetchRequest<TResponse>(
        requestUrl,
        {
            ...baseConfiguration(),
            method: 'GET'
        }
    );

    return result;
}

export async function post<TResponse>(requestUrl: string, body: any | undefined): Promise<TResponse> {
    const result = await fetchRequest<TResponse>(
        requestUrl,
        {
            ...baseConfiguration(),
            method: 'POST',
            body: body != null ? JSON.stringify(body) : undefined
        });

    return result;
}

export async function postFormData<TResponse>(requestUrl: string, body: any | undefined): Promise<TResponse> {
    const result = await fetchRequest<TResponse>(
        requestUrl,
        {
            method: 'POST',
            body
        });

    return result;
}

export async function postMultiPart<TResponse>(requestUrl: string, body: any): Promise<TResponse> {
    const bodyFormData = Object.keys(body).reduce((formData, key) => {
        const value = body[key];

        if (value instanceof Array) {
            value.forEach((x) => appendValue(formData, key, x));
        } else {
            appendValue(formData, key, value);
        }

        return formData;
    }, new FormData());

    const result = await fetchRequest<TResponse>(
        requestUrl,
        {
            ...multiPartConfiguration(),
            method: 'POST',
            body: bodyFormData
        });

    return result;
}

function appendValue(formData: FormData, key: string, value: any) {
    if (value instanceof Date) {
        // Dates need to be sent as ISO string to be recognized by the backend.
        formData.append(key, value.toISOString());
    } else {
        // Null values need to be sent as empty strings
        formData.append(key, value != null ? value : '');
    }
}

export async function put<TResponse>(requestUrl: string, body: any | undefined): Promise<TResponse> {
    const result = await fetchRequest<TResponse>(
        requestUrl,
        {
            method: 'PUT',
            body
        });

    return result;
}

export async function del<TResponse>(requestUrl: string): Promise<TResponse> {
    const result = await fetchRequest<TResponse>(
        requestUrl,
        {
            ...baseConfiguration(),
            method: 'DELETE'
        });

    return result;
}

async function fetchRequest<TResponse>(requestUrl: string, requestConfiguration: RequestInit): Promise<TResponse> {
    const response = await fetch(requestUrl, requestConfiguration);

    if (response.status >= 200 && response.status < 300) {
        return response.json() as Promise<TResponse>;
    }

    let errorMessage = `${response.status} ${response.statusText}`;
    try {
        const error = await response.json();
        errorMessage = error && error.UserMessage ? (isDevelopment ? error.UserMessage + ' DEBUG: ' + error.DebugMessage : error.UserMessage) : errorMessage;
    }
    catch {
        // response did not contain a json serialized error
    }

    return Promise.reject(new ApiError(response.status, errorMessage));
}
