import { push } from "redux-first-history";
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import * as ApplicationActions from '../application/actions';
import * as actions from './actions';
import * as api from './api';

import { LOGOUT_REQUEST, RETRIEVE_USER_REQUEST, User } from './types';

function* handleLogout() {
    try {
        yield call(api.logout);
        yield put(push('/account/login'));
    } catch (e) {
        console.error('An error occurred while performing the logout operation');
    }
}

function* handleRetrieve() {
    try {
        const response: User = yield call(api.retrieve);
        yield put(actions.retrieveUserSuccess(response));
        yield put(ApplicationActions.startApplication());
    } catch (e) {
        yield put(actions.retrieveUserFailure(e as Error));
    }
}

function* watchLogout() { yield takeEvery(LOGOUT_REQUEST, handleLogout); }
function* watchRetrieveUser() { yield takeEvery(RETRIEVE_USER_REQUEST, handleRetrieve); }

function* accountSagas() {
    yield all([
        fork(watchLogout),
        fork(watchRetrieveUser)
    ]);
}

export default accountSagas;