import dayjs from 'dayjs';
import _ from 'lodash';
import { OrdersFilters, Order, ExchangeRate } from './types';
import { ApplicationState } from '../../store/store';
import { CurrencyFilter } from '../reports/types';

export const areOrdersLoading = (state: ApplicationState): boolean => state.orders.data.loading;
export const getOrderById = (state: ApplicationState, id: number): Order | undefined => state.orders.data.byId[id];
export const getAllOrders = (state: ApplicationState): Order[] => state.orders.data.allIds.map(x => getOrderById(state, x)).filter(x => x != null) as Order[];
export const getAllOrdersBetween = (state: ApplicationState, startDate: Date, endDate: Date): Order[] => {
    // N.B.: This method makes the assumption that the start date and end date are both in the same year. If that's not the case
    // we'll need to join the orders for the start year and end year and apply the same logic.
    const orders = (state.orders.data.byYear[dayjs(startDate).year()] || [])
        .map(x => getOrderById(state, x))
        .filter(x => x != null)
        .filter(x => dayjs(x!.orderedOn).isSameOrBefore(endDate));

    return orders as Order[];
};

export const getOrders = (state: ApplicationState, startDate: Date, endDate: Date, filters?: OrdersFilters): Order[] => {
    const orders = getAllOrdersBetween(state, startDate, endDate);
    return filterOrders(state, startDate, orders, filters);
};

export const filterOrders = (state: ApplicationState, startDate: Date, orders: Order[], filters?: OrdersFilters) => {
    const ordersIds = orders.map(x => x.id);

    if (filters == null) {
        return orders;
    }

    const byYearAndMonth = filters.month != null ? state.orders.data.byYearAndMonth[dayjs(new Date(dayjs(startDate).year(), filters.month, 1)).format('YYYYMMDD')] || [] : undefined;
    const byClientId = filters.clientId != null ? state.orders.data.byClientId[filters.clientId] || [] : undefined;
    const byRepresentativeId = filters.representativeId != null ? state.orders.data.byRepresentativeId[filters.representativeId] || [] : undefined;
    const byCountry = filters.countryCode != null ? state.orders.data.byCountry[filters.countryCode] || [] : undefined;
    const byProvince = filters.provinceCode != null ? state.orders.data.byProvince[filters.provinceCode] || [] : undefined;
    const byCurrency =
        filters.currency != null && filters.currency != CurrencyFilter.AllConvertedInUsd
            ? state.orders.data.byCurrency[filters.currency] || []
            : undefined;
    const filteredIds = [ordersIds, byYearAndMonth, byClientId, byRepresentativeId, byCountry, byProvince, byCurrency].filter(x => x != null) as number[][];

    return _.intersection(...filteredIds).map(x => getOrderById(state, x)).filter(x => x != null) as Order[];
};

export const getExchangeRates = (state: ApplicationState): ExchangeRate[] => state.orders.exchangeRates.allCurrencies.map(x => state.orders.exchangeRates.byCurrency[x]);

export const getAllCountryCodes = (state: ApplicationState): string[] => _.uniq(getAllOrders(state).map(x => x.shippingCountryCode).filter(x => x != null));
export const getProvinceCodes = (state: ApplicationState, countryCode: string): string[] => {
    const provinces = getAllOrders(state)
        .filter(x => x.shippingCountryCode === countryCode)
        .map(x => x.shippingProvinceCode)
        .filter(x => x != null);

    return _.uniq(provinces);
};