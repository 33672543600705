import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../../components/PageHeader';
import { BranchSummary } from './components/BranchSummary/BranchSummary';
import { useNavigate, useParams } from 'react-router-dom';
import * as clientApi from '../api';
import * as activityApi from '../../activities/api';
import { Branch } from '../types';
import { defineMessages, useIntl } from 'react-intl';
import styles from './ClientDetailsPage.module.css';
import { Activity } from '../../activities/types';
import { BranchActivities } from './components/BranchActivities';
import { FloatingActionMenu, FloatingActionMenuItem } from '../../../components/floatingActionButton';
import { FluidPageLayout } from '../../../components/layouts';
import { ClientSets } from './components/ClientSets';
import * as LayoutActions from '../../layout/actions';
import { useDispatch } from 'react-redux';
import { Segment } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { JaymarStudioSection } from './components/JaymarStudioSection';

const m = defineMessages({
    pageTitle: { id: 'ClientDetailsPage.pageTitle', defaultMessage: 'Branch details' },
    activitiesSectionTitle: { id: 'ClientDetailsPage.activitiesSectionTitle', defaultMessage: 'Activities' },
    setsSectionTitle: { id: 'ClientDetailsPage.setsSectionTitle', defaultMessage: 'Sets' },
    newActivityAction: { id: 'ClientDetailsPage.newActivityAction', defaultMessage: 'Add an activity' },
    newSetAction: { id: 'ClientDetalisPage.newSetAction', defaultMessage: 'Add a set' },
    studioSectionTitle: { id: 'ClientDetailsPage.studioSectionTitle', defaultMessage: 'Jaymar studio' },
    goalsSectionTitle: { id: 'ClientDetailsPage.goalsSectionTitle', defaultMessage: 'Goals' },
    goalsLink: { id: 'ClientDetailsPage.goalsLink', defaultMessage: 'View the client\'s goals' }
});

export const ClientDetailsPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { clientId, branchId } = useParams<any>();

    const [isLoadingBranch, setLoadingBranch] = useState(false);
    const [isLoadingActivities, setLoadingActivities] = useState(false);
    const [branch, setBranch] = useState<Branch | undefined>(undefined);
    const [activities, setActivities] = useState<Activity[]>([]);

    const navigateToActivity = (activity: Activity) => {
        navigate(`/clients/${clientId}/branches/${branchId}/activities/${activity.id}`);
    };

    useEffect(() => {
        dispatch(LayoutActions.setPageTitle(formatMessage(m.pageTitle)));
    }, [dispatch, formatMessage]);

    useEffect(() => {
        const id = branchId ? Number(branchId) : undefined;

        if (id != null && !isNaN(id)) {
            setLoadingBranch(true);
            setLoadingActivities(true);

            clientApi.getBranchById(id)
                .then(result => setBranch(result))
                .finally(() => setLoadingBranch(false));

            activityApi.getActivitiesByBranch(id)
                .then(result => setActivities(result))
                .finally(() => setLoadingActivities(false));
        }
    }, [branchId]);

    return (
        <FluidPageLayout>
            <PageHeader title={branch?.client?.displayName || ''} subtitle={branch?.city || ''} />
            <BranchSummary branch={branch} loading={isLoadingBranch} style={{ marginTop: '1rem' }} />

            <div className={styles.activitiesSection}>
                <h3>{formatMessage(m.activitiesSectionTitle)}</h3>
                <BranchActivities
                    clientId={Number(clientId)}
                    branchId={Number(branchId)}
                    activities={activities}
                    maxResults={3}
                    loading={isLoadingActivities}
                    onNavigate={navigateToActivity}
                />

                <h3>{formatMessage(m.setsSectionTitle)}</h3>
                <ClientSets
                    clientId={branch?.clientId || 0}
                    branchId={branch?.id || 0}
                    sets={branch?.client?.sets || []}
                    loading={isLoadingBranch}
                />

                <h3>{formatMessage(m.studioSectionTitle)}</h3>
                <JaymarStudioSection
                    clientId={branch?.clientId || 0}
                    branchId={branch?.id || 0}
                    hasSpaceReservedForJaymar={branch?.hasSpaceReservedForJaymar || false}
                />

                <h3>{formatMessage(m.goalsSectionTitle)}</h3>
                <Segment>
                    <NavLink to={`/goals?clientId=${clientId}`}>{formatMessage(m.goalsLink)}</NavLink>
                </Segment>
            </div>

            <FloatingActionMenu>
                {branch != null && <FloatingActionMenuItem to={`/clients/${branch.clientId}/branches/${branch.id}/sets`} icon="box" title={formatMessage(m.newSetAction)} />}
                {branch != null && <FloatingActionMenuItem to={`/activities/new/${branch.clientId}/${branch.id}`} icon="sticky note" title={formatMessage(m.newActivityAction)} />}
            </FloatingActionMenu>
        </FluidPageLayout>
    );
};