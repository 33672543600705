import { useMediaQuery } from "react-responsive";

export const useResponsive = (): { isMobile: boolean; isTablet: boolean; isDesktop: boolean, isLargeMonitor: boolean, isWidescreenMonitor: boolean, isPortrait: boolean, isLandscape: boolean } => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 768px) and (max-width:991px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 991px) and (max-width:1199px)" });
  const isLargeMonitor = useMediaQuery({ query: "(min-width: 1200px) and (max-width:1919px)" });
  const isWidescreenMonitor = useMediaQuery({ query: "(min-width: 1920px)" });

  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });

  return { isMobile, isTablet, isDesktop, isLargeMonitor, isWidescreenMonitor, isPortrait, isLandscape };
};
