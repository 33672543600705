import React, { useState } from 'react';
import { Image, Modal, ImageGroupProps, Button } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';

export interface MediaFile {
    id: any;
    src: string;
}

interface OwnProps {
    files: MediaFile[];
    disabled?: boolean;
    onDelete?: (file: MediaFile) => void;
}

export type MediaGalleryProps =
    & OwnProps
    & ImageGroupProps;

const m = defineMessages({
    modalTitle: { id: 'MediaGallery.modalTitle', defaultMessage: 'Image preview' },
    modalDeleteButton: { id: 'MediaGallery.modalDeleteButton', defaultMessage: 'Delete' },
    modalCancelButton: { id: 'MediaGallery.modalCancelButton', defaultMessage: 'Cancel' },
});

export const MediaGallery: React.FC<MediaGalleryProps> = (props) => {
    const { files, onDelete, ...imageGroupProps } = props;

    const { formatMessage } = useIntl();
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedImage, selectImage] = useState<MediaFile | undefined>(undefined);

    const openPreview = (file: MediaFile) => {
        selectImage(file);
        setModalOpen(true);
    };

    const closePreview = () => {
        selectImage(undefined);
        setModalOpen(false);
    };

    const deleteImage = () => {
        if (selectedImage && onDelete) {
            onDelete(selectedImage);
        }

        closePreview();
    };

    return (
        <>
            <Image.Group {...imageGroupProps}>
                {files.map(file => {
                    return <Image key={file.src} src={file.src} style={{ cursor: 'pointer' }} onClick={() => openPreview(file)} />;
                })}
            </Image.Group>

            <Modal closeIcon open={isModalOpen} size="fullscreen" onClose={closePreview}>
                <Modal.Header>{formatMessage(m.modalTitle)}</Modal.Header>
                <Modal.Content image>
                    {selectedImage &&
                        <Image wrapped src={selectedImage.src} />
                    }
                </Modal.Content>
                {onDelete &&
                    <Modal.Actions>
                        <Button onClick={closePreview}>{formatMessage(m.modalCancelButton)}</Button>
                        <Button negative onClick={deleteImage}>{formatMessage(m.modalDeleteButton)}</Button>
                    </Modal.Actions>
                }
            </Modal>
        </>
    );
};