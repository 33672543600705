import { call, put, takeEvery, all, fork, takeLatest } from "redux-saga/effects";
import * as api from './api';
import * as actions from './actions';
import {
    Branch,
    Client,
    CreateBranchRequestAction,
    CreateClientRequestAction,
    CREATE_BRANCH_REQUEST,
    CREATE_CLIENT_REQUEST,
    LOAD_CLIENTS_REQUEST,
} from "./types";
import { push } from "redux-first-history";

function* watchLoadClients() { yield takeLatest(LOAD_CLIENTS_REQUEST, handleLoadClients); }
function* handleLoadClients() {
    try {
        const result: Client[] = yield call(api.getClients);
        yield put(actions.loadClientsSuccess(result));
    } catch (e) {
        yield put(actions.loadClientsFailure(e as Error));
    }
}

function* watchCreateClient() { yield takeEvery(CREATE_CLIENT_REQUEST, handleCreateClient); }
function* handleCreateClient(action: CreateClientRequestAction) {
    try {
        const result: Client = yield call(api.createClient, action.payload.client);
        yield put(actions.createClientSuccess(result));
        yield put(push(`/clients/${result.id}/branches/${result.branches[0].id}`));
    } catch (e) {
        yield put(actions.createClientFailure(e as Error));
    }
}

function* watchCreateBranch() { yield takeEvery(CREATE_BRANCH_REQUEST, handleCreateBranch); }
function* handleCreateBranch(action: CreateBranchRequestAction) {
    try {
        const branch: Branch = yield call(api.createBranch, action.payload.clientId, action.payload.branch);
        yield put(actions.createBranchSuccess(branch));
        yield put(push(`/clients/${branch.clientId}/branches/${branch.id}`));

    } catch (e) {
        yield put(actions.createBranchFailure(e as Error));
    }
}

export default function* clientsSagas() {
    yield all([
        fork(watchLoadClients),
        fork(watchCreateClient),
        fork(watchCreateBranch)
    ]);
}