import React from "react";
import { Sidebar, Menu, Icon } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { useIntl, defineMessages } from "react-intl";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../modules/account/selectors";
import styles from "./SidebarMenu.module.css";
import { ReactComponent as JaymarLogo } from "../assets/images/logo-white.svg";

interface SidebarMenuProps {
    visible: boolean;
    onHide: () => void;
}

const m = defineMessages({
    home: { id: "SidebarMenu.Home", defaultMessage: "Home" },
    newActivity: { id: "SidebarMenu.NewActivity", defaultMessage: "New activity" },
    clients: { id: "SidebarMenu.Clients", defaultMessage: "Clients" },
    goals: { id: "SidebarMenu.Goals", defaultMessage: "Goals" },
    orderReport: { id: "SidebarMenu.OrderReport", defaultMessage: "Orders by client report" },
    salesReport: { id: "SidebarMenu.SalesReport", defaultMessage: "Sales by client report" },
    signOut: { id: "SidebarMenu.SignOut", defaultMessage: "Sign Out" },
});

export const SidebarMenu: React.FunctionComponent<SidebarMenuProps> = (props) => {
    const { formatMessage } = useIntl();
    const currentUser = useSelector(getCurrentUser);
    const isAdmin = currentUser?.isAdmin;

    return (
        <Sidebar
            className={styles.sidebarMenu}
            as={Menu}
            color="grey"
            inverted
            icon="labeled"
            animation="overlay"
            onHide={props.onHide}
            vertical
            visible={props.visible}
            size="large"
        >
            <Menu.Item className={styles.applicationLogo}>
                <JaymarLogo />
            </Menu.Item>
            <Menu.Item as={NavLink} to="/" onClick={props.onHide}>
                <Icon name="home" />
                {formatMessage(m.home)}
            </Menu.Item>
            <Menu.Item as={NavLink} to="/activities/new/" onClick={props.onHide}>
                <Icon name="add" />
                {formatMessage(m.newActivity)}
            </Menu.Item>
            <Menu.Item as={NavLink} to="/clients" onClick={props.onHide}>
                <Icon name="user" />
                {formatMessage(m.clients)}
            </Menu.Item>
            {isAdmin && (
                <>
                    <Menu.Item as={NavLink} to="/goals" onClick={props.onHide}>
                        <Icon name="target" />
                        {formatMessage(m.goals)}
                    </Menu.Item>
                    <Menu.Item as={NavLink} to="/reports/orders" onClick={props.onHide}>
                        <Icon name="table" />
                        {formatMessage(m.orderReport)}
                    </Menu.Item>
                    <Menu.Item as={NavLink} to="/reports/sales" onClick={props.onHide}>
                        <Icon name="table" />
                        {formatMessage(m.salesReport)}
                    </Menu.Item>
                </>
            )}
            <Menu.Item as={NavLink} to="/account/logout" onClick={props.onHide}>
                <Icon name="sign out" />
                {formatMessage(m.signOut)}
            </Menu.Item>
        </Sidebar>
    );
};
