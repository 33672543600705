import orderBy from "lodash/orderBy";
import React, { useMemo } from "react";
import { Table } from "semantic-ui-react";
import { Invoice } from "../../../orders";
import { TableHeaders } from "./TableHeaders";
import { TableRow } from "./TableRow";

interface InvoicesTableProps {
    invoices: Invoice[];
}

export const InvoicesTable: React.FC<InvoicesTableProps> = ({ invoices }) => {
    const sortedInvoices = useMemo(() => {
        return orderBy(invoices, (invoice) => new Date(invoice.billedOn));
    }, [invoices]);

    return (
        <Table>
            <TableHeaders />
            <Table.Body>
                {sortedInvoices.map((invoice) => (
                    <TableRow key={invoice.id} invoice={invoice} />
                ))}
            </Table.Body>
        </Table>
    );
};
