import React, { useMemo } from 'react';
import _ from 'lodash';
import { VictoryChart, VictoryAxis, VictoryArea, VictoryLine, VictoryScatter } from 'victory';
import { formatMoney } from '../../../../utils/formatMoney';
import { Point, GoalsChartType } from '../../types';
import { Order, OrdersFilters, getExchangeRates, ExchangeRate } from '../../../orders';
import dayjs from 'dayjs';
import { Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { calculateOrderPrice } from '../../selectors';

export interface GoalChartProps {
  type: GoalsChartType;
  filters: OrdersFilters;
  currentOrders: Order[];
  currentShippedOrders: Order[];
  previousOrders: Order[];
  yearlyGoal: number;

  width?: number;
  height?: number;
  loading?: boolean;
}

export const ChartTheme = {
  currentSales: { color: "rgba(177, 131, 62, 0.55)", symbol: 'circle' },
  previousSales: { color: "rgba(115, 111, 115, 0.4)", symbol: 'circle' },
  shipped: { color: "rgba(166, 119, 48, .95)", symbol: 'square' },
  goal: { color: "rgba(0, 0, 0, 1)", symbol: 'circle' }
};

const accumulate = (points: Point[], chartDomain: number[]): Point[] => {
  const empty = chartDomain.map((x): Point => ({ x, y: 0 }));
  const merged = _.unionBy(points, empty, 'x').sort((a, b) => a.x - b.x);
  const results = merged.reduce((acc, curr, index) => acc.length > 0
    ? [...acc, ...[{ x: curr.x, y: curr.y + acc[index - 1].y }]]
    : [curr], [] as Point[]);

  return results;
};

const plotOrders = (orders: Order[], chartDomain: number[], chartType: GoalsChartType, convertCurrenciesToCad: boolean, defaultExchangeRates: ExchangeRate[]): Point[] => {
  const grouppedOrders = _(orders)
    .groupBy(x => chartType === GoalsChartType.wholeYear ? dayjs(x.orderedOn).month() : dayjs(x.orderedOn).format('D'))
    .map((group, key): Point => ({ x: Number(key), y: _(group).sumBy(x => calculateOrderPrice(x, convertCurrenciesToCad, defaultExchangeRates)) }))
    .value();

  return accumulate(grouppedOrders, chartDomain);
};

const getCurrentX = (chartType: GoalsChartType, filteredMonth?: number) => {
  let currentX = dayjs().month();

  if (chartType === GoalsChartType.specificMonth) {
    const forMonth = dayjs().month(filteredMonth || 0).startOf('month');
    const currentMonth = dayjs().startOf('month');

    // Don't display a vertical line when asking for future dates.
    if (currentMonth.isBefore(forMonth)) {
      return 0;
    } else if (currentMonth.isSame(forMonth)) {
      // Display the vertical line on the current day
      currentX = Number(dayjs().format('D'));
    } else {
      // Display the vertical line at the end of the month
      currentX = currentMonth.daysInMonth();
    }
  }

  return currentX;
};

export const GoalChart: React.FC<GoalChartProps> = (props) => {
  const convertCurrenciesToCad = props.filters.currency == null;
  const defaultExchangeRates = useSelector(getExchangeRates);

  const ytdChartDomain = props.type === GoalsChartType.specificMonth ? _.range(1, dayjs().month(props.filters.month || 0).daysInMonth() + 1) : _.range(0, dayjs().month() + 1);
  const allChartDomain = props.type === GoalsChartType.specificMonth ? _.range(1, dayjs().month(props.filters.month || 0).daysInMonth() + 1) : _.range(0, 12);

  const currentOrdersPlotted = useMemo(() => plotOrders(props.currentOrders, ytdChartDomain, props.type, convertCurrenciesToCad, defaultExchangeRates), [props.currentOrders, props.type, ytdChartDomain, convertCurrenciesToCad, defaultExchangeRates]);
  const shippedOrdersPlotted = useMemo(() => plotOrders(props.currentShippedOrders, ytdChartDomain, props.type, convertCurrenciesToCad, defaultExchangeRates), [props.currentShippedOrders, props.type, ytdChartDomain, convertCurrenciesToCad, defaultExchangeRates]);
  const previousOrdersPlotted = useMemo(() => plotOrders(props.previousOrders, allChartDomain, props.type, convertCurrenciesToCad, defaultExchangeRates), [props.previousOrders, props.type, allChartDomain, convertCurrenciesToCad, defaultExchangeRates]);

  const goalTarget = props.type === GoalsChartType.wholeYear ? props.yearlyGoal / 12 : props.yearlyGoal / 12 / dayjs().month(props.filters.month || 0).daysInMonth();
  const goalTargetPlotted = useMemo(() => accumulate(allChartDomain.map((x): Point => ({ x: x, y: goalTarget })), allChartDomain), [allChartDomain, goalTarget]);

  const currentX = getCurrentX(props.type, props.filters.month);
  const currentSales = _(currentOrdersPlotted.filter(point => point.x === currentX).map(point => point.y)).max() || 0;
  const previousSales = _(previousOrdersPlotted.filter(point => point.x === currentX).map(point => point.y)).max() || 0;
  const currentTarget = _(goalTargetPlotted.filter(point => point.x === currentX).map(point => point.y)).max() || 0;
  const currentShipped = _(shippedOrdersPlotted.filter(point => point.x === currentX).map(point => point.y)).max() || 0;
  const currentLineValues = _.max([currentSales, previousSales, currentTarget, currentShipped]);

  const formatMonths = (tick: any): string | number => dayjs().month(tick).format('MMM');

  if (props.loading) {
    return (<Loader active />);
  }

  return (
    <VictoryChart width={props.width} height={props.height} domain={{ x: [_.first(allChartDomain) || 0, _.last(allChartDomain) || 0], y: undefined }}>
      <VictoryAxis dependentAxis tickFormat={t => formatMoney(t, true, 0)} />
      <VictoryAxis
        tickValues={props.type === GoalsChartType.wholeYear ? _.range(0, 12) : undefined}
        tickFormat={props.type === GoalsChartType.wholeYear ? formatMonths : undefined}
        style={props.type === GoalsChartType.wholeYear ? { tickLabels: { angle: -60 } } : undefined}
      />

      <VictoryArea data={currentOrdersPlotted} style={{ data: { fill: ChartTheme.currentSales.color } }} />
      <VictoryArea data={previousOrdersPlotted} style={{ data: { fill: ChartTheme.previousSales.color } }} />
      <VictoryLine data={shippedOrdersPlotted} style={{ data: { stroke: ChartTheme.shipped.color } }} />
      <VictoryLine data={goalTargetPlotted} style={{ data: { strokeDasharray: '4,3' } }} />

      <VictoryLine data={[{ x: currentX, y: 0 }, { x: currentX, y: currentLineValues }]} />
      <VictoryScatter
        style={{ data: { fill: (data) => data.datum.color } }}
        size={5}
        data={[
          { x: currentX, y: currentSales, color: ChartTheme.currentSales.color, symbol: ChartTheme.currentSales.symbol },
          { x: currentX, y: previousSales, color: ChartTheme.previousSales.color, symbol: ChartTheme.previousSales.symbol },
          { x: currentX, y: currentShipped, color: ChartTheme.shipped.color, symbol: ChartTheme.shipped.symbol },
          { x: currentX, y: currentTarget, color: ChartTheme.goal.color, symbol: ChartTheme.goal.symbol }
        ]}
      />
    </VictoryChart>
  );
};