import * as React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Header, Segment, Image, Container } from 'semantic-ui-react';
import { isAuthenticatedSelector } from '../selectors';
import styles from './LoginPage.module.css';
import { MicrosoftAuthentication } from '../components/MicrosoftAuthentication';

import Logo from '../../../assets/images/logo.png';

const m = defineMessages({
    title: { id: 'LoginPage.title', defaultMessage: 'Welcome!' },
    subtitle: { id: 'LoginPage.subtitle', defaultMessage: 'To use the CRM, please sign in with your Microsoft 365 account.' },
});

export const LoginPage: React.FC = () => {
    const { formatMessage } = useIntl();

    const isAuthenticated = useSelector(isAuthenticatedSelector);

    if (isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <Container className={styles.centered}>
            <Segment raised className={styles.loginBox}>
                <Image src={Logo} size="small" />
                <Header content={formatMessage(m.title)} size="medium" style={{ marginTop: '40px' }} />
                <p>{formatMessage(m.subtitle)}</p>

                <div style={{ textAlign: 'center', marginTop: '40px' }}>
                    <MicrosoftAuthentication />
                </div>
            </Segment>
        </Container>
    );
};