import React from "react";
import { Grid, Header, Segment, Button } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useIntl, defineMessages } from "react-intl";

const m = defineMessages({
    title: { id: "ForbiddenPage.title", defaultMessage: "403" },
    subtitle: { id: "ForbiddenPage.subtitle", defaultMessage: "You do not have access." },
    loginPageButton: { id: "ForbiddenPage.loginPageButton", defaultMessage: "Log in page" },
});

export const ForbiddenPage: React.FC = () => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();

    function handleGoLoginClicked() {
        navigate("/account/login");
    }

    return (
        <Grid
            textAlign="center"
            style={{ height: "100vh" }}
            verticalAlign="middle"
            className="not-found"
        >
            <Grid.Column style={{ maxWidth: 450 }}>
                <Segment basic>
                    <Header as="h2" textAlign="center">
                        {formatMessage(m.title)}
                    </Header>
                    <p>{formatMessage(m.subtitle)}</p>
                    <div>
                        <Button primary onClick={handleGoLoginClicked}>
                            {formatMessage(m.loginPageButton)}
                        </Button>
                    </div>
                </Segment>
            </Grid.Column>
        </Grid>
    );
};
