import React from 'react';
import { Placeholder } from 'semantic-ui-react';

export const LoadingSetPlaceholder: React.FC = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: '.5rem' }}>
            <div>
                <Placeholder style={{ width: 20, height: 20 }}>
                    <Placeholder.Image />
                </Placeholder>
            </div>
            <div style={{ flexGrow: 1, marginLeft: '.5rem' }}>
                <Placeholder style={{ marginTop: '-14px', marginBottom: '14px' }}>
                    <Placeholder.Line length="medium" />
                    <Placeholder.Line length="short" />
                </Placeholder>
            </div>
        </div>
    );
};